import React, { useEffect, useRef } from "react";
import { Box, InputAdornment, MenuItem, Select } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import { formatDateNew } from "../../utils/formateDate";
import { FaCalendarDays } from "react-icons/fa6";

const CommonDateFilterPanel = ({
  filterSelectOptions = [],
  handleDateFilter,
  dateFilter,
  disabled,
}) => {
  // Common text styles
  const commonTextStyles = {
    fontFamily: "Lato",
    fontSize: "12px",
    lineHeight: "14.52px",
    color: "#6A6A6A",
  };

  const containerStyle = {
    width: "320px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "5px",
    padding: "0px 6px",
    border: "1px solid #D9D9D9",
    boxSizing: "content-box",
    background: "#fff",
  };

  const selectStyles = {
    height: "30px",
    width: "135px",
    padding: "0",
    margin: "0",
    fontWeight: 500,
    boxShadow: "none",
    ...commonTextStyles,
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "0",
    },
  };

  const menuItemStyles = {
    ...commonTextStyles,
  };

  const datePickerStyles = {
    fontSize: "16px",
    padding: "3px",
    width: "172px",
    border: "none",
    backgroundColor: "transparent",
    ...commonTextStyles,
    ...(dateFilter?.select !== "CUSTOM" && {
      color: "gray",
      cursor: "not-allowed",
    }),
  };
  const datePickerRef = useRef(null);

  useEffect(() => {
    if (dateFilter?.select === "CUSTOM") {
      datePickerRef.current.openCalendar();
    }
  }, [dateFilter?.select]);

  return (
    <Box sx={containerStyle}>
      <Select
        displayEmpty
        disabled={disabled}
        sx={selectStyles}
        name="select"
        value={dateFilter?.select ?? ""}
        onChange={(e) => {
          const { name, value } = e.target;
          handleDateFilter(name, value);
        }}
        startAdornment={
          <InputAdornment position="start">
            <FaCalendarDays size={16} style={{
              marginLeft:"12px",
              color:"#858D9D",
            }} />
          </InputAdornment>
        }
      >
        <MenuItem
          value=""
          disabled
          sx={menuItemStyles}
        >
          --Select--
        </MenuItem>
        {filterSelectOptions.length > 0 &&
          filterSelectOptions.map((res) => (
            <MenuItem 
              key={res.label} 
              value={res.value}
              sx={menuItemStyles}
            >
              {res.label}
            </MenuItem>
          ))}
      </Select>

      <Box sx={{ borderLeft: "1px solid #E0E2E7" }}>
        <DatePicker
          range
          rangeHover
          ref={datePickerRef}
          dateSeparator=" &nbsp;&nbsp;to&nbsp;&nbsp; "
          disabled={dateFilter?.select !== "CUSTOM"}
          value={[
            dateFilter?.from_time ? new Date(dateFilter.from_time) : "",
            dateFilter?.to_time ? new Date(dateFilter.to_time) : "",
          ]}
          style={datePickerStyles}
          placeholder="Start Date ~ End Date"
          onChange={(value) => {
            if (value) {
              const startDate = value[0] ? formatDateNew(value[0]) : "";
              const endDate = value[1] ? formatDateNew(value[1]) : "";
              handleDateFilter("from_time", startDate);
              handleDateFilter("to_time", endDate);

             // Close the date picker if both start and end dates are selected
              if (dateFilter?.select === "CUSTOM" && startDate && endDate) {
                setTimeout(() => {
                  datePickerRef.current.closeCalendar();
                }, 200);
              }
            }
          }}
          // format="DD:MM:YYYY"
          format="D MMM YYYY"
          editable={false}
          inputClass="rmdp-input-centered"
        />
      </Box>

      <style>{`
        .rmdp-input-centered::placeholder {
          text-align: center;
          font-family: Lato;
          font-size: 12px;
          line-height: 14.52px;
          color: #6A6A6A;
        }
        
        /* Style the calendar popup */
        .rmdp-container {
          font-family: Lato;
        }
        
        .rmdp-day {
          font-size: 12px;
        }
        
        .rmdp-day.rmdp-selected span {
          background-color: #1976d2;
          color: white;
        }
        
        .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
          background-color: #1976d2;
          color: white;
        }
        
        .rmdp-week-day {
          color: #6A6A6A;
          font-size: 12px;
        }
        
        .rmdp-header-values {
          font-family: Lato;
          font-size: 12px;
          color: #6A6A6A;
        }

        /* Styles for the selected dates and separator */
        .rmdp-input-centered {
          text-align: center; /* Center align the selected dates */
          font-weight: 700; /* Start and End Date font weight */
          color: black; /* Start and End Date color */
        }
          
        .rmdp-input-centered::after {
          font-weight: 400; /* Date separator font weight */
          color: #858D9D !important;  /* Changed here: removed quotes and fixed syntax */
        }
      `}</style>
    </Box>
  );
};

export default CommonDateFilterPanel;