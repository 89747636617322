import React from "react";
import coupon from "./scratchcard.module.scss";
import goldCoin from "../../assets/svg/goldcoinScratch.svg";
import goldBarSvg from "../../assets/svg/pile-gold-bars-removebg-preview 1.svg";
import particles from "../../assets/particles.gif";
import { DeviceFrameset } from "react-device-frameset";
import bgImg from "../../assets/couponbg.png";
import couponPageStyle from "../../components/offers/scratchCardId.module.scss";
import { Typography } from "@mui/material";
const getCSStylesByName = (currency) => {
  switch (currency) {
    case "NEUCOIN":
      return {
        img: goldCoin,
        boxshadow: "linear-gradient(180deg, #FCDFFF 0%, #FFF 100%)",
        border: "2px solid #ED2FFF",
      };
    case "DIGIGOLD": {
      return {
        img: goldBarSvg,
        border: "2px solid #FBD51A",
        boxshadow: "linear-gradient(180deg, #FFF5C6 0%, #FFF 100%)",
      };
    }
    default: {
      return {
        img: goldBarSvg,
        border: "2px solid #FBD51A",
        boxshadow: "linear-gradient(180deg, #FFF5C6 0%, #FFF 100%)",
      };
    }
  }
};

const ScratchCardUnlocked = ({ AllData }) => {
  const scrathCardStyle = getCSStylesByName("NEUCOIN");

  return (
    <DeviceFrameset
      device="iPhone X"
      color="black"
      zoom="55%"
      style={{
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className={couponPageStyle.container}
      >
        <div className={couponPageStyle.topIconsContainer}>
          <div
            //   onClick={handleDrawerOpen}
            style={{ alignSelf: "flex-start" }}
          >
            {/* <img src={infoIcon.src} width={20} height={20} alt="info" /> */}
          </div>
          <div
            //   onClick={handleBackToRewardsClick}
            style={{ alignSelf: "flex-start" }}
          >
            {/* <img
                    src={crossIcon.src}
                    width={15}
                    height={15}
                    alt="close"
                  /> */}
          </div>
        </div>

        {/* {console.log(URL.createObjectURL(data?.createGame?.merchantLogo),"data+")} */}
        <div
          className={couponPageStyle.brandLogo}
          style={{
            marginTop: "55px",
          }}
          //   onClick={handleClick}
        >
          {AllData?.merchantLogoUrl && (
            // merchant Logo
            <img
              src={AllData?.merchantLogoUrl}
              style={{ background: "#fff" }}
              width={90}
              height={90}
              alt="logo"
            />
          )}
        </div>

        <div className={couponPageStyle.scratchText}>
          <Typography
            margin="52px 0"
            fontFamily="Proxima Nova A"
            fontWeight={600}
            fontSize="24px"
            lineHeight="20.72px"
          >
            Congrats!
          </Typography>
        </div>

        {(AllData?.scratchCardImageUrl || AllData?.scratchCardColor) && (
          <div
            className={coupon.container}
            style={{
              background: "linear-gradient(180deg, #FCDFFF 0%, #FFF 100%)",
              border: scrathCardStyle?.border,
            }}
          >
            <img
              src={scrathCardStyle?.img} // Assuming img is defined in scrathCardStyle
              width={120}
              height={120}
              alt="Scratch Card"
            />
            <div>
              <div className={coupon.priceText}>
                <span>{19}</span>
                <div className={coupon.particles}>
                  <img
                    src={particles}
                    width={300}
                    height={300}
                    alt="Particles"
                  />
                </div>
              </div>
              <div className={coupon.badge}>{"NEUCOIN"}</div>
            </div>
          </div>
        )}
      </div>
    </DeviceFrameset>
  );
};

export default ScratchCardUnlocked;
