import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiInput from "../../MuiInput";
import Label from "../../Label";
import SmallImageUpload from "../../SmallImageUpload";
import tickImage from "../../../assets/whiteTick.png";
import MuiRadioGroup from "../../MuiRadioGroup";
import ColorInput from "../../ColorInput";
import { CreateGame_PreData } from "../../../utils/CreateGame_PreData";

const images = [
  "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
  "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
  "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
];

export default function ScratchCard_BeforePlay({
  data,
  onChange,
  handleBeforePlayChange,
}) {
  const { ctaColor, ctaTextColor, color } = CreateGame_PreData();

  useEffect(() => {
    handleBeforePlayChange("prefilledData", "", {
      titleColor: data?.beforePlay?.scratchCardData?.titleColor || ctaColor,
      scratchCardColor:
        data?.beforePlay?.scratchCardData?.scratchCardColor || ctaTextColor,
      backgroundColorCode:
        data?.beforePlay?.scratchCardData?.backgroundColorCode || color,
    });
  }, []);
  return (
    <Box>
      <Box sx={{ width: "400px" }}>
        <Box
          sx={{
            display: data?.beforePlay?.scratchCardData?.title_required
              ? "block"
              : "none",
          }}
        >
          <Label label={"1. Reward Title"} />
          <MuiInput
            value={data?.beforePlay?.scratchCardData?.title}
            onChange={(event) => handleBeforePlayChange("title", event)}
          />
        </Box>
        <Box
          mt={"20px"}
          sx={{
            display: data?.beforePlay?.scratchCardData?.titleColor_required
              ? "block"
              : "none",
          }}
        >
          <Label label={"2. Title Color"} />
          <ColorInput
            value={data?.beforePlay?.scratchCardData?.titleColor}
            onChange={(event) => handleBeforePlayChange("titleColor", event)}
          />
        </Box>
      </Box>
      <Box
        mt={"20px"}
        sx={{
          display: data?.beforePlay?.scratchCardData?.merchantLogo_required
            ? "block"
            : "none",
        }}
      >
        <Label label={"3. Merchant Logo"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            gap: "15px",
            mt: "10px",
          }}
        >
          {images?.map((image, index) => (
            <Box
              key={index}
              sx={{
                height: "100px",
                width: "100px",
                borderRadius: "8px",
                border:
                  data?.beforePlay?.scratchCardData?.merchantLogo === image
                    ? "3px solid black"
                    : "3px solid transparent",
                position: "relative", // Add this
                overflow: "hidden", // Add this
              }}
              onClick={() => handleBeforePlayChange("merchantLogo", image)}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "6px",
                  objectFit: "cover", // Add this
                }}
                src={image}
                alt=""
              />
              {data?.beforePlay?.scratchCardData?.merchantLogo === image && (
                <img
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                    zIndex: 100,
                    width: "20px", // Add this
                    height: "20px", // Add this
                  }}
                  src={tickImage}
                  alt="Selected"
                />
              )}
            </Box>
          ))}
          <Box
            sx={{
              height: "100px",
              display: "flex",
              alignItems: "center",
              gap: "25px",
            }}
          >
            <Typography>OR</Typography>
            <Box>
              <SmallImageUpload
                value={data?.beforePlay?.scratchCardData?.merchantLogoUploadUrl}
                onClick={() => {
                  if (
                    data?.beforePlay?.scratchCardData?.merchantLogoUploadUrl
                  ) {
                    handleBeforePlayChange(
                      "merchantLogo",
                      data?.beforePlay?.scratchCardData?.merchantLogoUploadUrl
                    );
                  }
                }}
                borderValue={data?.beforePlay?.scratchCardData?.merchantLogo}
                onChange={(file) =>
                  handleBeforePlayChange("merchantLogoUpload", file)
                }
                handleImage={(value) =>
                  onChange({
                    ...data,
                    beforePlay: {
                      ...data.beforePlay,
                      scratchCardData: {
                        ...data.beforePlay.scratchCardData,
                        merchantLogoUpload: value,
                        [`merchantLogoUploadUrl`]: value,
                        [`merchantLogoUploadId`]: value,
                        merchantLogo: value,
                      },
                    },
                  })
                }
                image={
                  data?.beforePlay?.scratchCardData?.merchantLogoUploadBase64
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        mt={"20px"}
        sx={{
          display: data?.beforePlay?.scratchCardData?.scratchCard_required
            ? "block"
            : "none",
        }}
      >
        <Label label={"4. Scratch card "} />
        <Box mt={"-10px"}>
          <MuiRadioGroup
            options={[
              { label: "Color", value: "color" },
              { label: "Image", value: "image" },
            ]}
            value={
              data?.beforePlay?.scratchCardData?.scratchCardType || "color"
            }
            onChange={(value) =>
              handleBeforePlayChange("scratchCardType", value)
            }
          />
        </Box>
        {data?.beforePlay?.scratchCardData?.scratchCardType === "image" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "15px",
            }}
          >
            {images?.map((image, index) => (
              <Box
                key={index}
                sx={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "8px",
                  border:
                    data?.beforePlay?.scratchCardData?.scratchCard === image
                      ? "3px solid black"
                      : "3px solid transparent",
                  position: "relative", // Add this
                  overflow: "hidden", // Add this
                }}
                onClick={() => handleBeforePlayChange("scratchCard", image)}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6px",
                    objectFit: "cover", // Add this
                  }}
                  src={image}
                  alt=""
                />
                {data?.beforePlay?.scratchCardData?.scratchCard === image && (
                  <img
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "5px",
                      zIndex: 100,
                      width: "20px", // Add this
                      height: "20px", // Add this
                    }}
                    src={tickImage}
                    alt="Selected"
                  />
                )}
              </Box>
            ))}
            <Box
              sx={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <Typography>OR</Typography>
              <Box>
                <SmallImageUpload
                  value={
                    data?.beforePlay?.scratchCardData?.scratchCardUploadUrl
                  }
                  onClick={() => {
                    if (
                      data?.beforePlay?.scratchCardData?.scratchCardUploadUrl
                    ) {
                      handleBeforePlayChange(
                        "scratchCard",
                        data?.beforePlay?.scratchCardData?.scratchCardUploadUrl
                      );
                    }
                  }}
                  borderValue={data?.beforePlay?.scratchCardData?.scratchCard}
                  onChange={(file) =>
                    handleBeforePlayChange("scratchCardUpload", file)
                  }
                  handleImage={(value) =>
                    onChange({
                      ...data,
                      beforePlay: {
                        ...data.beforePlay,
                        scratchCardData: {
                          ...data.beforePlay.scratchCardData,
                          scratchCardUpload: value,
                          [`scratchCardUploadUrl`]: value,
                          [`scratchCardUploadId`]: value,
                          scratchCard: value,
                        },
                      },
                    })
                  }
                  image={
                    data?.beforePlay?.scratchCardData?.scratchCardUploadBase64
                  }
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: "400px" }} mt={"-10px"}>
            <ColorInput
              value={data?.beforePlay?.scratchCardData?.scratchCardColor}
              onChange={(event) =>
                handleBeforePlayChange("scratchCardColor", event)
              }
            />
          </Box>
        )}
      </Box>

      <Box
        mt={"20px"}
        sx={{
          display: data?.beforePlay?.scratchCardData?.backgroundColor_required
            ? "block"
            : "none",
        }}
      >
        <Label label={"5. background "} />
        <Box mt={"-10px"}>
          <MuiRadioGroup
            options={[
              { label: "Color", value: "color" },
              { label: "Image", value: "image" },
            ]}
            value={
              data?.beforePlay?.scratchCardData?.backgroundColorType || "color"
            }
            onChange={(value) =>
              handleBeforePlayChange("backgroundColorType", value)
            }
          />
        </Box>
        <Box></Box>
        {data?.beforePlay?.scratchCardData?.backgroundColorType === "image" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "15px",
            }}
          >
            {images?.map((image, index) => (
              <Box
                key={index}
                sx={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "8px",
                  border:
                    data?.beforePlay?.scratchCardData?.backgroundColor === image
                      ? "3px solid black"
                      : "3px solid transparent",
                  position: "relative", // Add this
                  overflow: "hidden", // Add this
                }}
                onClick={() => handleBeforePlayChange("backgroundColor", image)}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6px",
                    objectFit: "cover", // Add this
                  }}
                  src={image}
                  alt=""
                />
                {data?.beforePlay?.scratchCardData?.backgroundColor ===
                  image && (
                  <img
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "5px",
                      zIndex: 100,
                      width: "20px", // Add this
                      height: "20px", // Add this
                    }}
                    src={tickImage}
                    alt="Selected"
                  />
                )}
              </Box>
            ))}
            <Box
              sx={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <Typography>OR</Typography>
              <Box>
                <SmallImageUpload
                  value={
                    data?.beforePlay?.scratchCardData?.backgroundColorUploadUrl
                  }
                  onClick={() => {
                    if (
                      data?.beforePlay?.scratchCardData
                        ?.backgroundColorUploadUrl
                    ) {
                      handleBeforePlayChange(
                        "backgroundColor",
                        data?.beforePlay?.scratchCardData
                          ?.backgroundColorUploadUrl
                      );
                    }
                  }}
                  borderValue={
                    data?.beforePlay?.scratchCardData?.backgroundColor
                  }
                  onChange={(file) =>
                    handleBeforePlayChange("backgroundColorUpload", file)
                  }
                  handleImage={(value) =>
                    onChange({
                      ...data,
                      beforePlay: {
                        ...data.beforePlay,
                        scratchCardData: {
                          ...data.beforePlay.scratchCardData,
                          backgroundColorUpload: value,
                          [`backgroundColorUploadUrl`]: value,
                          [`backgroundColorUploadId`]: value,
                          backgroundColor: value,
                        },
                      },
                    })
                  }
                  image={
                    data?.beforePlay?.scratchCardData?.backgroundUploadBase64
                  }
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box mt={"-10px"} sx={{ width: "400px" }}>
            <ColorInput
              value={data?.beforePlay?.scratchCardData?.backgroundColorCode}
              onChange={(event) =>
                handleBeforePlayChange("backgroundColorCode", event)
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
