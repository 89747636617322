import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../utils/token";
import axios from "axios";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../utils/tooltip";
import usePermission from "../../utils/permissions";
import copyIcon from "../../assets/copy.svg";
import UiTable from "../UiTable";
import { toast, ToastContainer } from "react-toastify";
import CustomConfirmationDialog from "../../UIComponents/CustomConfirmationDialog";
import SearchBar from "../../UIComponents/SearchBar";
import exportImg from "../../assets/export.svg";
import plus from "../../assets/Plus.svg";
import documentsvg from "../../assets/Frame.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import deleteIcon from "../../assets/delete.png";
import QueryInput from "../QueryInput";
import ToggleColumn from "../../UIComponents/ToggleColumn";
import FilterAndSearch from "../Filter/FilterAndSearch";
import { formatDateNew } from "../../utils/formateDate";
import dayjs from "dayjs";
const TData = () => {
  return [
    { key: "transaction_id", displayName: "Transaction ID", flag: true },
    { key: "externalCustomerId", displayName: "Customer#", flag: true },
    { key: "product_code", displayName: "Product Code", flag: true },
    { key: "merchant_code", displayName: "Merchant", flag: true },
    { key: "offer_name", displayName: "Offer Name", flag: true },
    { key: "status", displayName: "Status", flag: true },
    { key: "initiative_code", displayName: "Initiative Code", flag: true },
    { key: "category_code", displayName: "Category", flag: true },
    { key: "subcategory_code", displayName: "Subcategory", flag: true },
    {
      key: "loyality_reference_id",
      displayName: "Loyality Reference ID",
      flag: false,
    },
    { key: "game_type", displayName: "Game Type", flag: false },
    { key: "created_at", displayName: "Created At", flag: false },
    { key: "reward_type", displayName: "Reward Type", flag: false },
    {
      key: "integration_status",
      displayName: "Integration Status",
      flag: false,
    },
    { key: "scratch_card_id", displayName: "Scratch Card ID", flag: false },
  ];
};

const dateFilterLayout = {
  select: "ALL_TIME",
  from_time: "",
  to_time: "",
};

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

const titleStyle = {
  fontFamily: "Lato",
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0em",
  color: "#0F1828",
};

const subTitle = {
  fontFamily: "Lato",
  width: "325px",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "21px",
  textAlign: "center",
  letterSpacing: "0em",
  color: "#0F182899",
};

export default function TransactionsPage() {
  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  const [dateFilter, setDateFilter] = useState(dateFilterLayout);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterDone, setFilterDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState([]);
  const [filterRemoveFlag, setFilterRemoveFlag] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    return Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
  });
  const filterCount = Array.from(searchParams.keys()).length;

  const [transactions, setTransactions] = useState({
    data: [],
    paginationModel: {
      pageSize: 15,
      page: 0,
    },
    totalCount: 0,
    allOfferCount: 0,
    pendingOfferCount: 0,
    denyOfferCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [offerStatus, setOfferStatus] = useState(false);
  const [noOptions, setNoOptions] = useState(false);
  const [editData, setEditData] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const permission = usePermission("/view-transactions");
  const IdRef = useRef("");
  const navigate = useNavigate();

  console.log(permission);

  const getAllTransactions = async () => {
    setLoading(true);
    const body = {
      page: transactions?.paginationModel.page + 1,
      itemsPerPage: transactions?.paginationModel.pageSize,
      ...(search && { externalCustomerId: search }),
      ...selectedFilters,
    };

    if (dateFilter?.select) {
      body["day"] = dateFilter?.select;
    }

    if (dateFilter?.select === "CUSTOM") {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      body["dateStart"] = startDate;
      body["dateEnd"] = endDate;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${baseUrl}/api/transactions`, body, {
        headers,
      });
      console.log(response.data);
      const formateData = response?.data?.data.map((e) => {
        return {
          transaction_id: e?.transaction_id,
          externalCustomerId: e?.externalCustomerId,
          product_code: e?.product_code,
          merchant_code: e?.merchant_code,
          offer_name: e?.offer_name,
          points_issued: e?.points_issued,
          reward_currency: e?.reward_currency,
          status: e?.status,
          offer_description: e?.offer_description,
          offer_type: e?.offer_type,
          offer_id: e?.offer_id,
          initiative_code: e?.initiative_code,
          category_code: e?.category_code,
          subcategory_code: e?.subcategory_code,
          loyality_reference_id: e?.loyality_reference_id,
          created_at: e?.created_at,
          game_type: e?.game_type,
          offer_status: e?.offer_status,
          reward_type: e?.reward_type,
          integration_status: e?.integration_status,
          scratch_card_id: e?.scratch_card_id,
        };
      });

      setTransactions((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.ledgerCount ?? 0,
      }));
      setStartDate(response.data.day_start_date ?? "");
      setEndDate(response.data.day_end_date ?? "");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const exportToCSV = () => {
    if (transactions.data.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Generate headers dynamically
    const headers = Object.keys(transactions.data[0]).filter(
      (key) => key !== "action"
    );

    // Convert header keys to display-friendly format
    const displayHeaders = headers.map((header) =>
      header
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );

    const csvData = transactions.data.map((offer) =>
      headers.map((header) => {
        if (header === "visibility") {
          return offer[header] ? "Visible" : "Hidden";
        }
        return offer[header];
      })
    );

    const csvContent = [
      displayHeaders.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "rule_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handlePaginationModel = (value) => {
    if (!value) return;
    setTransactions((prev) => ({ ...prev, paginationModel: value }));
  };

  const handleSearch = () => {
    if (search.length > 0) {
      setNoOptions(true);
      getAllTransactions();
      // setSearchFlag(prev=>!prev)
    } else {
      setNoOptions(false);
      setTransactions((prev) => ({ ...prev, data: [] }));
    }
  };

  const fetchFilters = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    // const token = getToken();

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=transaction`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        let obj = {};
        const data = config.map((res) => {
          if (!obj.hasOwnProperty(res?.name)) {
            obj[res?.name] = [];
          }
          return { ...res, state: [] };
        });
        setFilters(response?.data);
        console.log(config, data, "filter");
        // setFilterOptions(obj);
        // setFilterConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateFilter = (name, value) => {
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === "ALL_TIME") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }

    if (value === "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
  };

  const handleToggleColumn = (i) => {
    console.log(i, "index");
    const data = fieldsName.map((e, index) => {
      if (index === i) {
        e.flag = !e.flag;
      }
      return e;
    });
    console.log(data, "inside handleToggleColumn");
    setFieldName(data);
  };

  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            color="#043A87"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "externalCustomerId",
      headerName: "Customer#",

      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              color="#043A87"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "product_code",
      headerName: "Product Code",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "merchant_code",
      headerName: "Merchant",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },

    {
      field: "offer_name",
      headerName: "Offer Name",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    // {
    //   field: "points_issued",
    //   headerName: "Points Issued",
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return (
    //         <Box
    //           fontFamily="Lato"
    //           fontWeight={400}
    //           fontSize="12px"
    //           lineHeight="14.4px"
    //           letterSpacing="2%"
    //           overflow="hidden"
    //           textOverflow="ellipsis"
    //           whiteSpace={"nowrap"}
    //         >
    //           NA
    //         </Box>
    //       );
    //     }

    //     return (
    //       <Box
    //         fontFamily="Lato"
    //         fontWeight={400}
    //         fontSize="12px"
    //         lineHeight="14.4px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params?.value}
    //       </Box>
    //     );
    //   },
    // },

    // {
    //   field: "reward_currency",
    //   headerName: "Reward Currency",
    //   minWidth: 120,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return (
    //         <Box
    //           fontFamily="Lato"
    //           fontWeight={400}
    //           fontSize="12px"
    //           lineHeight="14.4px"
    //           letterSpacing="2%"
    //           overflow="hidden"
    //           textOverflow="ellipsis"
    //           whiteSpace={"nowrap"}
    //         >
    //           NA
    //         </Box>
    //       );
    //     }
    //     let phoneNumber = params.value;
    //     let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
    //     return (
    //       <Typography
    //         fontFamily="Lato"
    //         fontWeight={400}
    //         fontSize="12px"
    //         lineHeight="14.4px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    // {
    //   field: "offer_description",
    //   headerName: "Offer Description",
    //   minWidth: 120,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return (
    //         <Box
    //           fontFamily="Lato"
    //           fontWeight={400}
    //           fontSize="12px"
    //           lineHeight="14.4px"
    //           letterSpacing="2%"
    //           overflow="hidden"
    //           textOverflow="ellipsis"
    //           whiteSpace={"nowrap"}
    //         >
    //           NA
    //         </Box>
    //       );
    //     }

    //     return (
    //       <Box
    //         fontFamily="Lato"
    //         fontWeight={400}
    //         fontSize="12px"
    //         lineHeight="14.4px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params?.value}
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   field: "offer_type",
    //   headerName: "Offer Type",
    //   minWidth: 120,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return (
    //         <Box
    //           fontFamily="Lato"
    //           fontWeight={400}
    //           fontSize="12px"
    //           lineHeight="14.4px"
    //           letterSpacing="2%"
    //           overflow="hidden"
    //           textOverflow="ellipsis"
    //           whiteSpace={"nowrap"}
    //         >
    //           NA
    //         </Box>
    //       );
    //     }

    //     return (
    //       <Box
    //         fontFamily="Lato"
    //         fontWeight={400}
    //         fontSize="12px"
    //         lineHeight="14.4px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params?.value}
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   field: "offer_id",
    //   headerName: "Offer Id",
    //   minWidth: 120,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return (
    //         <Box
    //           fontFamily="Lato"
    //           fontWeight={400}
    //           fontSize="12px"
    //           lineHeight="14.4px"
    //           letterSpacing="2%"
    //           overflow="hidden"
    //           textOverflow="ellipsis"
    //           whiteSpace={"nowrap"}
    //         >
    //           NA
    //         </Box>
    //       );
    //     }

    //     return (
    //       <Box
    //         fontFamily="Lato"
    //         fontWeight={400}
    //         fontSize="12px"
    //         lineHeight="14.4px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params?.value}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "initiative_code",
      headerName: "Initiative Code",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "category_code",
      headerName: "Category ",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "subcategory_code",
      headerName: "Subcategory ",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "loyality_reference_id",
      headerName: "Loyality Reference ID",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "game_type",
      headerName: "Game Type",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "reward_type",
      headerName: "Reward Type",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "integration_status",
      headerName: "Integration Status",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "scratch_card_id",
      headerName: "Scratch Card ID",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
  ];

  console.log(transactions, "transactions");
  // useEffect(() => {
  //   let debounceTimer;
  //   if (filters?.length > 0) {
  //     // if (flag) {
  //     //   setFlag(false);
  //     // } else {
  //     debounceTimer = setTimeout(() => {
  //       getAllTransactions();
  //     }, 800);
  //     // }
  //   }

  //   return () => {
  //     clearTimeout(debounceTimer);
  //   };
  // }, [search]);

  useEffect(() => {
    // let debounceTimer;
    if (dateFilter.select !== "CUSTOM" && filters?.length > 0) {
      if (flag) {
        setFlag(false);
      } else {
        // debounceTimer = setTimeout(() => {
        getAllTransactions();
        // }, 0);
      }
    }

    // return () => {
    //   clearTimeout(debounceTimer);
    // };
  }, [
    offerStatus,
    transactions?.paginationModel?.page,
    transactions?.paginationModel?.pageSize,
    dateFilter?.select,
    // activeTab,
    filterDone,
    filterRemoveFlag,
    filters,
    // search
  ]);

  useEffect(() => {
    if (dateFilter.select === "CUSTOM") {
      if (
        typeof dateFilter?.from_time === "string" &&
        typeof dateFilter?.to_time === "string" &&
        dateFilter.from_time &&
        dateFilter.to_time
      ) {
        console.log(
          "inside custom date filter",
          dateFilter?.to_time,
          dateFilter?.from_time
        );
        getAllTransactions();
      }
    }
  }, [
    dateFilter,
    filterDone,
    filterRemoveFlag,
    transactions?.paginationModel?.page,
    transactions?.paginationModel?.pageSize,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateFilter((prev) => ({
        ...prev,
        from_time: dayjs(new Date(startDate)),
        to_time: dayjs(new Date(endDate)),
      }));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let filteredTData = fieldsName?.filter((column) => column.flag === true);

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    // Step 3: Add the action object at the end
    // const actionColumn = columns.find((column) => column.field === "action");
    // if (actionColumn) {
    //   filteredColumns.push(actionColumn);
    // }

    setColumnFields(filteredColumns);
  }, [fieldsName]);

  useEffect(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    const filterData = Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
    setSelectedFilters(filterData);
  }, [searchParams, filters, filterRemoveFlag]);

  useEffect(() => {
    fetchFilters();
    // to remove the search params from the url on page load
    setSearchParams({});
  }, []);

  return (
    <Box sx={{ padding: "20px" }}>
      <ToastContainer />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: "16px" }}
      >
        <QueryInput
          placeholder={"Search user by User#"}
          value={search}
          setState={setSearch}
          handleSearch={handleSearch}
          hitEnter={true}
        />
        <Box
          sx={{
            display:
              noOptions && transactions.data?.length > 0 ? "block" : "none",
          }}
        >
          <Button
            sx={{
              height: "30px",
              width: "106px",
              backgroundColor: "#E8F0F9",
            }}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={exportImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14.4px",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
              onClick={exportToCSV}
            >
              Export Data
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box>
        {!noOptions ? (
          <Stack
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"30vh"}
          >
            <Avatar
              sx={{
                borderRadius: "0",
              }}
              src={documentsvg}
            />
            <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
              <>
                <Typography sx={titleStyle}>Search User#</Typography>
                <Typography sx={subTitle}>
                  Simply type the user# into the search box and instantly
                  retrieve the data.
                </Typography>
              </>
            </Stack>
          </Stack>
        ) : // loading ? (
        //   <Stack
        //     spacing={2}
        //     justifyContent={"center"}
        //     alignItems={"center"}
        //     marginTop={"30vh"}
        //   >
        //     <Typography sx={titleStyle}>Loading...</Typography>
        //   </Stack>
        // ) :
        // (noOptions && transactions?.data.length > 0) || loading ? (
          <Box>
            <FilterAndSearch
              hideSearch={true}
              search={search}
              setSearch={setSearch}
              tableFields={fieldsName}
              handleToggleColumn={handleToggleColumn}
              columnDisableIndex={1}
              // filterCount={3}
              filterCount={filterCount}
              dateFilter={dateFilter}
              filterSelectOptions={filterSelectOptions}
              handleDateFilter={handleDateFilter}
              filterData={filters}
              setFilterDone={setFilterDone}
              setFilterRemoveFlag={setFilterRemoveFlag}
            />

            <UiTable
              row={transactions?.data || []}
              alternaviteIdName={"transaction_id"}
              columns={columnFields || []}
              totalCount={transactions?.totalCount}
              paginationModel={transactions?.paginationModel}
              setPaginationModel={handlePaginationModel}
              loading={loading}
            />
            {/* <CustomConfirmationDialog
              open={editData}
              setOpen={setEditData}
              deleteById={handleStatusChangeByID}
              prop={IdRef.current}
              status="delete"
              message={message}
            /> */}
          </Box>
        // ) : (
        //   <Stack
        //     spacing={2}
        //     justifyContent={"center"}
        //     alignItems={"center"}
        //     marginTop={"30vh"}
        //   >
        //     <Typography sx={titleStyle}>No Data Found</Typography>
        //   </Stack>
        // )
        }
      </Box>
    </Box>
  );
}
