import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import AnalyticsSection from "./AnalyticsSection";
import CustomPieChart from "./charts/CustomPieChart";
import CustomAreaChart from "./charts/CustomAreaChart";
import { initialStateOverviewFilter } from "../utils/initialStates";
import axios from "axios";
import { useSelector } from "react-redux";
import CircularLoader from "./CircularLoader";
import { formatDateNew } from "../utils/formateDate";
import FilteredResults from "./FilteredResults";


const areaChartCss = [
  {
    strokeColor: "#043A87",
    backgroundColor: "#043A87",
  },
  {
    strokeColor: "#DA8BFF",
    backgroundColor: "#DA8BFF",
  },
];

const Overview = ({
  activeButton,
  filter = [],
  handleReset,
  doneButton,
  dateFilter,
  handleRemove,
  handleDateFilter,
}) => {
  const [overviewData, setOverviewData] = useState({});
  const [loading, setLoading] = useState(false);
  // const trackDoneButton = useRef(false);
  const [showFilter, setShowFilter] = useState([]);
  // const [OfferDistribution, setOfferDistribution] = useState([false, false]);
  const [date, setDate] = useState({
    from_time: "",
    to_time: "",
  });

  const token = useSelector((store) => store.AuthReducer.token);

  const sectionTitleStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const flagRef = useRef("");

  const getOverview = async (status, data) => {
    setLoading(true);
    const baseurl = process.env.REACT_APP_API_BASE_URL;

    if (status === "done") {
      setShowFilter(filter);
    }

    const url = `${baseurl}/api/analytics/rewards`;
    const query = {};
    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      query["dateStart"] = startDate;
      query["dateEnd"] = endDate;
    }

    if (dateFilter?.select) {
      query["day"] = dateFilter?.select;
    }

    if (!status && data) {
      filter?.forEach((res) => {
        if (res?.state?.length > 0) {
          query[res?.name] = res?.state;
        }
      });
    } else if (status === "clear") {
      data?.forEach((res) => {
        if (res?.state?.length > 0) {
          query[res?.name] = res?.state;
        }
      });
    } else if (status === "done") {
      filter?.forEach((res) => {
        if (res?.state?.length > 0) {
          query[res?.name] = res?.state;
        }
      });
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(url, query, config);

      const data = response?.data?.data;

      //We are adding 2 empty strings because we want to make changes in the parent object instead of specific properties.

      if (dateFilter?.select !== "CUSTOM") {
        handleDateFilter(activeButton, "", "", {
          from_time: response?.data?.day_start_date,
          to_time: response?.data?.day_end_date,
        });
      }

      const piechartColors = [
        ["#685EDD", "#D6D6D6"],
        ["#00A52E", "#3C3C3C", "#D9D9D9"],
      ];

      const overviewOptions = [
        {
          title: "All Rewards Analytics",
          options: [...data?.reward_analytics, ...data?.currency_rewards] ?? [],
        },
        {
          title: "Scratch Card Analytics",
          options: data?.scratch_card_analytics ?? [],
        },
      ];

      const piechartOptions = data?.graph_data?.map((res, index) => ({
        ...res,
        color: piechartColors[index],
      }));

      const areaChart = data?.area_chart?.map((res, index) => ({
        ...res,
        ...areaChartCss[index],
      }));

      setOverviewData({
        overviewOptions,
        piechartOptions,
        areaChart,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  // const handleOfferDistribution = (i) => {
  //   setOfferDistribution((prev) => {
  //     return prev?.map((value, index) => (index === i ? !value : value));
  //   });
  // };

  const removeFilters = (name, value) => {
    handleRemove(name, value);
    const latestData = showFilter.map((filter) =>
      filter?.name === name
        ? {
            ...filter,
            state: filter.state.filter((res) => res !== value),
          }
        : filter
    );
    setShowFilter((prevOptions) => {
      const updatedData = prevOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );

      return updatedData;
    });

    getOverview("clear", latestData);
  };

  const cssOptions = {
    "All Rewards Analytics": {
      titleColor: "#585858",
      columnsInRow: 3,
    },
    "Scratch Card Analytics": {
      titleColor: "#585858",
      sidebackgroundColor: "#D4D71E",
      columnsInRow: 3,
    },
  };

  useEffect(() => {
    getOverview("done");
  }, [doneButton]);

  useEffect(() => {
    if (!flagRef.current) {
      flagRef.current = "initialized";
      return;
    }

    if (dateFilter?.select !== "CUSTOM") {
      getOverview();
    }
  }, [dateFilter?.select]);

  useEffect(() => {
    if (
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time
    ) {
      setDate({
        from_time: dateFilter?.from_time ?? "",
        to_time: dateFilter?.to_time ?? "",
      });
    }
  }, [dateFilter?.from_time, dateFilter?.to_time]);

  useEffect(() => {
    let debounceTimer;
    if (
      (date?.from_time || date?.to_time) &&
      date?.from_time !== "Invalid Date" &&
      date?.to_time !== "Invalid Date"
    ) {
      debounceTimer = setTimeout(() => {
        getOverview("done");
      }, 0);

    }
    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [date]);

  useEffect(() => {
    return () => {
      handleReset(activeButton, initialStateOverviewFilter);
    };
  }, []);

  return loading ? (
    <CircularLoader />
  ) : (
    <Box sx={{ paddingBottom:"30px" }}>
      {showFilter && showFilter && (
        <FilteredResults options={showFilter} handleRemove={removeFilters} />
      )}
      <Stack spacing={5} boxSizing={"border-box"} width={"100%"} mt={"15px"}>
        {overviewData?.overviewOptions?.map((res) => (
          <AnalyticsSection
            title={res.title}
            options={res?.options ?? []}
            titleColor={cssOptions[res.title]?.titleColor}
            sidebackgroundColor={cssOptions[res.title]?.sidebackgroundColor}
            columnsInRow={cssOptions[res.title]?.columnsInRow}
          />
        ))}
      </Stack>

      <Stack spacing={3} mt={"32px"}>
        <Typography sx={sectionTitleStyle}>Fulfilment Chart</Typography>
        <Stack spacing={5}>
          <Stack direction={"row"} spacing={"42px"}>
            {overviewData?.piechartOptions?.map((res) => (
              <CustomPieChart
                key={res.legend}
                legend={res.legend}
                color={res.color}
                series={res.series}
                options={res.options}
                data={res.data}
                pieCenterData={res.pieCenterData}
              />
            ))}
          </Stack>
          <Stack direction={"column"} spacing={"42px"} >
            {overviewData?.areaChart?.map((res) => (
              <CustomAreaChart
                // enbaleOfferDistribution={true}
                key={res?.legend?.label}
                legend={res?.legend ?? {}}
                data={res?.data}
                strokeColor={res?.strokeColor}
                backgroundColor={res?.backgroundColor}
              />
            ))}
            {/* <CustomAreaChart />
            <CustomAreaChart /> */}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Overview;
