// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scratchCard_container__6ocYT {
  width: 300px;
  height: 424px;
  border-radius: 24px;
  background-color: #fff;
}

.scratchCard_contentContainer__2k5v1 {
  width: 300px;
  height: 424px;
  border-radius: 24px;
}

.scratchCard_headerText__SXtyq {
  color: #212121;
  margin: 10px 0;
  font-family: Proxima Nova A;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/offers/scratchCard.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACJ;;AACA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AAEJ;;AAIA;EACI,cAAA;EACA,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADJ","sourcesContent":[".container {\n    width: 300px;\n    height: 424px;\n    border-radius: 24px;\n    background-color: #fff;\n}\n.contentContainer {\n    width: 300px;\n    height: 424px;\n    border-radius: 24px;\n    // border-radius: 12px;\n    // background-color: #FBD51A;\n    // background: linear-gradient(116deg, #D8BB3A 35.37%, rgba(255, 243, 191, 0.00) 161.4%);\n}\n\n.headerText {\n    color: #212121;\n    margin: 10px 0;\n    font-family: Proxima Nova A;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `scratchCard_container__6ocYT`,
	"contentContainer": `scratchCard_contentContainer__2k5v1`,
	"headerText": `scratchCard_headerText__SXtyq`
};
export default ___CSS_LOADER_EXPORT___;
