import styles from "./deal.module.scss";
import React, { useEffect, useState } from "react";
import infoIcon from "../../../assets/Info-icon-black.png";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import { Box, Button, Divider, Typography } from "@mui/material";
import { RxCross1 } from "react-icons/rx";

const Deals = ({ dealData, poster }) => {
  const [data, setData] = useState({});

  function formatDateRange(startDate, endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const startMonth = startDateObj.toLocaleString("default", {
      month: "short",
    });
    const endMonth = endDateObj.toLocaleString("default", { month: "short" });
    const startDay = startDateObj.getDate();
    const endDay = endDateObj.getDate();
    const formattedString = `Valid From ${startDay} ${startMonth} to ${endDay} ${endMonth}`;
    return formattedString;
  }

  useEffect(() => {
    setData(dealData);
  }, [dealData]);

  return (
    <Box sx={{ width: "100%", backgroundColor: "#FFFFFF" }}>
      <Box
        sx={{
          height: "32px",
          backgroundColor: "#212121",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "5px",
          paddingLeft: "15px",
          paddingRight: "10px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            color: "#E2E2E2",
          }}
        >
          My Rewards
        </Typography>
        <RxCross1
          style={{ color: "#FFFFFF", fontSize: "14px", marginLeft: "5px" }}
        />
      </Box>

      <Box>
        {data.coverImageUrl && (
          <img
            src={data.coverImageUrl}
            alt="logo"
            style={{
              aspectRatio: "4/3",
              width: "100%",
              height: "auto",
            }}
          />
        )}
      </Box>

      <Box sx={{ padding: "8px 8px 0" }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={infoIcon} height={12} width={12} alt="info" />
            </Box>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "14px",
                letterSpacing: "0.4px",
                textAlign: "left",
                color: "#2A2A2A",
              }}
            >
              {data?.startDate && data?.endDate
                ? formatDateRange(data?.startDate, data.endDate)
                : ""}
            </Typography>
          </Box>
          <Box sx={{ width: "200px", margin: "auto", overflow: "hidden" }}>
            <Divider
              sx={{
                margin: "12px -8.5px",
                height: "1px",
                background:
                  "linear-gradient(to right, transparent 40%, #929094 50%), linear-gradient(to right, #ffffff, #ffffff)",
                backgroundSize: "12px 1px",
              }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
                lineHeight: "14px",
                letterSpacing: "1.5px",
                textAlign: "left",
                color: "#005CBD",
                padding: "4px 4px 6px 4px",
              }}
            >
              Terms And Conditions
            </Typography>
            {data?.termsAndConditions && (
              <Box
                sx={{
                  backgroundColor: "#f4f4f4",
                  padding: "8px 15px",
                  borderRadius: "6px",
                  textAlign: "left",
                  color:"#212121",
                  fontFamily: "Proxima Nova A",
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  wordWrap: "break-word",
                  maxHeight: "120px",
                  // Enhanced typography styles
                  "& h1, & h2, & h3, & h4, & h5, & h6": {
                    fontWeight: "bold",
                    lineHeight: 1.2,
                    margin:"0px",
                    // marginBottom: "8px",
                  },
                  "& h1": { fontSize: "18px" },
                  "& h2": { fontSize: "16px" },
                  "& h3": { fontSize: "15px" },
                  "& h4": { fontSize: "14px" },
                  "& h5": { fontSize: "13px" },
                  "& h6": { fontSize: "12px" },
                  // Paragraph styles
                  "& p": {
                    // marginBottom: "12px",
                    fontSize: "12px",
                    lineHeight: "1.4",
                  },
                  // Text formatting
                  "& strong, & b": {
                    fontWeight: "bold",
                  },
                  "& em, & i": {
                    fontStyle: "italic",
                  },
                  // List styles
                  "& ul, & ol": {
                    paddingLeft: "12px",
                    // marginBottom: "12px",
                    // marginTop: "4px",
                  },
                  "& ul": {
                    listStyleType: "disc",
                  },
                  "& ol": {
                    listStyleType: "decimal",
                  },
                  // Nested list styles
                  "& ul ul": {
                    listStyleType: "circle",
                    marginBottom: "0",
                  },
                  "& ul ul ul": {
                    listStyleType: "square",
                  },
                  "& ol ol": {
                    listStyleType: "lower-alpha",
                    marginBottom: "0",
                  },
                  "& ol ol ol": {
                    listStyleType: "lower-roman",
                  },
                  "& li": {
                    marginBottom: "4px",
                    lineHeight: "1.4",
                  },
                  // Link styles
                  "& a": {
                    color: "#005CBD",
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  },
                  // Table styles
                  "& table": {
                    borderCollapse: "collapse",
                    width: "100%",
                    marginBottom: "12px",
                    fontSize: "13px",
                  },
                  "& th, & td": {
                    border: "1px solid #ddd",
                    padding: "6px",
                    textAlign: "left",
                  },
                  "& th": {
                    backgroundColor: "#f2f2f2",
                    fontWeight: "bold",
                  },
                  // Quote styles
                  "& blockquote": {
                    borderLeft: "3px solid #005CBD",
                    margin: "12px 0",
                    paddingLeft: "12px",
                    fontStyle: "italic",
                    color: "#555",
                  },
                  // Code styles
                  "& code": {
                    backgroundColor: "#e6e6e6",
                    padding: "2px 4px",
                    borderRadius: "3px",
                    fontSize: "12px",
                    fontFamily: "monospace",
                  },
                  // Horizontal rule
                  "& hr": {
                    border: "none",
                    borderTop: "1px solid #ddd",
                    margin: "12px 0",
                  },
                  // Image styles
                  "& img": {
                    maxWidth: "100%",
                    height: "auto",
                    marginBottom: "12px",
                    borderRadius: "4px",
                  },
                  // Jodit specific styles
                  "& .jodit-wysiwyg": {
                    minHeight: "50px",
                    padding: "8px",
                  },
                  // Alignment classes
                  "& .jodit-text-center": {
                    textAlign: "center",
                  },
                  "& .jodit-text-right": {
                    textAlign: "right",
                  },
                  "& .jodit-text-justify": {
                    textAlign: "justify",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: data?.termsAndConditions || "",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          backgroundColor: '#FDFDFD',
          maxHeight: '60px',
          padding: '12px 10px',
          width: '100%',
          boxSizing: 'border-box',
          boxShadow: '0px -1px 2px 0px #7E7E7E4D, 0px -3px 6px 2px #59595926',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span
          style={{
            width: '220px',
            height: '24px',
            backgroundColor: '#8800EC',
            border: '1px solid #8800ec',
            color: '#FFF',
            fontFamily: 'Proxima_Nova_A',
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '1.5px',
            borderRadius: '4px',
            textAlign: 'center',
            margin: 0,
            padding: 0,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          GO BACK
        </span>
      </Box>
    </Box>
  );
};

export default Deals;