import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { FaCaretUp } from "react-icons/fa6";
import { MdPeopleAlt } from "react-icons/md";
const AnalyticsCard = ({
  value,
  valueColor="#1D1F2C",
  title,
  height,
  width,
  titleColor = "#585858",
  // sidebackgroundColor = "#9383D1",
  icon,
  percentage = "10%",
  percentageColor="#1A9882",
  percentageText ="+15 last week",
  percentageTextColor="#858D9D",
}) => {
  // css
  // const containerStyle = {
  //   width: "100%",
  //   height: height ? height : "100px",
  //   gap: "10%",
  //   borderRadius: "12px 12px 12px 12px",
  //   background: "#fff",
  //   position: "relative",
  //   boxSizing: "border-box",
  //   display: "flex",
  //   alignItems: "center", // Vertically center the content
  // };

  // const analyticsCashTextStyle = {
  //   fontFamily: "Proxima Nova A",
  //   fontSize: "26px",
  //   fontWeight: 700,
  //   lineHeight: "32px",
  //   letterSpacing: "0em",
  //   textAlign: "left",
  // };

  // const analyticsTitleTextStyle = {
  //   fontFamily: "Proxima Nova A",
  //   fontSize: "18px",
  //   fontWeight: 400,
  //   lineHeight: "21.9px",
  //   color: titleColor,
  //   textTransform  : "capitalize",
  //   textAlign: "left",
  // };

  return (

    // <Box sx={containerStyle}>
    //   <Box
    //     sx={{
    //       width: "6px",
    //       height: "96%",
    //       background: sidebackgroundColor,
    //       borderRadius: "12px 0 0  12px",
    //     }}
    //   ></Box>
    //   <Box
    //     sx={{
    //       display: "flex",
    //       justifyContent: "flex-start",
    //       alignContent: "center",
    //     }}
    //   >
    //     <Stack spacing={2}>
    //       <Typography sx={analyticsCashTextStyle}>{value}</Typography>
    //       <Typography sx={analyticsTitleTextStyle}>{title}</Typography>
    //     </Stack>
    //   </Box>
    // </Box>

    <Box sx={{
      backgroundColor: "#fff",
      borderRadius: "8px",
      padding: "14px",
    }}>
      <Box sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
        <Typography sx={{
          fontFamily:"Lato",
          fontSize:"12px",
          fontWeight:500,
          lineHeight:"14.4px",
          color:titleColor,
          textAlign:"start",
        }}>
        # {title}
        </Typography>
        {/* <img src="" alt="" /> */}
        {/* <MdPeopleAlt style={{height:"24px", width:"24px"}} color="#286CBD" /> */}
      </Box>

      <Box 
      // mt="-2px" 
      mt="8px" 
      mb="12px" 
      >
        <Typography sx={{
          fontFamily:"Lato",
          fontSize:"22px",
          fontWeight:700,
          lineHeight:"26.4px",
          color:valueColor,
          textAlign:"start"
        }}>
        {value}
        </Typography>
      </Box>

      {/* <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:"100%",gap:"4px"}}>
        <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"center",gap:"3px"}}>
      <Typography sx={{
          fontFamily:"Lato",
          fontSize:"12px",
          fontWeight:500,
          lineHeight:"14.4px",
          color:percentageColor,
          textAlign:"start"
        }}>
        {percentage}
        </Typography>
        <FaCaretUp color={percentageColor} style={{height:"10px",marginTop:"2px"}}/> 
        </Box>
        <Typography sx={{
          fontFamily:"Lato",
          fontSize:"12px",
          fontWeight:500,
          lineHeight:"14.4px",
          color:percentageTextColor,
          textAlign:"start"
        }}>
        {percentageText}
        </Typography>
    
      </Box> */}
    </Box>
  );
};

export default AnalyticsCard;
