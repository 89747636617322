
import React from "react";
// import Image from "next/image";
// import LogoIMG from "../../../assets/images/couponImg.png";
import Styles from "./scratchCard.module.scss";
// import LOGOIMGPURPLE from "@assets/images/Rectangle 40444.svg";


// function getScratchCardCoverByName(currency) {
//   switch (currency) {
//     case "NEUCOIN":
//       return {
//         coverImage: LOGOIMGPURPLE,
//         background: "linear-gradient(180deg, #CB50FF 0%, #8B4AC1 100%)",
//       };
//     case "DIGIGOLD":
//       return {
//         coverImage: LogoIMG,
//         background: "linear-gradient(154.18deg, #FFED8F, #FBD51A)",
//       };
//   }
// }



const ScratchCardCoupon = ({
//   currencyCode,
  coverImage
}) => {
//   console.log("reward++++++++++++++++:", reward);
//   const cover = getScratchCardCoverByName(currencyCode);
//   const { scratchCardStatus = null } = reward || {};
//   const [visible, setVisible] = useState(false);
//   const [couponScratched, setCouponScratched] = useState(
//     Boolean(scratchCardStatus === ScratchCardStatusEnum.ALLOTTED)
//   );


//   useEffect(() => {
//     setTimeout(() => {
//       setVisible(true);
//     }, 1000);
//   });
//   const scratchStart = () => {
//     setCouponScratched(true);
//   };
  return (
    <div
      className={Styles.container}
    >
      <div className={Styles.contentContainer} >
        {true && <img src={coverImage || ""} width={300} height={424} />}
        
      </div>
    </div>
  );
};

export default ScratchCardCoupon;
