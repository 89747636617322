import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';

export default function HorizontalLinearStepper({activeStep, steps, setActiveStep}) {
  return (
    <Box sx={{ width:activeStep===0? '98%':"100%", mb: "20px", }}>
      <Stepper 
        activeStep={activeStep}
        sx={{
          '& .MuiStepConnector-line': {
            borderWidth: 2, // Increase the height of the connector
          },
          '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
            borderColor: '#003D86', // Blue color for active step
          },
          '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
            borderColor: '#003D86', // Blue color for completed steps
          },
        }}
      >
        {steps?.map((label, index) => {
          const stepProps = {};
          const labelProps = {
            sx: {
              '& .MuiStepLabel-label': {
                color: activeStep === index || index < activeStep ? '#003D86' : '#858D98',
                fontFamily: 'Lato',
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: '15.6px',
              },
              '& .MuiStepIcon-root': {
                color: activeStep === index ? '#E8F0F9' : 'white',
                border: (activeStep === index || index < activeStep) ? "1px solid transparent" : '1px solid #858D98',
                borderRadius: '50%',
              },
              '& .Mui-completed .MuiStepIcon-root': {
                color: '#003D86',
              },
              '& .Mui-active .MuiStepIcon-root': {
                color: '#E8F0F9',
              },
              '& .MuiStepIcon-text': {
                fill: (activeStep === index || index < activeStep) ? '#003D86' : '#858D98',
              },
            },
          };

          return (
            <Step key={label} {...stepProps}>
              <StepButton onClick={() => setActiveStep(index)}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}