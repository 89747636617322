import React, { useState, useEffect, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { ChromePicker } from "react-color";

const ColorInput = ({
  value,
  onChange,
  type = "text",
  placeholder,
  label = "",
  errorMessage = "",
  disable = false,
  mt = "10px",
  preFill,
  editBoolean
}) => {
  const [color, setColor] = useState(value);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const colorBoxRef = useRef(null);

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    if (!value && preFill) {
      handleInputChange(preFill);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target) &&
          colorBoxRef.current && !colorBoxRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleColorChange = (color) => {
    setColor(color.hex);
    onChange(color.hex);
  };

  const handleInputChange = (inputValue) => {
    setColor(inputValue);
    if (type === "number") {
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue) && numericValue >= 0) {
        onChange(numericValue);
      } else {
        onChange(""); 
      }
    } else {
      onChange(inputValue);
    }
  };

  const handleColorBoxClick = (e) => {
    e.stopPropagation();
    setShowPicker((prev) => !prev);
  };

  return (
    <Box sx={{ position: "relative", width: "100%", mt: mt }}>
      <Box sx={{ position: "relative", width: "100%" }}>
        <Box
          ref={colorBoxRef}
          sx={{
            backgroundColor: color,
            height: "20px",
            width: "20px",
            position: "absolute",
            left: "8px",
            top: "50%",
            transform: "translateY(-50%)",
            borderRadius: "3px",
            cursor: "pointer",
            zIndex: 2,
          }}
          onClick={handleColorBoxClick}
        />
        <TextField
          sx={{
            width: "100%",
            borderRadius: "6px",
            "& .MuiOutlinedInput-root": {
              height: "32px",
              fontFamily: "Lato",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              "& input": {
                padding: "8px 22px",
              },
              "& fieldset": {
                borderColor: editBoolean ? "orange" : "",
                borderRadius: "6px",
              },
              "&:hover fieldset": {
                borderColor: editBoolean ? "orange" : "",
              },
              "&.Mui-focused fieldset": {
                borderColor: editBoolean ? "orange" : "",
              },
              "&.Mui-disabled": {
                opacity: 1,
                "& fieldset": {
                  borderColor: editBoolean ? "orange" : "",
                },
              },
            },
            "& .MuiInputLabel-root": {
              fontFamily: "Lato",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              color: editBoolean ? "orange" : "",
              "&.Mui-focused": {
                color: editBoolean ? "orange" : "",
              },
              "&.Mui-disabled": {
                color: editBoolean ? "orange" : "#000000ba !important",
              },
            },
            "& .MuiInputBase-input::placeholder": {
              fontFamily: "Lato",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
            },
            "& .Mui-disabled": {
              "-webkit-text-fill-color": "#000000ba !important",
              backgroundColor: "transparent",
            },
          }}
          type={type}
          value={color}
          disabled={disable}
          onChange={(e) => handleInputChange(e.target.value)}
          label={label}
          placeholder={placeholder}
          size="small"
          helperText={errorMessage}
          error={!!errorMessage}
          onWheel={() => document.activeElement.blur()}
          InputProps={{
            sx: { pl: "20px" },
          }}
        />
      </Box>
      {showPicker && !disable && (
        <Box
          ref={pickerRef}
          sx={{
            position: "absolute",
            zIndex: 9999,
            top: "100%",
            left: "0",
            marginTop: "5px",
            paddingBottom: "20px",
          }}
        >
          <ChromePicker color={color} onChange={handleColorChange} />
        </Box>
      )}
    </Box>
  );
};

export default ColorInput;