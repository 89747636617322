export const getToken = () => {
  const data = localStorage.getItem("Auth");
  const AuthDetails = data ? JSON.parse(data) : {};
  return AuthDetails?.accessToken || "";
};

export const getUserId = () => {
  const data = localStorage.getItem("userId");
  const userId = data ? JSON.parse(data) : {};
  return userId?.userId || "";
};

export const getMakerCheckerPermission = () => {
  const data = localStorage.getItem("makerCheckerPermission");
  const permission = data ? JSON.parse(data) : {};
  return permission?.permission || "";
};

export const getAllowedRoutes = () => {
  const data = localStorage.getItem("allowedRouters");
  const allowedRoutes = data ? JSON.parse(data) : {};
  return allowedRoutes?.allowedRouters || "";
};