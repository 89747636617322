import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddColumnIcon from "../../assets/AddColumnIcon.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiButton from "../../UIComponents/MuiButton";

const AddColumn = ({ handleColumnAdd, columnFields, fieldsName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //   externalCustomerId [default]
  // organisation_code [default]
  // category_code
  // subcategory_code
  // product_code
  // initiative
  // merchant_code [default]
  // collection_name
  // offer_name [default]
  // offer_description
  // offer_type
  // start_date
  // expiry_date
  // status [default]
  // reward_currency
  // reward_type
  // transaction_id [default]
  // offer_id
  // reward_reference_id
  // points_issued [default]
  // points_blocked
  // points_redeemed
  // game_type
  // reward_code [default]

  const tableFields = fieldsName?.map((item, index) => {
    if (index > 6) {
      return {
        label: item.displayName,
        name: item.key,
      };
    }
    // If the condition is not met, return undefined
  }).filter(Boolean); // Filter out any undefined values

  // const tableFields = [
  //   {
  //     label: "Category",
  //     name: "category_code",
  //   },
  //   {
  //     label: "Subcategory",
  //     name: "subcategory_code",
  //   },
  //   {
  //     label: "Product ",
  //     name: "product_code",
  //   },
  //   {
  //     label: "Initiative",
  //     name: "initiative",
  //   },
  //   {
  //     label: "Collection Name ",
  //     name: "collection_name",
  //   },
  //   {
  //     label: "Offer Description",
  //     name: "offer_description",
  //   },
  //   {
  //     label: "Offer Type",
  //     name: "offer_type",
  //   },
  //   {
  //     label: "Start Date ",
  //     name: "start_date",
  //   },
  //   {
  //     label: "End Date",
  //     name: "expiry_date",
  //   },
  //   {
  //     label: "Reward Currency",
  //     name: "reward_currency",
  //   },
  //   {
  //     label: "Reward Type",
  //     name: "reward_type",
  //   },
  //   {
  //     label: "Offer ID",
  //     name: "offer_id",
  //   },
  //   {
  //     label: "Reward Reference ID",
  //     name: "reward_reference_id",
  //   },
  //   {
  //     label: "Points Blocked ",
  //     name: "points_blocked",
  //   },
  //   {
  //     label: "Points Redeemed",
  //     name: "points_redeemed",
  //   },
  //   {
  //     label: "Game Type",
  //     name: "game_type",
  //   },
  // ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, label) => {
    const { name, checked } = event.target;
    handleColumnAdd(checked, name, label);
  };

  return (
    <div>
      <MuiButton
        text=" Add Column"
        variant="outlined"
        style={{
          padding: "8px 20px",
          border: "1px solid rgba(0, 0, 0, 0.30)",
          marginBottom: "10px",
        }}
        onClick={handleClick}
        icon={AddColumnIcon}
        bg="white"
        color="black"
      >
        Add Column
      </MuiButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormGroup
        // onClick={handleChange}
        >
          {tableFields?.map((options) => {
            return (
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={options.name}
                      sx={{
                        color: "black",
                        "&.Mui-checked": {
                          color: "black",
                        },
                      }}
                      onClick={(e) => handleChange(e, options.label)}
                      checked={
                        !!columnFields?.find(
                          (fields) => fields.field === options.name
                        )
                      }
                    />
                  }
                  label={options.label}
                />
              </MenuItem>
            );
          })}
        </FormGroup>
      </Menu>
    </div>
  );
};

export default AddColumn;
