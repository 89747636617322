import React from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { Autocomplete, TextField, styled } from "@mui/material";

const OptionItem = styled("li")(({ theme, selected }) => ({
  width: "100%",
  borderBottom: "1px solid #E3E7EF",
  backgroundColor: selected ? "#edf4fb" : "inherit",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const MultiSelect = ({
  label = "",
  options,
  placeholder = "sdsada",
  onChange,
  value,
  errorMessage,
  isDisable = false,
  editBoolean,
}) => {
  const isAllSelected =
    options?.length > 0 && value?.length === options?.length;

  const handleChange = (event, reason) => {
    let inputValue = event;

    const latestData = event[event.length - 1];

    if (
      value?.some &&
      value?.some((prev) => prev?.id === latestData?.id) &&
      reason !== "removeOption"
    ) {
      inputValue = value.filter((ele) => ele.id !== latestData?.id);
    }

    // for all select

    // if (inputValue[inputValue?.length - 1] === "all") {
    //   onChange(
    //     value?.length === options?.length
    //       ? []
    //       : options.map((option) => option.value)
    //   );
    //   return;
    // }
    onChange(inputValue);
  };

  const getOptionLabel = (option) => {
    if (value?.some && value?.some((val) => val.id === option.id)) {
      return <div>{option.name}</div>;
    } else {
      return option.name;
    }
  };

  return (
    <FormControl
      sx={{ mt: "10px", width: "100%" }}
      variant="outlined"
      size="small"
      error={!!errorMessage}
    >
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        disableCloseOnSelect
        multiple
        disabled={isDisable}
        id="tags-outlined"
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            padding: "1px 5px",
            minHeight: "32px",
            borderRadius:"6px",
            fontFamily: "Lato",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            ...(editBoolean && {
              "& fieldset": {
                borderColor: "orange !important",
                borderWidth: "1px",
              },
              "&:hover fieldset": {
                borderColor: "orange !important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "orange !important",
              },
            }),
            ...(errorMessage && {
              "& fieldset": {
                borderColor: "#d40e10 !important",
                borderWidth: "1px",
              },
              "&:hover fieldset": {
                borderColor: "#d40e10 !important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#d40e10 !important",
              },
            }),
          },
          "& .MuiAutocomplete-tag": {
            borderRadius: "3px",
            height: "24px",
            padding: "0px",
            // backgroundColor:"#edf4fb",
            margin: "0px 2px",
          },
          "&.MuiChip-deleteIcon": {
            margin: "0px",
          },
          "& .MuiSvgIcon-root": {
            height: "18px",
          },
        }}
        options={options}
        getOptionLabel={getOptionLabel}
        // defaultValue={[top100Films[13]]}
        value={value?.length === 0 || !value ? [] : value}
        onChange={(event, newValue, reason) => {
          // onChange(newValue);
          console.log(reason, "reason");
          handleChange(newValue, reason);
        }}
        // filterSelectedOptions
        renderOption={(props, option, { selected }) => (
          <OptionItem
            {...props}
            selected={
              Array.isArray(value) && value?.some((val) => val.id === option.id)
            }
          >
            <Checkbox
              checked={
                Array.isArray(value) &&
                value?.some((val) => val.id === option.id)
              }
              style={{
                marginRight: 8,
                padding: "5px",
                color:
                  Array.isArray(value) &&
                  value?.some((val) => val.id === option.id)
                    ? "black"
                    : "gray",
              }}
            />
            {option.name}
          </OptionItem>
        )}
        renderInput={(params) => (
          <TextField
            sx={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
            }}
            {...params}
            // label="filterSelectedOptions"
            placeholder={value.length == 0 ? " --Select--" : "--Add More--"}
          />
        )}
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;
