import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  Chip,
  Button,
  TextField,
  InputAdornment,
  Stack,
  Paper,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { TiArrowSortedDown } from "react-icons/ti";
import { ChevronLeft } from "@mui/icons-material";

const Filter = ({
  filters,
  handleDone,
  setFilterDone,
  selectedFilters,
  setSelectedFilters,
  searchTerms,
  setSearchTerms,
  setOpen,
  hideSearchFromFilter,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const [expandedPanels, setExpandedPanels] = useState([]);
  
  const [expandedPanels, setExpandedPanels] = useState(() => 
    filters.map(filter => filter.name)
  );

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    Object.entries(selectedFilters).forEach(([key, values]) => {
      if (values.length > 0) {
        newSearchParams.set(key, values.join(","));
      } else {
        newSearchParams.delete(key);
      }
    });

    setSearchParams(newSearchParams);
  }, [selectedFilters, setSearchParams]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanels(prev => {
      if (isExpanded) {
        return [...prev, panel];
      } else {
        return prev.filter(p => p !== panel);
      }
    });
  };

  const handleCheckboxChange = (filterName, value) => {
    setSelectedFilters((prev) => {
      const currentValues = prev[filterName] || [];
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      return {
        ...prev,
        [filterName]: newValues,
      };
    });
  };

  const handleSearchChange = (filterName, value) => {
    setSearchTerms((prev) => ({
      ...prev,
      [filterName]: value.toLowerCase(),
    }));
  };

  const getFilteredOptions = (filter) => {
    const searchTerm = searchTerms[filter.name] || "";
    return filter.options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm)
    );
  };

  const getSelectedCount = (filterName) => {
    return selectedFilters[filterName]?.length || 0;
  };

  const handleClearFilter = (filterName) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: [],
    }));
    setSearchTerms((prev) => ({
      ...prev,
      [filterName]: "",
    }));
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({});
    setSearchTerms({});
    setSearchParams(new URLSearchParams());
    setOpen(false);
    setTimeout(() => {
      setFilterDone((prev) => !prev);
    }, 200);
  };

  const hasAnyFilters = Object.values(selectedFilters).some(
    (filters) => filters.length > 0
  );

  // Function to determine if search box should be shown
  const shouldShowSearch = (filter) => {
    return !hideSearchFromFilter && filter.options.length > 10;
  };

  const renderSelectedFilters = () => {
    if (!hasAnyFilters) return null;

    return (
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: "background.default",
          fontFamily: "Lato",
        }}
      >
        {/* <Typography variant="subtitle2" gutterBottom>
          Selected Filters
        </Typography> */}
        <Stack spacing={1.5}>
          {filters.map((filter) => {
            const selectedValues = selectedFilters[filter.name] || [];
            if (selectedValues.length === 0) return null;

            return (
              <Box key={filter.name}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "16.8px",
                      color: "#1D1F2C",
                    }}
                  >
                    {filter.summaryText}:
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selectedValues.map((value) => {
                      const option = filter.options.find(
                        (opt) => opt.value.toString() === value.toString()
                      );
                      return (
                        <Chip
                          key={value}
                          label={option?.label || value}
                          size="small"
                          onDelete={() =>
                            handleCheckboxChange(filter.name, value)
                          }
                          sx={{
                            maxWidth: "200px",
                            fontFamily: "Lato",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16.8px",
                            color: "#6F747E",
                            height: "16.8px",
                            "& .MuiChip-label": {
                              lineHeight: "16.8px",
                            },
                            "& .MuiChip-deleteIcon": {
                              margin: 0,
                            },
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
                {filter !== filters[filters.length - 1] && (
                  <Divider sx={{ mt: 1.5 }} />
                )}
              </Box>
            );
          })}
        </Stack>
      </Paper>
    );
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 400, fontFamily: "Lato" }}>
      <Box sx={{mb:"100px"}}>
      {renderSelectedFilters()}
      {filters.map((filter) => (
        <Accordion
          key={filter.name}
          expanded={expandedPanels.includes(filter.name)}
          onChange={handleAccordionChange(filter.name)}
          sx={{
            "&:before": {
              display: "none",
            },
            boxShadow: "none",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <AccordionSummary
            // expandIcon={
            //   <TiArrowSortedDown
            //     style={{
            //       height: "17px",
            //       width: "17px",
            //     }}
            //   />
            // }
            expandIcon={
              <ChevronLeft
                sx={{
                  rotate: "270deg",
                  transition: "color 0.3s",
                }}
              />
            }
            sx={{
              backgroundColor: "#F9F9FC",
              minHeight: "32px !important",
              height: "32px !important",
              padding: "0 16px !important",
              margin: "0 !important",
              "& .MuiAccordionSummary-content": {
                margin: "0 !important",
                minHeight: "32px !important",
                height: "32px !important",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Lato",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#535353",
                "&.Mui-expanded": {
                  margin: "0 !important",
                  minHeight: "32px !important",
                  height: "32px !important",
                  backgroundColor: "#F9F9FC",
                },
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                color: "#B5BBC5",
                "&.Mui-expanded": {
                  color: "#0B1B32",
                },
              },
            }}
          >
            <Typography>{filter.summaryText}</Typography>
            {getSelectedCount(filter.name) > 0 && (
              <Chip
                size="small"
                label={getSelectedCount(filter.name)}
                color="primary"
                sx={{ ml: 1 }}
                onDelete={() => handleClearFilter(filter.name)}
              />
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#FFFFFF",
              p: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {shouldShowSearch(filter) && (
                <TextField
                  size="small"
                  placeholder="Search options..."
                  value={searchTerms[filter.name] || ""}
                  onChange={(e) =>
                    handleSearchChange(filter.name, e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{ 
                    fontFamily: "Lato",
                    "& .MuiOutlinedInput-root": {
                      height: "30px",
                      margin: 0,
                      padding: 0,
                      "& input": {
                        padding: "0 8px",
                        height: "30px"
                      },
                      "& .MuiInputAdornment-root": {
                        height: "30px",
                        marginLeft: "8px"
                      }
                    }
                  }}
                />
              )}
              <FormGroup>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {getFilteredOptions(filter).map((option) => (
                    <FormControlLabel
                      key={option.value}
                      // style={{display: "flex", alignItems: "start",marginBottom:"16px"}}
                      control={
                        <Checkbox
                          sx={{
                            color: "gray",
                            "&.Mui-checked": {
                              color: "#003D86",
                            },
                            "&.Mui-disabled": {
                              color: "gray",
                            },
                            // height: "15px",
                            // width: "15px",
                            // margin: "0px 10px",
                          }}
                          checked={selectedFilters[filter.name]?.includes(
                            option.value.toString()
                          )}
                          onChange={() =>
                            handleCheckboxChange(
                              filter.name,
                              option.value.toString()
                            )
                          }
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "Lato",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "16.8px",
                            color: "#1D1F2C",
                          }}
                        >
                          {option.label}
                        </Typography>
                      }
                      sx={{
                        width: "50%",
                        m: 0,
                      }}
                    />
                  ))}
                  {getFilteredOptions(filter).length === 0 && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ py: 1, fontFamily: "Lato" }}
                    >
                      No matching options found
                    </Typography>
                  )}
                </Box>
              </FormGroup>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "48px",
          backgroundColor: "#F9F9F9",
          alignItems: "center",
          px: "16px",
          boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "318px",
          zIndex: 1000,
        }}
      >
        <Button
          onClick={handleClearAllFilters}
          disabled={!hasAnyFilters}
          sx={{
            height: "30px",
            width: "124px",
            fontFamily: "Lato",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16.8px",
            color: "#003D86",
            borderRadius: "5px",
            border: "1px solid #003D86",
            textTransform: "none",
          }}
        >
          Clear all Filters
        </Button>
        <Button
          onClick={handleDone}
          sx={{
            height: "30px",
            width: "124px",
            fontFamily: "Lato",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16.8px",
            backgroundColor: "#003D86",
            color: "#FFFFFF",
            borderRadius: "5px",
            border: "1px solid #003D86",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#003D86",
            },
          }}
        >
          Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default Filter;