import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../../utils/token";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../../utils/tooltip";
import usePermission from "../../../utils/permissions";
import copyIcon from "../../../assets/copy.svg";
import UiTable from "../../UiTable";
import { toast, ToastContainer } from "react-toastify";
import exportImg from "../../../assets/export.svg";
import plus from "../../../assets/Plus.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import deleteIcon from "../../../assets/delete.png";
import { useDispatch } from "react-redux";
import FilterAndSearch from "../../Filter/FilterAndSearch";
import dayjs from "dayjs";
import { formatDateNew } from "../../../utils/formateDate";
import preview from "../../../assets/eye.svg";

const TData = () => {
  return [
    { key: "id", displayName: "Rule ID", flag: true },
    { key: "name", displayName: "Rule Name", flag: true },
    { key: "description", displayName: "Rule Description", flag: true },
    { key: "created_date", displayName: "Created Date", flag: true },
    { key: "status", displayName: "Status", flag: true },
    { key: "copyBtn", displayName: "Action", flag: true },
  ];
};

const dateFilterLayout = {
  select: "ALL_TIME",
  from_time: "",
  to_time: "",
};

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

export default function ViewRulePage() {
  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  const [dateFilter, setDateFilter] = useState(dateFilterLayout);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterDone, setFilterDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState([]);
  const [filterRemoveFlag, setFilterRemoveFlag] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    return Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
  });
  const filterCount = Array.from(searchParams.keys()).length;

  const [rules, setRules] = useState({
    data: [],
    paginationModel: {
      pageSize: 15,
      page: 0,
    },
    totalCount: 0,
    allOfferCount: 0,
    pendingOfferCount: 0,
    denyOfferCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [offerStatus, setOfferStatus] = useState(false);
  const [editData, setEditData] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const permission = usePermission("/view-rules");
  const IdRef = useRef("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(permission);

  const getAllRules = async () => {
    setLoading(true);
    const body = {
      page: rules?.paginationModel.page + 1,
      pageSize: rules?.paginationModel.pageSize,
      ...(search && { search }),
      ...selectedFilters,
    };

    if (dateFilter?.select) {
      body["day"] = dateFilter?.select;
    }

    if (dateFilter?.select === "CUSTOM") {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      body["dateStart"] = startDate;
      body["dateEnd"] = endDate;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${baseUrl}/api/rules`, body, {
        headers,
      });
      console.log(response.data);
      let formateData = response?.data?.results?.map((e) => {
        return {
          id: e?.rules?.[0]?.id,
          name: e.name,
          description: e.description,
          // rule: e.rules[0]?.cashback_type,
          created_date: e.rules[0]?.created_date,
          status: e.rules[0]?.status,
          copyBtn: copyIcon,
          // deleteBtn: deleteIcon,
        };
      });
      setRules((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.ruleSetCount ?? 0,
      }));
      setStartDate(response.data.day_start_date ?? "");
      setEndDate(response.data.day_end_date ?? "");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const exportToCSV = () => {
    if (rules.data.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Generate headers dynamically
    const headers = Object.keys(rules.data[0]).filter(
      (key) => key !== "action"
    );

    // Convert header keys to display-friendly format
    const displayHeaders = headers.map((header) =>
      header
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );

    const csvData = rules.data.map((offer) =>
      headers.map((header) => {
        if (header === "visibility") {
          return offer[header] ? "Visible" : "Hidden";
        }
        return offer[header];
      })
    );

    const csvContent = [
      displayHeaders.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "rule_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handlePaginationModel = (value) => {
    if (!value) return;
    setRules((prev) => ({ ...prev, paginationModel: value }));
  };

  const handleCopy = async (e, id) => {
    console.log(id, "id");
    e.stopPropagation();
    navigate(`/create-rules`, {
      state: {
        id: id,
      },
    });
  };
  const handlePreview = async (e, id) => {
    console.log(id, "id");
    e.stopPropagation();
    navigate(`/create-rules`, {
      state: {
        id: id,
        preview:true
      },
    });
  };

  const fetchFilters = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    // const token = getToken();

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=viewRules`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        let obj = {};
        const data = config.map((res) => {
          if (!obj.hasOwnProperty(res?.name)) {
            obj[res?.name] = [];
          }
          return { ...res, state: [] };
        });
        setFilters(response?.data);
        console.log(config, data, "filter");
        // setFilterOptions(obj);
        // setFilterConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateFilter = (name, value) => {
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === "ALL_TIME") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }

    if (value === "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
  };

  const handleToggleColumn = (i) => {
    console.log(i, "index");
    const data = fieldsName.map((e, index) => {
      if (index === i) {
        e.flag = !e.flag;
      }
      return e;
    });
    console.log(data, "inside handleToggleColumn");
    setFieldName(data);
  };

  const columns = [
    {
      field: "id",
      headerName: "Rule ID",
      minWidth: 100,
      // flex:0.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }
        return (
          <Box
            paddingRight="auto"
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Rule Name",
      // flex:1.5,
      minWidth: 250,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              color="#043A87"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "description",
      headerName: "Rule Description",
      minWidth: 350,
      // flex:2.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                overflow: "hidden",
              }}
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "created_date",
      headerName: "Created Date",
      minWidth: 150,
      // flex:1,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      // flex:1,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "copyBtn",
      headerName: "Action",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        //   if (!params?.value) {
        //     return "NA";
        //   }

        return (
          <Box sx={{display:"flex",gap:"10px"}}> 
          <BootstrapTooltip
            title={
              permission.CREATE ? "Copy Rule" : "You don't have permission"
            }
          >
            <Box marginLeft="5px" onClick={(e) => handleCopy(e, params?.id)}>
              <img
                style={{ height: "18px", width: "18px" }}
                src={copyIcon}
                alt="copy"
              />
            </Box>
          </BootstrapTooltip>

          <BootstrapTooltip title={ "Preview Rule"}>
            <Box marginLeft="5px" onClick={(e) => handlePreview(e, params?.id)}>
              <img
                style={{ height: "18px", width: "18px" }}
                src={preview}
                alt="copy"
              />
            </Box>
          </BootstrapTooltip>

          </Box>
        );
      },
    },

    // {
    //   field: "deleteBtn",
    //   headerName: "",
    //   // minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) => {
    //     //   if (!params?.value) {
    //     //     return "NA";
    //     //   }

    //     return (
    //       <Box
    //         sx={{
    //           width: "100%",
    //           height: "100%",
    //           display: "grid",
    //           placeItems: "center",
    //         }}
    //         onClick={(e) => handleDelete(e, params)}
    //       >
    //         {/* {params?.value} */}
    //         <img
    //           style={{ height: "24px", width: "24px" }}
    //           src={params?.value}
    //           alt="delete"
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  console.log(rules, "rules");

  useEffect(() => {
    let debounceTimer;
    // if (filters?.length > 0) {
    if (flag) {
      setFlag(false);
    } else {
      debounceTimer = setTimeout(() => {
        getAllRules();
      }, 800);
    }
    // }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search]);

  useEffect(() => {
    // let debounceTimer;
    if (dateFilter.select !== "CUSTOM") {
      // if (flag) {
      //   setFlag(false);
      // } else {
      // debounceTimer = setTimeout(() => {
      getAllRules();
      // }, 0);
      // }
    }

    // return () => {
    //   clearTimeout(debounceTimer);
    // };
  }, [
    offerStatus,
    rules?.paginationModel?.page,
    rules?.paginationModel?.pageSize,
    dateFilter?.select,
    // activeTab,
    filterDone,
    filterRemoveFlag,
    // filters,
    // search
  ]);

  useEffect(() => {
    if (dateFilter.select === "CUSTOM") {
      if (
        typeof dateFilter?.from_time === "string" &&
        typeof dateFilter?.to_time === "string" &&
        dateFilter.from_time &&
        dateFilter.to_time
      ) {
        console.log(
          "inside custom date filter",
          dateFilter?.to_time,
          dateFilter?.from_time
        );
        getAllRules();
      }
    }
  }, [
    dateFilter,
    filterDone,
    filterRemoveFlag,
    rules?.paginationModel?.page,
    rules?.paginationModel?.pageSize,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateFilter((prev) => ({
        ...prev,
        from_time: dayjs(new Date(startDate)),
        to_time: dayjs(new Date(endDate)),
      }));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let filteredTData = fieldsName?.filter((column) => column.flag === true);

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    // Step 3: Add the action object at the end
    // const actionColumn = columns.find((column) => column.field === "action");
    // if (actionColumn) {
    //   filteredColumns.push(actionColumn);
    // } else {
    //   filteredColumns.push({ field: "action", headerName: "Action" });
    // }

    setColumnFields(filteredColumns);
  }, [fieldsName]);

  useEffect(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    const filterData = Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
    setSelectedFilters(filterData);
  }, [searchParams, filterRemoveFlag]);

  useEffect(() => {
    fetchFilters();
    // to remove the search params from the url on page load
    setSearchParams({});
  }, []);

  return (
    <Box sx={{ padding: "20px" }}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "16px" }}>
        {/* <SearchBar
          value={search}
          setState={setSearch}
          placeholder={"Search here..."}
        /> */}
        <Box>
          <Button
            sx={{
              height: "30px",
              width: "106px",
              backgroundColor: "#E8F0F9",
            }}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={exportImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14.4px",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
              onClick={exportToCSV}
            >
              Export Data
            </Typography>
          </Button>

          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              borderRadius: "6px",
              marginLeft: "12px",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            onClick={() => navigate("/create-rules")}
          >
            <img style={{ height: "16px", width: "16px" }} src={plus} alt="" />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "12px",
                lineHeight: "14.4px",
                fontWeight: 500,
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
            >
              Create New Rule
            </Typography>
          </Button>
        </Box>
      </Box>
      <FilterAndSearch
        search={search}
        setSearch={setSearch}
        tableFields={fieldsName}
        handleToggleColumn={handleToggleColumn}
        columnDisableIndex={1}
        // filterCount={3}
        filterCount={filterCount}
        dateFilter={dateFilter}
        filterSelectOptions={filterSelectOptions}
        handleDateFilter={handleDateFilter}
        filterData={filters}
        setFilterDone={setFilterDone}
        setFilterRemoveFlag={setFilterRemoveFlag}
      />
      {/* Table */}
      <Box>
        <UiTable
          row={rules?.data || []}
          columns={columnFields || []}
          totalCount={rules?.totalCount}
          paginationModel={rules?.paginationModel}
          setPaginationModel={handlePaginationModel}
          //   handleRowClick={handleRowClick}
          loading={loading}
        />
        {/* <CustomConfirmationDialog
          open={editData}
          setOpen={setEditData}
          deleteById={handleStatusChangeByID}
          prop={IdRef.current}
          status="delete"
          message={message}
        /> */}
      </Box>
    </Box>
  );
}
