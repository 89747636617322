import React from "react";
import food_delivery_offer from "../../../assets/food_delivery_offer.jpg";
import food_delivery_offer_1st_order from "../../../assets/food_delivery_1st_order_offer.jpg";
import styles from "./deals.module.scss";
import { DeviceFrameset } from "react-device-frameset";

const ViewAllDeals = ({ data = {} }) => {
  const deals = [
    {
      ...data,
    },
    {
      img: food_delivery_offer_1st_order,
      label: "food_delivery_offer_1st_order",
    },
    {
      img: food_delivery_offer,
      label: "food Delivery Offer",
    },
  ];
  return data ? (
    <div
      // device="iPhone X"
      // color="black"
      // zoom="55%"
      // style={{
      //   overflowY: "scroll",
      // }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.cardGrid}>
            {deals?.map((deal, index) => {
              const { img } = deal;
              const image = img;
              return (
                <div className={styles.dealCard} key={index}>
                  {image && (
                    <img
                      className={styles.dealLogo}
                      // src={offerBanner}
                      src={image}
                      alt="logo"
                      width={324}
                      height={243}
                    />
                  )}
                  {/* <div className={styles.dealCardDescription}>
            <div className={styles.dealTitle}>{offerTitle}</div>
            <div className={styles.dealFooterText}>
              {offerDescription}
            </div>
          </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      device="iPhone X"
      color="black"
      zoom="55%"
      style={{
        overflowY: "scroll",
      }}
    ></div>
  );
};

export default ViewAllDeals;
