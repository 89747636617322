import axios from "axios"
import { DummyUser, getLimitOptions, userDetails } from "./actionType"
import { getToken } from "../../utils/token";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;


export const handleGetSelectOption = ()=> (dispatch)=>{
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.get(`${BaseUrl}/api/limits/get-allowed-limits`,config).then((res)=>{
        dispatch({type:getLimitOptions,payload:res.data})
      console.log(res.data)
    }).catch((error)=>{
      console.log(error)
    })
  }
