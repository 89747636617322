import { Box, Button, Chip, Drawer, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchBar from "../../UIComponents/SearchBar";
import CommonDateFilterPanel from "./CommonDateFilterPanel";
import filterImg from "../../assets/filterImg.svg";
import ToggleColumn from "../../UIComponents/ToggleColumn";
import Filter from "./Filter";
import { useSearchParams } from "react-router-dom";
import filterNotApplied from "../../assets/filterNotApplied.svg";

export default function FilterAndSearch({
  search,
  setSearch,
  hideSearch,
  dateFilter,
  filterSelectOptions,
  handleDateFilter,
  disableDateFilter,
  hideDateFilter,
  filterCount,
  tableFields,
  handleToggleColumn,
  columnDisableIndex,
  filterData,
  setFilterDone,
  setFilterRemoveFlag,
  hideSearchFromFilter,
  onToggleColumnReset,
}) {
  const [open, setOpen] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [done, setDone] = React.useState(true);
  const [searchTerms, setSearchTerms] = useState({});
  const [previousParams, setPreviousParams] = useState(searchParams);
  const isFilter = filterCount > 0;

  // Initialize selected filters state
  const [selectedFilters, setSelectedFilters] = useState({});

  // Effect to initialize and update filters from URL params
  useEffect(() => {
    const initialFilters = {};
    filterData.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });
    setSelectedFilters(initialFilters);
  }, [searchParams, filterData]);

  // Compute hasAnyFilters based on selectedFilters
  const hasAnyFilters = Object.values(selectedFilters).some(
    (filters) => filters && filters.length > 0
  );

  const handleChipDelete = (filterName, valueToDelete) => {
    // Create new filters object with the value removed
    const updatedFilters = {
      ...selectedFilters,
      [filterName]: selectedFilters[filterName].filter(
        (value) => value.toString() !== valueToDelete.toString()
      ),
    };
    setSelectedFilters(updatedFilters);

    // Update URL params
    const newSearchParams = new URLSearchParams(searchParams);
    const remainingValues = updatedFilters[filterName];

    if (remainingValues.length === 0) {
      newSearchParams.delete(filterName);
    } else {
      newSearchParams.set(filterName, remainingValues.join(","));
    }

    setSearchParams(newSearchParams);
    setTimeout(() => {
      setFilterRemoveFlag((prev) => !prev);
    }, 100);
    // setFilterDone(prev => !prev); // Trigger parent component update
    setFilterRemoveFlag((prev) => !prev);
  };

  const toggleDrawer = (newOpen) => () => {
    if (newOpen) {
      setPreviousParams(searchParams);
      const initialFilters = {};
      filterData.forEach((filter) => {
        const paramValue = searchParams.get(filter.name);
        if (paramValue) {
          initialFilters[filter.name] = paramValue.split(",");
        } else {
          initialFilters[filter.name] = [];
        }
      });
      setSelectedFilters(initialFilters);
      setSearchTerms({});
      setDone(false);
    } else {
      if (!done) {
        const revertFilters = {};
        filterData.forEach((filter) => {
          const paramValue = previousParams.get(filter.name);
          if (paramValue) {
            revertFilters[filter.name] = paramValue.split(",");
          } else {
            revertFilters[filter.name] = [];
          }
        });
        setSelectedFilters(revertFilters);
      } else {
        setPreviousParams(searchParams);
      }
      setDone(true);
    }
    setOpen(newOpen);
  };

  const handleDone = () => {
    setFilterDone((prev) => !prev);
    setOpen(false);
  };

  const renderSelectedFilters = () => {
    if (!hasAnyFilters) return null;

    return (
      <Paper
        variant="outlined"
        sx={{
          p: "2px",
          mb: 2,
          backgroundColor: "background.default",
          fontFamily: "Lato",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "4px",
            flexWrap: "wrap",
            px: "7px",
            py: "2px",
          }}
        >
          {filterData.map((filter) => {
            const selectedValues = selectedFilters[filter.name] || [];
            if (selectedValues.length === 0) return null;

            return (
              <Box
                key={filter.name}
                sx={{
                  backgroundColor: "#FCFCFC",
                  px: "7px",
                  border: "0.5px solid #EDF0F6",
                  borderRadius: "6px",
                  height: "22px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      color: "#1D1F2C",
                    }}
                  >
                    {filter.summaryText}:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      alignItems: "center",
                    }}
                  >
                    {selectedValues.map((value) => {
                      const option = filter.options.find(
                        (opt) => opt.value.toString() === value.toString()
                      );
                      return (
                        <Chip
                          key={value}
                          label={option?.label || value}
                          size="small"
                          onDelete={() => handleChipDelete(filter.name, value)}
                          sx={{
                            maxWidth: "200px",
                            fontFamily: "Lato",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16.8px",
                            color: "#6F747E",
                            height: "16.8px",
                            "& .MuiChip-label": {
                              lineHeight: "16.8px",
                            },
                            "& .MuiChip-deleteIcon": {
                              margin: 0,
                            },
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Paper>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: hideSearch ? "flex-end" : "space-between",
        }}
      >
        <Box sx={{ display: hideSearch ? "none" : "block" }}>
          <SearchBar
            value={search}
            setState={setSearch}
            placeholder={"Search here..."}
          />
        </Box>

        <Box sx={{ display: "flex", gap: "12px" }}>
          <Box sx={{ display: hideDateFilter ? "none" : "block" }}>
            <CommonDateFilterPanel
              dateFilter={dateFilter}
              filterSelectOptions={filterSelectOptions}
              handleDateFilter={handleDateFilter}
              disabled={disableDateFilter}
            />
          </Box>

          <Box>
            <Button
              sx={{
                height: "30px",
                width: "94px",
                borderRadius: "6px",
                backgroundColor: isFilter ? "#E8F0F9" : "white",
                border: isFilter
                  ? "1px solid #043A87"
                  : "1px solid rgba(0, 0, 0, 0.30)",
                color: isFilter ? "#043A87" : "#667085",
                fontFamily: "Lato",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
                textTransform: "capitalize",
              }}
              onClick={toggleDrawer(true)}
            >
              <img
                style={{ height: "14px", width: "14px", marginRight: "4px" }}
                src={isFilter ? filterImg : filterNotApplied}
                alt=""
              />
              Filters {filterCount > 0 ? `(${filterCount})` : ""}
            </Button>
          </Box>

          <Box>
            <ToggleColumn
              tableFields={tableFields}
              handleToggleColumn={handleToggleColumn}
              disabledIndex={columnDisableIndex}
              onReset={onToggleColumnReset}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Drawer
          open={open}
          anchor="right"
          onClose={toggleDrawer(false)}
          sx={{
            width: "352px",
            maxWidth: "none",
            overflowX: "hidden",
            "& .MuiDrawer-paper": {
              width: "352px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "14px",
              color: "#1D1F2C",
              margin: "24px 16px 12px 16px",
              textTransform: "capitalize",
            }}
          >
            Filters
          </Typography>
          <Box sx={{ padding: "16px", backgroundColor: "white" }}>
            <Filter
              filters={filterData}
              handleDone={handleDone}
              setFilterDone={setFilterDone}
              done={done}
              setDone={setDone}
              searchTerms={searchTerms}
              setSearchTerms={setSearchTerms}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setOpen={setOpen}
              hideSearchFromFilter={hideSearchFromFilter}
            />
          </Box>
        </Drawer>
        {renderSelectedFilters()}
      </Box>
    </Box>
  );
}
