import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import MuiTextarea from "../../UIComponents/MuiTextarea";
import MuiInput from "../../UIComponents/MuiInput";
import arrow from "../../assets/arrow-left.svg";
import BoldLabel from "../../UIComponents/BoldLevel";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCreateRuleData,
  setCreateRuleChanges,
} from "../../Redux/CreateRuleReducer/actionType";
import MuiSelectAndSearch from "../../UIComponents/MuiSelectAndSearch";
import deleteLogo from "../../assets/redDeleteBtn.png";
import addLogo from "../../assets/add.svg";
import { FaPlus } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import MuiSelect from "../../UIComponents/MuiSelect";
import { getWhereSectionSelectOne } from "../../Redux/CreateRuleReducer/action";
import { getToken } from "../../utils/token";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import MuiSelectWithSearch from "../../UIComponents/MuiSelectWithSearch";

const options = [
  { label: "AND", value: "AND" },
  { label: "OR", value: "OR" },
];

export default function CreateRulePage() {
  const data = useSelector((store) => store.CreateRuleReducer.createRuleData);
  const roleOptionData = useSelector(
    (store) => store.CreateRuleReducer.select1
  );
  const roleOption = roleOptionData.map((item) => ({
    id: item?.id,
    name: item?.attributeName,
    attributeValueType: item?.attributeValueType,
    entityName: item?.entityName,
    operator: item?.operator?.map((e) => ({
      id: e,
      name: e,
    })),
  }));
  // console.log(roleOption,"roleOption")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { pathname } = useLocation();
  const token = getToken();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleData = (key, value) => {
    dispatch({
      type: setCreateRuleChanges,
      payload: { [key]: value },
    });
  };

  const handleWhereSectionChange = (index, groupIndex, key, value) => {
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data[groupIndex][key] = value;
    handleData("whereSection", updatedWhereSection);
  };

  const handleAddCriteria = () => {
    const newCriteria = {
      data: [
        {
          role: "",
          operator: "",
          value: "",
          divider: "AND",
        },
      ],
      type: "AND",
    };
    handleData("whereSection", [...data.whereSection, newCriteria]);
  };

  const handleDeleteCriteria = (index) => {
    if (data.whereSection.length > 1) {
      const updatedWhereSection = [...data.whereSection];
      updatedWhereSection.splice(index, 1);
      handleData("whereSection", updatedWhereSection);
    }
  };

  const handleAddGroup = (index) => {
    const newGroup = {
      role: "",
      operator: "",
      value: "",
      divider: "AND",
    };
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data = [
      ...updatedWhereSection[index].data,
      newGroup,
    ];
    handleData("whereSection", updatedWhereSection);
  };

  const handleDeleteGroup = (index, groupIndex) => {
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data = updatedWhereSection[index].data.filter(
      (_, i) => i !== groupIndex
    );
    handleData("whereSection", updatedWhereSection);
  };

  const handleSave = () => {
    // Validation checks
    if (!data?.name) {
      toast.error("Rule Name is required");
      return;
    }
    if (!data?.description) {
      toast.error("Rule Description is required");
      return;
    }

    // Iterate through the whereSection to check for missing role, operator, and value
    for (const [index, section] of data?.whereSection.entries()) {
      for (const [groupIndex, group] of section.data.entries()) {
        if (!group?.role?.id) {
          // validation id group added just add "Group ${groupIndex + 1}" in error message
          // toast.error(`Role is required in Criteria ${index + 1}, Group ${groupIndex + 1}`);
          toast.error(`Role is required in Criteria ${index + 1}`);
          return;
        }
        if (!group?.operator?.id) {
          // toast.error(`Operator is required in Criteria ${index + 1}, Group ${groupIndex + 1}`);
          toast.error(`Operator is required in Criteria ${index + 1}`);
          return;
        }
        if (!group?.value) {
          // toast.error(`Value is required in Criteria ${index + 1}, Group ${groupIndex + 1}`);
          toast.error(`Value is required in Criteria ${index + 1}`);
          return;
        }
      }
    }

    // Proceed to save if validation passes
    const ruleConfiguration = data?.whereSection?.map((item, index) => ({
      sequenceId: index + 1,
      isGroup: false,
      attributeName: item?.data?.[0]?.role?.name,
      entityName: item?.data?.[0]?.role?.entityName,
      attributeId: item?.data?.[0]?.role?.id,
      type: item?.data?.[0]?.operator?.id,
      valueType: item?.data?.[0]?.role?.attributeValueType,
      value: item?.data?.[0]?.value,
      condition:index!==0? item?.type ?? null:null,
    }));

    const postData = {
      name: data?.name,
      description: data?.description,
      type: "EXPRESSION",
      ruleConfiguration: ruleConfiguration,
    };

    console.log(postData,"postData");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(`${baseUrl}/api/create-rule`, postData, config)
      .then((res) => {
        console.log(res.data, "Submitted");
        toast.success("Rule created successfully");
        setTimeout(() => {
          if (state?.id === "offer") {
            navigate(-1);
          } else {
            navigate("/view-rules");
          }
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e?.response?.data?.message || "Something went wrong");
      });
  };

  const getRuleDetailsById = async (id) => {
    console.log(id, "id");
    axios
      .get(`${baseUrl}/api/get-rule-by-id/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const name = res?.data?.name;
        const description = res?.data?.description;
        const whereSection = res?.data?.ruleConfiguration?.map((rule) => ({
          data: [
            {
              role: roleOption.find((option) => option.id === rule.attributeId),
              operator: roleOption
                .find((option) => option.id === rule.attributeId)
                ?.operator?.find((item) => item?.id === rule.type),
              value: rule.value,
              divider: rule.condition,
            },
          ],
          type: rule.condition || "AND",
        }));

        //   {
        //     "role": {
        //         "name": "transactionAmount",
        //         "id": 587,
        //         "attributeValueType": "NUMBER",
        //         "entityName": "LOYALTY"
        //     },
        //     "operator": {
        //         "id": "GREATER_THAN"
        //     },
        //     "value": "1000",
        //     "divider": null
        // }
        console.log(whereSection, "whereSection");
        dispatch({
          type: setCreateRuleChanges,
          payload: {
            name,
            description,
            whereSection,
          },
        });
        // toast.success("Rule copied to clipboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to copy rule");
      });
  };

  console.log(data, "CreateRulePage");
  useEffect(() => {
    dispatch(getWhereSectionSelectOne("", "", "loyalty"));
    dispatch({ type: resetCreateRuleData });
    if (state?.id && state?.id !== "offer") {
      getRuleDetailsById(state?.id);
    }
  }, []);
  return (
    <Box
      sx={{
        margin: "20px",
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        minHeight: "calc(100vh - 130px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <ToastContainer />
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            sx={{
              minWidth: "0px",
              display: pathname === "/create-rules" ? "none" : "default",
            }}
            // onClick={() =>
            //   navigate("/offers/create-offers/set-qualifying-criteria")
            // }
            onClick={() => navigate(-1)}
          >
            <img style={{ height: "16px", width: "16px" }} src={arrow} alt="" />
          </Button>
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#0B1B32",
            }}
          >
            Create New Rule
          </Typography>
        </Box>

        <Box>
          <Box sx={{ width: "500px" }}>
            <BoldLabel label="Rule Name" />
            <MuiInput
              value={data?.name}
              onChange={(e) => handleData("name", e)}
              placeholder="50 characters"
              maxLength={50}
            />
          </Box>
          <Box sx={{ width: "500px" }}>
            <BoldLabel label="Rule Description" />
            <MuiTextarea
              value={data.description}
              onChange={(e) => handleData("description", e)}
              placeholder="200 characters"
              maxLength={200}
            />
          </Box>
        </Box>

        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#0B1B32",
            marginTop: "25px",
            marginBottom: "20px",
            textAlign: "left",
          }}
        >
          Where
        </Typography>

        <Box>
          {data?.whereSection?.map((item, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: index === 0 ? "none" : "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "26px",
                  borderRadius: "8px",
                  padding: "14px",
                  width: "calc(100%-48px)",
                  mt: "14px",
                  mb: "19px",
                  height: "32px",
                }}
              >
                <Box sx={{ border: "1px solid #0B1B32", width: "100%" }}></Box>
                <Box sx={{ width: "180px" }}>
                  <MuiSelect
                    backgroundColor="black"
                    selectedBackgroundColor="black"
                    textColor="white"
                    selectedTextColor="white"
                    hoverBackgroundColor="gray"
                    options={options}
                    value={item.type}
                    onChange={(e) =>
                      handleData("whereSection", [
                        ...data.whereSection.slice(0, index),
                        { ...item, type: e },
                        ...data.whereSection.slice(index + 1),
                      ])
                    }
                  />
                </Box>
                <Box sx={{ border: "1px solid #0d1520", width: "100%" }}></Box>
              </Box>

              <Box
                mb="20px"
                sx={{
                  width: "calc(100%-28px)",
                  backgroundColor: "#F4F8FC",
                  borderRadius: "8px",
                  padding: "14px",
                }}
              >
                {item?.data?.length > 0 &&
                  item?.data?.map((group, groupIndex) => (
                    <Box key={groupIndex}>
                      <Box
                        sx={{
                          display: groupIndex === 0 ? "none" : "flex",
                          alignItems: "center",
                          width: "calc(100%-68px)",
                          gap: "26px",
                          mt: "14px",
                          mb: "19px",
                          height: "32px",
                        }}
                      >
                        <Box
                          sx={{ border: "1px solid #9CBFEB", width: "100%" }}
                        ></Box>
                        <Box sx={{ width: "180px" }}>
                          <MuiSelect
                            options={options}
                            value={group.divider}
                            textColor="#003D86"
                            backgroundColor="#E8F0F9"
                            onChange={(e) =>
                              handleWhereSectionChange(
                                index,
                                groupIndex,
                                "divider",
                                e
                              )
                            }
                          />
                        </Box>
                        <Box
                          sx={{ border: "1px solid #9CBFEB", width: "100%" }}
                        ></Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "end",
                          width: "calc(100%-56px)",
                          backgroundColor:
                            item.data.length > 1 ? "#D5E8F6" : "",
                          padding: item.data.length > 1 ? "14px" : "",
                          borderRadius: "8px",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <BoldLabel label="Role" mt="0px" />

                          {/* with add attribute */}
                          {/* <MuiSelectWithSearch
                          options={roleOption}
                          value={group.role}
                          onChange={(e) =>
                            handleWhereSectionChange(
                              index,
                              groupIndex,
                              "role",
                              e
                            )
                          }
                        /> */}

                          <MuiSelectAndSearch
                            options={roleOption}
                            value={group.role}
                            placeholder={"Search / Select"}
                            onChange={(e) =>
                              handleWhereSectionChange(
                                index,
                                groupIndex,
                                "role",
                                e
                              )
                            }
                          />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                          <BoldLabel label="Operator" mt="0px" />
                          <MuiSelectAndSearch
                            options={group?.role?.operator ?? []}
                            value={group.operator}
                            placeholder={"Search / Select"}
                            onChange={(e) =>
                              handleWhereSectionChange(
                                index,
                                groupIndex,
                                "operator",
                                e
                              )
                            }
                          />
                        </Box>
                        <Box sx={{ width: "100%", mb: "4px" }}>
                          <BoldLabel label="Add Value" mt="0px" />
                          <MuiInput
                            type={
                              group.role?.attributeValueType === "NUMBER"
                                ? "number"
                                : "text"
                            }
                            value={group.value}
                            onChange={(e) =>
                              handleWhereSectionChange(
                                index,
                                groupIndex,
                                "value",
                                e
                              )
                            }
                            placeholder=""
                          />
                        </Box>
                        <Button
                          sx={{
                            minWidth: "40px",
                            height: "40px",
                            borderRadius: "6px",
                            backgroundColor: "#FC55551A",
                            mb: "4px",
                            // display: item.data.length > 1 ? "block" : "none",
                            display:
                              data?.whereSection?.length > 1 ? "block" : "none",
                          }}
                          onClick={() => handleDeleteCriteria(index)}
                        >
                          <img
                            style={{ height: "25px", width: "25px" }}
                            src={deleteLogo}
                            alt=""
                          />
                        </Button>

                        {/* add group button commented for now */}
                        {/* <Box sx={{ ml: "5%", mb: "4px" }}>
                        <Button 
                        // onClick={() => handleAddGroup(index)}
                          >
                          <img src={addLogo} alt="" />
                          &nbsp;
                          <Typography
                            sx={{
                              fontFamily: "Lato",
                              fontWeight: 700,
                              fontSize: "12px",
                              lineHeight: "14.4px",
                            }}
                          >
                            Add
                          </Typography>
                          &nbsp;
                          <Typography
                            sx={{
                              fontFamily: "Lato",
                              fontWeight: 700,
                              fontSize: "12px",
                              lineHeight: "14.4px",
                            }}
                          >
                            Group
                          </Typography>
                        </Button>
                      </Box> */}
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "14px" }}>
          <Button
            sx={{
              width: "151px",
              height: "28px",
              backgroundColor: "#003D86",
              borderRadius: "6px",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            onClick={handleAddCriteria}
          >
            <FaPlus />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "12px",
                lineHeight: "14.4px",
                fontWeight: 500,
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
            >
              Create New Criteria
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "16px",
          mt: "20px",
        }}
      >
        <Button
          sx={{
            color: "#003D86",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.8px",
            backgroundColor: "#E8F0F9",
            width: "136px",
            height: "30px",
            borderRadius: "6px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#E8F0F9",
            },
            display: state?.id === "offer" ? "block" : "none",
          }}
          // onClick={() => navigate("/offers/create-offers/set-qualifying-criteria")}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            display:state?.preview?"none":"block",
            color: "#FFFFFF",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.8px",
            backgroundColor: "#003D86",
            width: "136px",
            height: "30px",
            borderRadius: "6px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#003D86",
            },
          }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
