import { Button, FormControl, InputLabel, FormHelperText, Chip, Box, Checkbox } from "@mui/material";
import { useState, useRef } from "react";
import Select, { components } from "react-select";
import CloseIcon from '@mui/icons-material/Close';
import { FiSearch } from 'react-icons/fi';

const Control = ({ children, ...props }) => {
  const { emoji, onEmojiClick, isFocused } = props.selectProps;

  return (
    <components.Control {...props}>
      {children}
      {isFocused && (
        <Button
          onMouseDown={(e) => {
            e.stopPropagation();
            onEmojiClick(e);
          }}
          sx={{
            background: "#003D86",
            color: "#fff",
            height: "30px",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "17px",
            textTransform:"capitalize",
            "&:hover": {
              background: "#003D86",
            },
          }}
        >
          {emoji}
        </Button>
      )}
    </components.Control>
  );
};

const Option = (props) => (
  <div
    ref={props.innerRef}
    {...props.innerProps}
    style={{
      display: 'flex',
      alignItems: 'center',
      padding: '8px',
      borderBottom: '1px solid #E3E7EF',
      textAlign: 'left',  
    }}
  >
    {props.data.name}
  </div>
);

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const selectedCount = getValue().length;

  return (
    <components.ValueContainer {...props}>
      {props.selectProps.hideSelectedOptions && hasValue ? (
        <div>{`${selectedCount} selected`}</div>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

const CustomSelectProps = ({
  options = [],
  handleClick,
  onChange,
  value = [],
  errorMessage,
  showChips = false,
  hideRemoveIcon = false,
  placeholder = '--Select--',
  label = '',
  isDisabled = false,
  editBoolean = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef();

  const styles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '32px',
      height: '32px',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontSize: '12px',
      padding: '0px 0px 0px 10px',
      backgroundColor: 'transparent',
      borderColor: editBoolean
        ? 'orange !important'
        : errorMessage
        ? '#d40e10 !important'
        : provided.borderColor,
      '&:hover': {
        borderColor: editBoolean
          ? 'orange !important'
          : errorMessage
          ? '#d40e10 !important'
          : provided.borderColor,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0',
      height: '30px',
      textAlign: 'left',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '30px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 4px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 4px',
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: '0',
      marginRight: '0',
      textAlign: 'left',
      color: '#0F1828',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '8px',
      backgroundColor: state.isSelected ? '#edf4fb' : 'white',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: '400',
      borderBottom: '1px solid #E3E7EF',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: '400',
      color: '#0F1828',
    }),
  };

  const emoji = "Add";

  const handleChipDelete = (optionToRemove) => {
    const newValue = value.filter(option => option.id !== optionToRemove.id);
    onChange(newValue);
  };

  return (
    <FormControl
      sx={{ mt: '10px', width: '100%' }}
      variant="outlined"
      size="small"
      error={!!errorMessage}
    >
      <InputLabel shrink>{label}</InputLabel>
      <div ref={selectRef}>
        <Select
          value={value}
          isFocused={isFocused}
          options={options}
          isDisabled={isDisabled}
          emoji={emoji}
          menuPlacement="auto"
          placeholder={
            <Box sx={{ display: "flex", alignItems: "center", color: "#9499a0ed" }}>
              <FiSearch style={{ color: "#858D9D", marginRight: '5px', height: "14px", width: "14px" }} />
              {placeholder}
            </Box>
          }
          onEmojiClick={(e) => {
            e.preventDefault();
            handleClick(e);
          }}
          onChange={onChange}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          components={{ Control, Option, ValueContainer }}
          hideSelectedOptions={false}
          styles={styles}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          closeMenuOnSelect={false}
        />
      </div>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      {showChips && (
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
          {value.map((option) => (
            <Chip
              key={option.id}
              label={option.name}
              onDelete={() => handleChipDelete(option)}
              style={{
                margin: '2px 5px',
                backgroundColor: '#F9F9FC',
                borderRadius: '20px',
                padding: '2px 5px',
                color: '#003D86',
                fontFamily: 'Lato',
                fontSize: '10px',
                fontWeight: '400',
              }}
              deleteIcon={
                !hideRemoveIcon && (
                  <CloseIcon sx={{ height: "16px", width: "16px", fill: "#003D86", display: isDisabled ? "none" : "block" }} />
                )
              }
            />
          ))}
        </div>
      )}
    </FormControl>
  );
};

export default CustomSelectProps;