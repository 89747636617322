import axios from "axios";
import {
  allDataSubmitted,
  editOfferFail,
  editOfferSuccess,
  errorSubmitOffer,
  getCategory,
  getCohortsData,
  getCurrency,
  getGames,
  getGameType,
  getInitiative,
  getMerchant,
  getPreviouslyCreateRewards,
  getProduct,
  getRewardTypes,
  getRuleSet,
  getSubCategory,
} from "./actionType";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

const getToken = () => {
  const data = localStorage.getItem("Auth");
  const AuthDetails = data ? JSON.parse(data) : {};
  return AuthDetails?.accessToken || "";
};

export const getCategoryFunction = (token) => (dispatch) => {
  let params = { table: "category" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getCategory, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSubCategoryFunction = (ID) => (dispatch) => {
  const token = getToken();
  console.log(token, "token");
  const categoryIdsString = JSON.stringify(ID);
  let params = { table: "subcategory", category_id: categoryIdsString };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getSubCategory, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const getProductFunction = (ID) => (dispatch) => {
//   const categoryIdsString = JSON.stringify(ID);
//   const token = getToken();
//   let params = { table: "product", subcategory_id: categoryIdsString };
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   axios
//     .get(`${BaseUrl}/api/get`, { params, ...config })
//     .then((res) => {
//       dispatch({ type: getProduct, payload: res.data });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

// export const getProductFunction = () => (dispatch) => {
 
//   const token = getToken();
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   axios
//     .get(`${BaseUrl}/api/product-list`, config)
//     .then((res) => {
//       dispatch({ type: getProduct, payload: res.data });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const getProductFunction = () => (dispatch) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .post(`${BaseUrl}/api/products`, {}, config) // Passing an empty object for the body
    .then((res) => {
      dispatch({ type: getProduct, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};


export const getMerchantFunction = () => (dispatch) => {
  const token = getToken();
  let params = { table: "merchant" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getMerchant, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getRuleSetFunction = () => (dispatch) => {
  let params = { table: "rule_set" };
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getRuleSet, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCohortsSetFunction = () => (dispatch) => {
  let params = { table: "collections" };
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getCohortsData, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const getCohortsSetFunction = () => (dispatch) => {
//   // let params = { table: "rule_set" };
//   const token = getToken();
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   axios
//     .get(`${BaseUrl}/api/collections`, { ...config })
//     .then((res) => {
//       dispatch({ type: getCohortsData, payload: res.data.data });
//       // console.log(res.data.data);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const getInitiativeFunction = () => (dispatch) => {
  const token = getToken();
  let params = { table: "initiative" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getInitiative, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getGamesFunction = (token) => (dispatch) => {
  let params = { table: "games" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getGames, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCurrencyFunction = (token) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let params = { table: "currency" };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getCurrency, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleSubmitAllData = (data, setLoading) => (dispatch) => {
  const token = getToken();
  setLoading(true);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .post(`${BaseUrl}/api/create-offer`, data, config)
    .then((res) => {
      dispatch({ type: allDataSubmitted, payload: res.data });
      console.log(res.data, "all");
      setLoading(false);
    })
    .catch((error) => {
      dispatch({
        type: errorSubmitOffer,
        payload: error?.response?.data?.message,
      });
      console.log(error);
      setLoading(false);
    });
};

export const handleEditData = (editedData, id) => (dispatch) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .post(`${BaseUrl}/api/admin/offer/${id}`, editedData, config)
    .then((res) => {
      dispatch({ type: editOfferSuccess, payload: res.data });
      console.log(res.data, "edited");
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: editOfferFail,
        payload: error?.response?.data?.message,
      });
    });

  // dispatch({ type: editOfferSuccess,});
};

export const getAllGameTypes =
  (pageNumber = 1, pageSize = 5) =>
  (dispatch) => {
    const token = getToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(
        `${BaseUrl}/api/game-types`,
        { pageNumber, pageSize },
        config
      )
      .then((res) => {
        console.log(res.data);
        dispatch({ type: getGameType, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  export const getRewardTypesFunction = (pageNumber = 1, pageSize = 10) => (dispatch) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      pageNumber,
      pageSize
    }
    
    axios
      .post(`${BaseUrl}/api/reward-types`,body, config)
      .then((res) => {
        const data = {
         data: [
              {
                  "id": 8,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a30c64_Cash back icon.png",
                  "name": "CASHBACK",
                  "code": "cashback",
                  "reward_details": [
                      {
                          "label": "Select Reward Type",
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              },
                            {
                              "name": "PROMO NEUCOINS",
                              "id": "promo_neu_coin",
                            }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "label": "Select Cashback Type",
                          "options": [
                              {
                                  "label": "Fixed Cashback",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Min Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "label": "Avg Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "label": "Std Deviation",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "% Cashback",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Percentage",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "label": "Reward Title",
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "title"
                      },
                      {
                          "label": "Choose A Reward Icon",
                          "options": [
                              "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "reward_icon"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": {},
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "created_at": "2024-07-31 07:34:34",
                  "last_modified_at": "2024-11-08 16:57:58"
              },
              {
                  "id": 9,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5adfcb_voucher_icon.png",
                  "name": "VOUCHER",
                  "code": "voucher",
                  "reward_details": [
                      {
                          "label": "Select Reward Type",
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              },
                            {
                              "name": "PROMO NEUCOINS",
                              "id": "promo_neu_coin"
                            }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "label": "Select Cashback Type",
                          "options": [
                              {
                                  "label": "Fixed Cashback",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Min Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "label": "Avg Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "label": "Std Deviation",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "% Cashback",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Percentage",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "label": "Reward Title",
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "title"
                      },
                      {
                          "label": "Choose A Reward Icon",
                          "options": [
                              "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "reward_icon"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": {},
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "created_at": "2024-07-31 07:35:03",
                  "last_modified_at": "2024-11-08 16:57:58"
              },
              {
                  "id": 10,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f1e32a_neucoin_icon.png",
                  "name": "Neucoins",
                  "code": "neu_coin",
                  "reward_details": [
                      {
                          "label": "Reward",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              },
                            {
                              "name": "PROMO NEUCOINS",
                              "id": "promo_neu_coin"
                            }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "type": "number",
                          "label": "Win Probability",
                          "style": {
                              "width": "31.3%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "win_probability"
                      },
                      {
                          "label": "Cashback Type",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "label": "Fixed",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Min. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Avg. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Std. Deviation",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "Percentage",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Percentage",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_value",
                                      "name": "Value"
                                  },
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": [
                      {
                          "label": "Title",
                          "style": {
                              "width": "48%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "placeholder": "e.g: NEUCOINS",
                          "backend_label": "title",
                          "max_characters": 20
                      },
                      {
                          "label": "Title Colour",
                          "style": {
                              "width": "48%"
                          },
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "title_colour"
                      },
                      {
                          "label": "Logo Image",
                          "options": [
                              {
                                  "id": 1579,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                              },
                              {
                                  "id": 1580,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                              },
                              {
                                  "id": 1581,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "logo_image"
                      },
                      {
                          "label": "Reward Value Colour",
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "reward_value_color"
                      },
                      {
                          "label": "Scratch Card Image",
                          "options": [
                              {
                                  "id": 1582,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                              },
                              {
                                  "id": 1583,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                              },
                              {
                                  "id": 1584,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                              },
                              {
                                  "id": 1585,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                              },
                              {
                                  "id": 1586,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                              },
                              {
                                  "id": 1587,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                              },
                              {
                                  "id": 1588,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "scratch_card_image"
                      },
                      {
                          "label": "After Play Details",
                          "fields": [
                              {
                                  "label": "Title",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : Congratulations!",
                                  "backend_label": "title",
                                  "max_characters": 50
                              },
                              {
                                  "label": "Description",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : You have won 100 Neucoins",
                                  "backend_label": "description",
                                  "max_characters": 100
                              }
                          ],
                          "mandatory": true,
                          "input_type": "json",
                          "backend_label": "after_play_details"
                      }
                  ],
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "created_at": "2024-07-31 07:36:28",
                  "last_modified_at": "2024-11-09 09:31:07"
              },
              {
                  "id": 11,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
                  "name": "Digital Gold",
                  "code": "digital_gold",
                  "reward_details": [
                      {
                          "label": "Reward",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              },
                            {
                              "name": "PROMO NEUCOINS",
                              "id": "promo_neu_coin"
                            }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "type": "number",
                          "label": "Win Probability",
                          "style": {
                              "width": "31.3%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "win_probability"
                      },
                      {
                          "label": "Cashback Type",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "label": "Fixed",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Min. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Avg. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Std. Deviation",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "Percentage",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Percentage",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_value",
                                      "name": "Value"
                                  },
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": [
                      {
                          "label": "Title",
                          "style": {
                              "width": "48%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "placeholder": "e.g: NEUCOINS",
                          "backend_label": "title",
                          "max_characters": 20
                      },
                      {
                          "label": "Title Colour",
                          "style": {
                              "width": "48%"
                          },
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "title_colour"
                      },
                      {
                          "label": "Logo Image",
                          "options": [
                              {
                                  "id": 1579,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                              },
                              {
                                  "id": 1580,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                              },
                              {
                                  "id": 1581,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "logo_image"
                      },
                      {
                          "label": "Reward Value Colour",
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "reward_value_color"
                      },
                      {
                          "label": "Scratch Card Image",
                          "options": [
                              {
                                  "id": 1582,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                              },
                              {
                                  "id": 1583,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                              },
                              {
                                  "id": 1584,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                              },
                              {
                                  "id": 1585,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                              },
                              {
                                  "id": 1586,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                              },
                              {
                                  "id": 1587,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                              },
                              {
                                  "id": 1588,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "scratch_card_image"
                      },
                      {
                          "label": "After Play Details",
                          "fields": [
                              {
                                  "label": "Title",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : Congratulations!",
                                  "backend_label": "title",
                                  "max_characters": 50
                              },
                              {
                                  "label": "Description",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : You have won 100 Neucoins",
                                  "backend_label": "description",
                                  "max_characters": 100
                              }
                          ],
                          "mandatory": true,
                          "input_type": "json",
                          "backend_label": "after_play_details"
                      }
                  ],
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "created_at": "2024-07-31 07:36:40",
                  "last_modified_at": "2024-11-09 09:34:38"
              },
              {
                  "id": 12,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/After.png",
                  "name": "BETTER LUCK NEXT TIME",
                  "code": "bl_next_time",
                  "reward_details": [
                      {
                          "label": "Select Reward Type",
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              },
                            {
                              "name": "PROMO NEUCOINS",
                              "id": "promo_neu_coin"
                            }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "label": "Select Cashback Type",
                          "options": [
                              {
                                  "label": "Fixed Cashback",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Min Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "label": "Avg Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "label": "Std Deviation",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "% Cashback",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Percentage",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "label": "Reward Title",
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "title"
                      },
                      {
                          "label": "Choose A Reward Icon",
                          "options": [
                              "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "reward_icon"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": {},
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "created_at": "2024-08-02 04:11:01",
                  "last_modified_at": "2024-11-08 16:57:58"
              },
            {
                "id": 13,
                "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
              name: "PROMO NEUCOINS",
              code: "promo_neu_coin",
                "reward_details": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "31.3%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                          {
                            "name": "PROMO NEUCOINS",
                            "id": "promo_neu_coin"
                          }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "reward_type"
                    },
                    {
                        "type": "number",
                        "label": "Win Probability",
                        "style": {
                            "width": "31.3%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "backend_label": "win_probability"
                    },
                  {
                    "type": "string",
                    "label": "Program ID",
                    "style": {
                      "width": "31.3%",
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "program_id",
                  },
                    {
                        "mandatory": false,
                        "input_type": "reward_limit",
                        "limitOptions": {
                            "limitTypeOptions": [
                                {
                                    "id": "reward_value",
                                    "name": "Value"
                                },
                                {
                                    "id": "reward_count",
                                    "name": "No. of Redemptions"
                                }
                            ],
                            "limitLevelOptions": [
                                {
                                    "id": "product",
                                    "name": "Product"
                                },
                                {
                                    "id": "user",
                                    "name": "User"
                                }
                            ]
                        },
                        "backend_label": "reward_limit"
                    }
                ],
                "design_details": [
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "e.g: NEUCOINS",
                        "backend_label": "title",
                        "max_characters": 20
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Reward Value Colour",
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                "created_at": "2024-07-31 07:36:40",
                "last_modified_at": "2024-11-09 09:34:38"
            }
          ]
        }
        dispatch({ type: getRewardTypes, payload: res.data });
        // dispatch({ type: getRewardTypes, payload: data?.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  export const getAllRewardFunction = () => (dispatch) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(`${BaseUrl}/api/reward`,null,config )
      .then((res) => {
        const data = {
          "rewardTypes": [
              {
                  "id": 8,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a30c64_Cash back icon.png",
                  "name": "CASHBACK",
                  "reward_details": [
                      {
                          "label": "Select Reward Type",
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "label": "Select Cashback Type",
                          "options": [
                              {
                                  "label": "Fixed Cashback",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Min Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "label": "Avg Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "label": "Std Deviation",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "% Cashback",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Percentage",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "label": "Reward Title",
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "title"
                      },
                      {
                          "label": "Choose A Reward Icon",
                          "options": [
                              "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "reward_icon"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": {},
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "currency_id": 12,
                  "reward_type_code": "cashback",
                  "created_at": "2024-07-31 07:34:34",
                  "last_modified_at": "2024-11-08 16:57:58"
              },
              {
                  "id": 9,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5adfcb_voucher_icon.png",
                  "name": "VOUCHER",
                  "reward_details": [
                      {
                          "label": "Select Reward Type",
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "label": "Select Cashback Type",
                          "options": [
                              {
                                  "label": "Fixed Cashback",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Min Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "label": "Avg Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "label": "Std Deviation",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "% Cashback",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Percentage",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "label": "Reward Title",
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "title"
                      },
                      {
                          "label": "Choose A Reward Icon",
                          "options": [
                              "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "reward_icon"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": {},
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "currency_id": 11,
                  "reward_type_code": "voucher",
                  "created_at": "2024-07-31 07:35:03",
                  "last_modified_at": "2024-11-08 16:57:58"
              },
              {
                  "id": 10,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f1e32a_neucoin_icon.png",
                  "name": "Neucoins",
                  "reward_details": [
                      {
                          "label": "Reward",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "type": "number",
                          "label": "Win Probability",
                          "style": {
                              "width": "31.3%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "win_probability"
                      },
                      {
                          "label": "Cashback Type",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "label": "Fixed",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Min. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Avg. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Std. Deviation",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "Percentage",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Percentage",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_value",
                                      "name": "Value"
                                  },
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": [
                      {
                          "label": "Title",
                          "style": {
                              "width": "48%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "placeholder": "e.g: NEUCOINS",
                          "backend_label": "title",
                          "max_characters": 20
                      },
                      {
                          "label": "Title Colour",
                          "style": {
                              "width": "48%"
                          },
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "title_colour"
                      },
                      {
                          "label": "Logo Image",
                          "options": [
                              {
                                  "id": 1579,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                              },
                              {
                                  "id": 1580,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                              },
                              {
                                  "id": 1581,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "logo_image"
                      },
                      {
                          "label": "Reward Value Colour",
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "reward_value_color"
                      },
                      {
                          "label": "Scratch Card Image",
                          "options": [
                              {
                                  "id": 1582,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                              },
                              {
                                  "id": 1583,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                              },
                              {
                                  "id": 1584,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                              },
                              {
                                  "id": 1585,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                              },
                              {
                                  "id": 1586,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                              },
                              {
                                  "id": 1587,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                              },
                              {
                                  "id": 1588,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "scratch_card_image"
                      },
                      {
                          "label": "After Play Details",
                          "fields": [
                              {
                                  "label": "Title",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : Congratulations!",
                                  "backend_label": "title",
                                  "max_characters": 50
                              },
                              {
                                  "label": "Description",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : You have won 100 Neucoins",
                                  "backend_label": "description",
                                  "max_characters": 100
                              }
                          ],
                          "mandatory": true,
                          "input_type": "json",
                          "backend_label": "after_play_details"
                      }
                  ],
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "currency_id": 2,
                  "reward_type_code": "neu_coin",
                  "created_at": "2024-07-31 07:36:28",
                  "last_modified_at": "2024-11-09 09:31:07"
              },
              {
                  "id": 11,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
                  "name": "Digital Gold",
                  "reward_details": [
                      {
                          "label": "Reward",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "type": "number",
                          "label": "Win Probability",
                          "style": {
                              "width": "31.3%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "win_probability"
                      },
                      {
                          "label": "Cashback Type",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "label": "Fixed",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Min. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Avg. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Std. Deviation",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "Percentage",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Percentage",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_value",
                                      "name": "Value"
                                  },
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": [
                      {
                          "label": "Title",
                          "style": {
                              "width": "48%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "placeholder": "e.g: NEUCOINS",
                          "backend_label": "title",
                          "max_characters": 20
                      },
                      {
                          "label": "Title Colour",
                          "style": {
                              "width": "48%"
                          },
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "title_colour"
                      },
                      {
                          "label": "Logo Image",
                          "options": [
                              {
                                  "id": 1579,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                              },
                              {
                                  "id": 1580,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                              },
                              {
                                  "id": 1581,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "logo_image"
                      },
                      {
                          "label": "Reward Value Colour",
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "reward_value_color"
                      },
                      {
                          "label": "Scratch Card Image",
                          "options": [
                              {
                                  "id": 1582,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                              },
                              {
                                  "id": 1583,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                              },
                              {
                                  "id": 1584,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                              },
                              {
                                  "id": 1585,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                              },
                              {
                                  "id": 1586,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                              },
                              {
                                  "id": 1587,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                              },
                              {
                                  "id": 1588,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "scratch_card_image"
                      },
                      {
                          "label": "After Play Details",
                          "fields": [
                              {
                                  "label": "Title",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : Congratulations!",
                                  "backend_label": "title",
                                  "max_characters": 50
                              },
                              {
                                  "label": "Description",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : You have won 100 Neucoins",
                                  "backend_label": "description",
                                  "max_characters": 100
                              }
                          ],
                          "mandatory": true,
                          "input_type": "json",
                          "backend_label": "after_play_details"
                      }
                  ],
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "currency_id": 1,
                  "reward_type_code": "digital_gold",
                  "created_at": "2024-07-31 07:36:40",
                  "last_modified_at": "2024-11-09 09:34:38"
              },
              {
                  "id": 12,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/After.png",
                  "name": "BETTER LUCK NEXT TIME",
                  "reward_details": [
                      {
                          "label": "Select Reward Type",
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "label": "Select Cashback Type",
                          "options": [
                              {
                                  "label": "Fixed Cashback",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Min Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "label": "Avg Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "label": "Std Deviation",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "% Cashback",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "label": "Percentage",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "label": "Max Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "label": "Reward Title",
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "title"
                      },
                      {
                          "label": "Choose A Reward Icon",
                          "options": [
                              "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                              "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                              "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "reward_icon"
                      },
                      {
                          "mandatory": false,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": {},
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "currency_id": null,
                  "reward_type_code": "bl_next_time",
                  "created_at": "2024-08-02 04:11:01",
                  "last_modified_at": "2024-11-08 16:57:58"
              },
              {
                  "id": 13,
                  "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
                  "name": "PROMO NEUCOINS",
                  "reward_details": [
                      {
                          "label": "Reward",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "id": "better_luck_next_time",
                                  "name": "Better Luck Next Time"
                              },
                              {
                                  "id": "voucher",
                                  "name": "Voucher"
                              },
                              {
                                  "id": "neu_coin",
                                  "name": "Neucoins"
                              },
                              {
                                  "id": "digital_gold",
                                  "name": "Digital Gold"
                              },
                              {
                                  "id": "cashback",
                                  "name": "Cashback"
                              },
                              {
                                  "id": "promonc",
                                  "name": "PROMO NEUCOINS"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "reward_type"
                      },
                      {
                          "type": "number",
                          "label": "Win Probability",
                          "style": {
                              "width": "31.3%"
                          },
                          "mandatory": true,
                          "input_type": "input_box",
                          "backend_label": "win_probability"
                      },
                      {
                          "label": "Cashback Type",
                          "style": {
                              "width": "31.3%"
                          },
                          "options": [
                              {
                                  "label": "Fixed",
                                  "value": "FIXED_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Value",
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "reward_value"
                                      }
                                  ]
                              },
                              {
                                  "label": "Dynamic Cashback",
                                  "value": "DYNAMIC_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Min. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "min"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Avg. Value",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "mean"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Std. Deviation",
                                          "style": {
                                              "width": "22%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "standard_deviation"
                                      }
                                  ]
                              },
                              {
                                  "label": "Percentage",
                                  "value": "PERCENTAGE_CASHBACK",
                                  "fields": [
                                      {
                                          "type": "number",
                                          "label": "Percentage",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "percentage"
                                      },
                                      {
                                          "type": "number",
                                          "label": "Max. Value",
                                          "style": {
                                              "width": "48%"
                                          },
                                          "mandatory": true,
                                          "input_type": "input_box",
                                          "backend_label": "max_value"
                                      }
                                  ]
                              }
                          ],
                          "mandatory": true,
                          "input_type": "dropdown",
                          "backend_label": "cashback_type"
                      },
                      {
                          "label": "Reward Value",
                          "mandatory": true,
                          "input_type": "array",
                          "backend_label": "value"
                      },
                      {
                          "mandatory": true,
                          "input_type": "reward_limit",
                          "limitOptions": {
                              "limitTypeOptions": [
                                  {
                                      "id": "reward_value",
                                      "name": "Value"
                                  },
                                  {
                                      "id": "reward_count",
                                      "name": "No. of Redemptions"
                                  }
                              ],
                              "limitLevelOptions": [
                                  {
                                      "id": "product",
                                      "name": "Product"
                                  },
                                  {
                                      "id": "user",
                                      "name": "User"
                                  }
                              ]
                          },
                          "backend_label": "reward_limit"
                      }
                  ],
                  "design_details": [
                    {
                      "label": "Logo Image",
                      "options": [
                          {
                              "id": 1579,
                              "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                          },
                          {
                              "id": 1580,
                              "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                          },
                          {
                              "id": 1581,
                              "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                          }
                      ],
                      "mandatory": true,
                      "input_type": "image_upload",
                      "backend_label": "logo_image"
                  },
                      {
                          "label": "Title Colour",
                          "style": {
                              "width": "48%"
                          },
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "title_colour"
                      },
                     
                      {
                          "label": "Reward Value Colour",
                          "style": {
                            "width": "48%"
                        },
                          "default": "#000000",
                          "mandatory": true,
                          "input_type": "color_picker",
                          "backend_label": "reward_value_color"
                      },
                      {
                          "label": "Scratch Card Image",
                          "options": [
                              {
                                  "id": 1582,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                              },
                              {
                                  "id": 1583,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                              },
                              {
                                  "id": 1584,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                              },
                              {
                                  "id": 1585,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                              },
                              {
                                  "id": 1586,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                              },
                              {
                                  "id": 1587,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                              },
                              {
                                  "id": 1588,
                                  "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                              }
                          ],
                          "mandatory": true,
                          "input_type": "image_upload",
                          "backend_label": "scratch_card_image"
                      },
                      {
                          "label": "After Play Details",
                          "fields": [
                              {
                                  "label": "Title",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : Congratulations!",
                                  "backend_label": "title",
                                  "max_characters": 50
                              },
                              {
                                  "label": "Description",
                                  "mandatory": true,
                                  "input_type": "input_box",
                                  "placeholder": "e.g : You have won 100 Neucoins",
                                  "backend_label": "description",
                                  "max_characters": 100
                              },
                              {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200,
                              },
                          ],
                          "mandatory": true,
                          "input_type": "json",
                          "backend_label": "after_play_details"
                      }
                  ],
                  "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
                  "currency_id": 13,
                  "reward_type_code": "promo_neu_coin",
                  "created_at": "2024-11-05 11:33:55",
                  "last_modified_at": "2024-11-14 13:30:16"
              }
          ]
      }
        dispatch({ type: getPreviouslyCreateRewards, payload: res.data });
        // dispatch({ type: getPreviouslyCreateRewards, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };