import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiInput from "../../MuiInput";
import Label from "../../Label";
import SmallImageUpload from "../../SmallImageUpload";
import tickImage from "../../../assets/whiteTick.png";
import MuiRadioGroup from "../../MuiRadioGroup";
import ColorInput from "../../ColorInput";
import { CreateGame_PreData } from "../../../utils/CreateGame_PreData";

const images = [
  "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
  "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
  "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
];

export default function ScratchCard_AfterPlay({
  data,
  onChange,
  handleAfterPlayChange,
}) {
  const { ctaColor, ctaTextColor, color } = CreateGame_PreData();

  useEffect(() => {
    handleAfterPlayChange("prefilledData", "", {
      titleColor: data?.afterPlay?.scratchCardData?.titleColor || ctaColor,
      noRewardTitleColor:
        data?.afterPlay?.scratchCardData?.noRewardTitleColor || color,
      scratchCardColor:
        data?.afterPlay?.scratchCardData?.scratchCardColor || ctaTextColor,
      backgroundColorCode:
        data?.afterPlay?.scratchCardData?.backgroundColorCode || color,
    });
  }, [])
  return (
    <Box>
      <Box sx={{ width: "400px" }}>
        <Box
          sx={{
            display: data?.afterPlay?.scratchCardData?.title_required
              ? "block"
              : "none",
          }}
        >
          <Label label={"1. Reward Title"} />
          <MuiInput
            value={data?.afterPlay?.scratchCardData?.title}
            onChange={(event) => handleAfterPlayChange("title", event)}
          />
        </Box>
        <Box
          mt={"20px"}
          sx={{
            display: data?.afterPlay?.scratchCardData?.titleColor_required
              ? "block"
              : "none",
          }}
        >
          <Label label={"2. Title Color"} />
          <ColorInput
            value={data?.afterPlay?.scratchCardData?.titleColor}
            onChange={(event) => handleAfterPlayChange("titleColor", event)}
          />
        </Box>

        <Box
          sx={{
            display: data?.afterPlay?.scratchCardData?.noRewardTitle_required
              ? "block"
              : "none",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", mt: "20px" }}>
            <Label label={"3. Reward Title"} />
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15.06px",
                color: "#6F747E",
                mt: "2px",
              }}
            >
              (Only for no rewards)
            </Typography>
          </Box>
          <MuiInput
            value={data?.afterPlay?.scratchCardData?.noRewardTitle}
            onChange={(event) => handleAfterPlayChange("noRewardTitle", event)}
          />
        </Box>
        <Box
          mt={"20px"}
          sx={{
            display: data?.afterPlay?.scratchCardData
              ?.noRewardTitleColor_required
              ? "block"
              : "none",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", mt: "20px" }}>
            <Label label={"4. Title Color"} />
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15.06px",
                color: "#6F747E",
                mt: "2px",
              }}
            >
              (Only for no rewards)
            </Typography>
          </Box>
          <ColorInput
            value={data?.afterPlay?.scratchCardData?.noRewardTitleColor}
            onChange={(event) =>
              handleAfterPlayChange("noRewardTitleColor", event)
            }
          />
        </Box>
      </Box>
  

      <Box
        mt={"20px"}
        sx={{
          display: data?.afterPlay?.scratchCardData?.scratchCard_required
            ? "block"
            : "none",
        }}
      >
        <Label label={"5. Scratch card "} />
        <Box mt={"-10px"}>
          <MuiRadioGroup
            options={[
              { label: "Color", value: "color" },
              { label: "Image", value: "image" },
            ]}
            value={data?.afterPlay?.scratchCardData?.scratchCardType || "color"}
            onChange={(value) =>
              handleAfterPlayChange("scratchCardType", value)
            }
          />
        </Box>
        {data?.afterPlay?.scratchCardData?.scratchCardType === "image" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "15px",
            }}
          >
            {images?.map((image, index) => (
              <Box
                key={index}
                sx={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "8px",
                  border:
                    data?.afterPlay?.scratchCardData?.scratchCard === image
                      ? "3px solid black"
                      : "3px solid transparent",
                  position: "relative", // Add this
                  overflow: "hidden", // Add this
                }}
                onClick={() => handleAfterPlayChange("scratchCard", image)}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6px",
                    objectFit: "cover", // Add this
                  }}
                  src={image}
                  alt=""
                />
                {data?.afterPlay?.scratchCardData?.scratchCard === image && (
                  <img
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "5px",
                      zIndex: 100,
                      width: "20px", // Add this
                      height: "20px", // Add this
                    }}
                    src={tickImage}
                    alt="Selected"
                  />
                )}
              </Box>
            ))}
            <Box
              sx={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <Typography>OR</Typography>
              <Box>
                <SmallImageUpload
                  value={data?.afterPlay?.scratchCardData?.scratchCardUploadUrl}
                  onClick={() => {
                    if (
                      data?.afterPlay?.scratchCardData?.scratchCardUploadUrl
                    ) {
                      handleAfterPlayChange(
                        "scratchCard",
                        data?.afterPlay?.scratchCardData?.scratchCardUploadUrl
                      );
                    }
                  }}
                  borderValue={data?.afterPlay?.scratchCardData?.scratchCard}
                  onChange={(file) =>
                    handleAfterPlayChange("scratchCardUpload", file)
                  }
                  handleImage={(value) =>
                    onChange({
                      ...data,
                      afterPlay: {
                        ...data.afterPlay,
                        scratchCardData: {
                          ...data.afterPlay.scratchCardData,
                          scratchCardUpload: value,
                          [`scratchCardUploadUrl`]: value,
                          [`scratchCardUploadId`]: value,
                          scratchCard: value,
                        },
                      },
                    })
                  }
                  image={
                    data?.afterPlay?.scratchCardData?.scratchCardUploadBase64
                  }
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: "400px" }} mt={"-10px"}>
            <ColorInput
              value={data?.afterPlay?.scratchCardData?.scratchCardColor}
              onChange={(event) =>
                handleAfterPlayChange("scratchCardColor", event)
              }
            />
          </Box>
        )}
      </Box>

      <Box
        mt={"20px"}
        sx={{
          display: data?.afterPlay?.scratchCardData?.backgroundColor_required
            ? "block"
            : "none",
        }}
      >
        <Label label={"6. background "} />
        <Box mt={"-10px"}>
          <MuiRadioGroup
            options={[
              { label: "Color", value: "color" },
              { label: "Image", value: "image" },
            ]}
            value={
              data?.afterPlay?.scratchCardData?.backgroundColorType || "color"
            }
            onChange={(value) =>
              handleAfterPlayChange("backgroundColorType", value)
            }
          />
        </Box>
        <Box></Box>
        {data?.afterPlay?.scratchCardData?.backgroundColorType === "image" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "15px",
            }}
          >
            {images?.map((image, index) => (
              <Box
                key={index}
                sx={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "8px",
                  border:
                    data?.afterPlay?.scratchCardData?.backgroundColor === image
                      ? "3px solid black"
                      : "3px solid transparent",
                  position: "relative", // Add this
                  overflow: "hidden", // Add this
                }}
                onClick={() => handleAfterPlayChange("backgroundColor", image)}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6px",
                    objectFit: "cover", // Add this
                  }}
                  src={image}
                  alt=""
                />
                {data?.afterPlay?.scratchCardData?.backgroundColor ===
                  image && (
                  <img
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "5px",
                      zIndex: 100,
                      width: "20px", // Add this
                      height: "20px", // Add this
                    }}
                    src={tickImage}
                    alt="Selected"
                  />
                )}
              </Box>
            ))}
            <Box
              sx={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <Typography>OR</Typography>
              <Box>
                <SmallImageUpload
                  value={
                    data?.afterPlay?.scratchCardData?.backgroundColorUploadUrl
                  }
                  onClick={() => {
                    if (
                      data?.afterPlay?.scratchCardData?.backgroundColorUploadUrl
                    ) {
                      handleAfterPlayChange(
                        "backgroundColor",
                        data?.afterPlay?.scratchCardData
                          ?.backgroundColorUploadUrl
                      );
                    }
                  }}
                  borderValue={
                    data?.afterPlay?.scratchCardData?.backgroundColor
                  }
                  onChange={(file) =>
                    handleAfterPlayChange("backgroundColorUpload", file)
                  }
                  handleImage={(value) =>
                    onChange({
                      ...data,
                      afterPlay: {
                        ...data.afterPlay,
                        scratchCardData: {
                          ...data.afterPlay.scratchCardData,
                          backgroundColorUpload: value,
                          [`backgroundColorUploadUrl`]: value,
                          [`backgroundColorUploadId`]: value,
                          backgroundColor: value,
                        },
                      },
                    })
                  }
                  image={
                    data?.afterPlay?.scratchCardData?.backgroundUploadBase64
                  }
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box mt={"-10px"} sx={{ width: "400px" }}>
            <ColorInput
              value={data?.afterPlay?.scratchCardData?.backgroundColorCode}
              onChange={(event) =>
                handleAfterPlayChange("backgroundColorCode", event)
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
