import { TextField, Box, styled } from "@mui/material";
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from 'antd';
import { BorderColor } from "@mui/icons-material";

const StyledDatePicker = styled(DatePicker)(({ theme, errorMessage, editBoolean }) => ({
  '& .ant-picker-input > input': {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#151515 !important', // Added !important to override disabled state
    padding: '0px',
    height: '23px',
    '&::placeholder': {
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      color: "#80858f",
      opacity: 0.6,
    },
    '&[disabled]': {
      color: '#151515 !important', // Maintain same color when disabled
      WebkitTextFillColor: '#151515 !important', // Required for webkit browsers
      cursor: 'not-allowed',
    },
  },
  '& .ant-picker': {
    borderRadius: '6px',
    border: `1px solid ${editBoolean ? 'orange' : 'rgba(0, 0, 0, 0.59)'}`,
    '&:focus, &.ant-picker-focused': {
      borderColor: editBoolean ? 'orange' : theme.palette.primary.main,
      borderWidth: '2px',
      outline: 'none',
      boxShadow: `0 0 0 2px ${editBoolean ? 'orange' : theme.palette.primary.main}33`,
    },
    '&.ant-picker-disabled': {
      backgroundColor: '#f5f5f5', // Light gray background for disabled state
      '& .ant-picker-input > input': {
        color: '#151515 !important',
        cursor: 'not-allowed',
      },
      '& .ant-picker-suffix': {
        color: 'rgba(0, 0, 0, 0.54) !important', // Keep icon color consistent
      },
    },
  },
  '&.ant-picker-outlined': {
    backgroundColor: 'white',
    borderRadius: '6px !important',
    borderColor: editBoolean ? 'orange' : '#cac9c9',
    '&:hover': {
      borderColor: editBoolean ? 'orange' : `${theme.palette.text.secondary}`,
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  },
  '& .ant-picker-suffix': {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '& .ant-picker-clear': {
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));

export const MUIDateTimePicker = ({
  value,
  onChange,
  disableFuture = false,
  disablePast = false,
  maxDate = null,
  minDate = null,
  errorMessage = "",
  disabled = false,
  placeholder = "Select Date & Time",
  useAntDesign = true,
  editBoolean = true,
}) => {
  if (useAntDesign) {
    return (
      <Box sx={{ mt: "10px", width: "100%" }}>
        <StyledDatePicker
          needConfirm={true}
          errorMessage={errorMessage}
          disabled={disabled}
          status={errorMessage ? "error" : undefined}
          value={value ? dayjs(value) : null}
          disabledDate={(current) => {
            return (
              current && (current < dayjs(minDate) || current > dayjs(maxDate))
            );
          }}
          format="DD/MM/YYYY HH:mm"
          showTime={{ format: "HH:mm" }}
          onChange={(date, dateString) => {
            onChange(date);
          }}
          placeholder={placeholder}
          allowClear={false}
          editBoolean={editBoolean}
          style={{ width: "100%" }}
        />
        {errorMessage && (
          <Box sx={{ color: 'error.main', fontSize: '14px', mt: 0.5, textAlign: "start", paddingLeft: "10px" }}>
            {errorMessage}
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box sx={{ mt: '10px', width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={disabled}
            value={value ? dayjs(value) : null}
            disablePast={disablePast}
            disableFuture={disableFuture}
            maxDate={maxDate}
            minDate={minDate}
            format="DD/MM/YYYY HH:mm"
            className="form-control"
            slotProps={{
              textField: {
                size: "small",
                error: !!errorMessage,
                helperText: errorMessage,
                label: placeholder,
              },
            }}
            onChange={(dateTime) => {
              if (dateTime?.isValid()) {
                onChange(dateTime?.toDate());
              } else {
                onChange(dateTime);
              }
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </LocalizationProvider>
      </Box>
    );
  }
};