import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Button, Modal } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BasicOffers from "./BasicOffers";
import OfferDetails from "./OfferDetails";
import CreateGame from "./CreateGame";
import OfferLimit from "./OfferLimit";
import SetOfferRules from "./SetOfferRules";
import { useDispatch, useSelector } from "react-redux";
import {
  handleEditData,
  handleSubmitAllData,
} from "../../Redux/OfferReducer/action";
import {
  cancelErrorSubmit,
  closeModel,
  editConfirmation,
  editOfferFail,
  editOfferSuccess,
  resetEdit,
} from "../../Redux/OfferReducer/actionType";
import CorrectMark from "../../assets/Correctmark.png";
import closeBtnLogo from "../../assets/closeBtnlogo.png";
import linkImg from "../../assets/linkImg.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import { convertLimitArrayToObject } from "../../utils/CreateGame_PreData";
import axios from "axios";
import { getToken } from "../../utils/token";
import TextArea from "antd/es/input/TextArea";
import { getGameDataName } from "../../utils/gameDataName";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const OfferPreview = ({
  data,
  onChange,
  basicOfferError,
  handleBasicOffersError,
  offerDetailsErrorMessage,
  handleOfferDetailsError,
  coverPhoto,
  handleCoverPhoto,
  createGameErrorMessage,
  handleCreateGameError,
  merchantLogoImg,
  handleMerchantLogoImg,
  handleScratchCardImage,
  scratchCardImage,
  setOfferRulesErrorMessage,
  handleSetOfferRulesError,
  cancelPreviewShow,
  handleOfferLimitError,
  offerLimitErrorMessage,
  handleSubmit,
  updatePermission = true,
  isPreviewShow,
}) => {
  const EditAndPreviewComponents = [
    {
      title: "1. Offer Basic",
      component: (
        <BasicOffers
          isEdit={true}
          data={data?.basicOffers}
          onChange={(data) => onChange("basicOffers", data)}
          errorMessage={basicOfferError}
          handleBasicOffersError={handleBasicOffersError}
        />
      ),
    },
    {
      title: "2. Offer Details",
      component: (
        <OfferDetails
          data={data?.offerDetails}
          onChange={(data) => onChange("offerDetails", data)}
          errorMessage={offerDetailsErrorMessage}
          handleOfferDetailsError={handleOfferDetailsError}
          coverPhoto={coverPhoto}
          handleCoverPhoto={handleCoverPhoto}
        />
      ),
    },
    {
      title: "3. Create Game",
      component: (
        <CreateGame
          isEdit={true}
          data={data?.createGame}
          onChange={(data) => onChange("createGame", data)}
          errorMessage={createGameErrorMessage}
          handleCreateGameError={handleCreateGameError}
          merchantLogoImg={merchantLogoImg}
          scratchCardImage={scratchCardImage}
          handleScratchCardImage={handleScratchCardImage}
          handleMerchantLogoImg={handleMerchantLogoImg}
        />
      ),
    },
    {
      title: "4. Set Offer Rule",
      component: (
        <SetOfferRules
          data={data?.setOfferRules}
          onChange={(data) => onChange("setOfferRules", data)}
          errorMessage={setOfferRulesErrorMessage}
          handleSetOfferRulesError={handleSetOfferRulesError}
          isEdit={true}
          isPreviewShow={isPreviewShow}
        />
      ),
    },
    // {
    //   title: "5. Offer Limit",
    //   component: (
    //     <OfferLimit
    //       data={data?.offerLimit}
    //       onChange={(data) => {
    //         // handleOfferLimitError();
    //         onChange("offerLimit", data);
    //       }}
    //       errorMessage={offerLimitErrorMessage}
    //     />
    //   ),
    // },
  ];

  const [mainData, setMainData] = useState({});
  const [approvalType, setApprovalType] = useState("");
  const [denyReason,setDenyReason] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flag = useSelector((store) => store.OfferReducer.flag);
  const editSuccess = useSelector((store) => store.OfferReducer.editSuccess);
  const editError = useSelector((store) => store.OfferReducer.editError);
  const editErrorMessage = useSelector(
    (store) => store.OfferReducer.editErrorMessage
  );
  const submitOfferError = useSelector(
    (store) => store.OfferReducer.submitOfferError
  );
  const offerId = useSelector((store) => store.OfferReducer.offerId);
  const errorMessage = useSelector((store) => store.OfferReducer.errorMessage);
  const initialData = useSelector(
    (store) => store.OfferReducer.initialOfferEditValue
  );
  console.log(denyReason, "denyReason");
  const { id } = useParams();
  const { pathname } = useLocation();
  const envUrl = process.env.REACT_APP_OFFER_SUCCESS_URL;
  const OfferSuccessUrl = `${envUrl}/${offerId}`;
    const rewards = useSelector((store) => store?.OfferReducer?.allRewards)?.rewardTypes;
  console.log(rewards, "URL");
  function removeEmptyValues(obj) {
    const newObj = {};
    for (const key in obj) {
      if (
        !Array.isArray(obj[key]) &&
        typeof obj[key] === "object" &&
        obj[key] !== null
      ) {
        const nestedObj = removeEmptyValues(obj[key]);
        if (Object.keys(nestedObj).length !== 0) {
          newObj[key] = nestedObj;
        }
      } else if (
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key] !== ""
      ) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }



  function findEditedFields(initialData, editedData) {
    const changes = {};
  
    function compareObjects(initial, edited, currentChanges) {
      for (const key in edited) {
        if (Array.isArray(edited[key])) {
          if (key === "rewards") {
            const rewardsChanged = compareRewards(initial[key], edited[key]);
            if (rewardsChanged) {
              currentChanges[key] = edited[key];
            }
          } else if (JSON.stringify(initial[key]) !== JSON.stringify(edited[key])) {
            currentChanges[key] = edited[key];
          }
        } else if (typeof edited[key] === "object" && edited[key] !== null) {
          if (initial.hasOwnProperty(key)) {
            currentChanges[key] = {};
            compareObjects(initial[key], edited[key], currentChanges[key]);
            if (Object.keys(currentChanges[key]).length === 0) delete currentChanges[key];
          } else {
            currentChanges[key] = edited[key];
          }
        } else if (initial[key] !== edited[key]) {
          currentChanges[key] = edited[key];
        }
      }
    }
  
    function compareRewards(initialRewards, editedRewards) {
      if (initialRewards.length !== editedRewards.length) {
        return true;
      }
      
      return editedRewards.some((editedReward, index) => {
        return JSON.stringify(editedReward) !== JSON.stringify(initialRewards[index]);
      });
    }
  
    compareObjects(initialData, editedData, changes);
    return changes;
  }
function findCashbackTypeKeyValuePairs(obj) {
  const cashbackTypeId = obj.cashback_type?.id;

  if (!cashbackTypeId) {
      return {};
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
      if (key.startsWith(cashbackTypeId)) {
          const newKey = key.replace(`${cashbackTypeId}_`, '');
          result[newKey] = value;
      }
      return result;
  }, {});
}
  const handleApproveOffer = (offerId) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body={};
    if (approvalType==="deny") {  
      body.action = "DENY";
      body.deny_reason = denyReason;
    } else {
      body.action = "APPROVE";
    }
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/submit-approval/${offerId}`,
        body,
        config
      )
      .then((res) => {
        console.log(res.data);
        console.log("Offer Approved");
        dispatch({ type: editOfferSuccess });
        setDenyReason("");
        // alert("Offer Approved");
        // toast.success("Offer Approved Successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: editOfferFail,
          payload: err?.response?.data?.message,
        });
        setDenyReason("");
      });
  };

  // const linkToCohort = data.setOfferRules.linkToCohort.map((e)=>e.value)
  // Determine the correct reward data based on gameName
  // let rewardsName = "";
  // if (data?.createGame?.gameName === "Scratch Card") {
  //   rewardsName = "scratchCardData";
  // } else if (data?.createGame?.gameName === "Spin The Wheel") {
  //   rewardsName = "spinTheWheelData";
  // } else if (data?.createGame?.gameName === "Slot Machine") {
  //   rewardsName = "slotMachineData";
  // } else if (data?.createGame?.gameName === "Trivia") {
  //   rewardsName = "triviaData";
  // }
  const rewardsName = getGameDataName(data?.createGame?.game_type_id);
  let initialDataObj = {
    categoryId: initialData?.basicOffers?.category,
    subcategoryId: initialData?.basicOffers?.subCategory,
    productId: initialData?.basicOffers?.product,
    merchantId: initialData?.offerDetails?.merchant,
    name: initialData?.offerDetails?.title,
    description: initialData?.offerDetails?.description,
    startsAt: initialData?.offerDetails?.startDate,
    endsAt: initialData?.offerDetails?.endDate,
    programId: initialData?.offerDetails?.programId,
    lockingPeriod: initialData?.offerDetails?.lockingPeriod,
    expiryPeriod: initialData?.offerDetails?.expiryPeriod,
    informationBeforeRedemption:
      initialData?.offerDetails?.offerInformationForPWABefore,
    informationAfterRedemption: initialData?.offerDetails?.offerInformationForPWAAfter,
    termsAndConditions: initialData?.offerDetails?.TAndC,
    initiativeId: initialData?.offerDetails?.offerInitiative,
    currencyCode: initialData?.offerDetails?.rewardCurrency,
    currencyId: initialData?.offerDetails?.rewardCurrencyId,
    coverImageId: initialData?.offerDetails?.coverPhotoId,
    game: {
      game_type_id: initialData?.createGame?.game_type_id,
      rewards: initialData?.createGame?.game?.[rewardsName]?.map((reward) => {
        return {
          reward_type_id: rewards?.find((item) => item?.name == reward?.reward_type?.name)?.id,
          title: reward?.title,
          win_probability: reward?.probability,  
          id: reward?.id ?? "",
          reward_details: {
            cashback_type: reward?.cashback_type?.id ?? "",
            ...findCashbackTypeKeyValuePairs(reward),
            voucherCollectionId: reward?.voucherCollectionId ?? "",
            csvUrl: reward?.csvUrl ?? "",
            reward_icon: reward?.reward_icon,
          
          },
          design_details: {},
          rewardLimits: [{
            limit_level: reward?.rewardLevel?.id,
            entity_type: reward?.rewardType?.id,
            ...convertLimitArrayToObject(reward?.rewardLimit)
          }]
        };
      }),
      design: {
        before_play: {
          ...initialData?.createGame?.beforePlay?.[rewardsName],
        },
        after_play: {
          ...initialData?.createGame?.afterPlay?.[rewardsName]
        },
      },
      details: {},
    },
    earnRuleSetId: initialData?.setOfferRules?.linkToRule,
    collectionsIds: initialData?.setOfferRules?.linkToCohort,
    values: [],
  };

  console.log(initialDataObj,"initialDataObj")
  useEffect(() => {
  
    let obj = {
      // organizationId: "fb14f7c3-f791-4174-a813-570ba90cd213",
      categoryId: data.basicOffers.category,
      subcategoryId: data.basicOffers.subCategory,
      productId: data.basicOffers.product,
      merchantId: data.offerDetails.merchant,
      name: data.offerDetails.title,
      description: data.offerDetails.description,
      startsAt: data.offerDetails.startDate,
      endsAt: data.offerDetails.endDate,
      programId: data?.offerDetails?.programId,
      lockingPeriod: data?.offerDetails?.lockingPeriod,
      expiryPeriod: data?.offerDetails?.expiryPeriod,
      informationBeforeRedemption:
        data.offerDetails.offerInformationForPWABefore,
      informationAfterRedemption: data.offerDetails.offerInformationForPWAAfter,
      termsAndConditions: data.offerDetails.TAndC,
      initiativeId: data.offerDetails.offerInitiative,
      currencyCode: data.offerDetails.rewardCurrency,
      currencyId: data.offerDetails.rewardCurrencyId,
      coverImageId: data.offerDetails.coverPhotoId,
      game: {
        game_type_id: data.createGame?.game_type_id,
        rewards: data.createGame?.game?.[rewardsName]?.map((reward) => {
          if(reward?.id){
            return {
              reward_type_id: rewards.find((item)=>item?.name==reward?.reward_type?.name)?.id,
              title: reward?.title,
              win_probability: reward?.probability,  
              id: reward?.id ?? "",
              reward_details: {
                cashback_type: reward?.cashback_type?.id ?? "",
                ...findCashbackTypeKeyValuePairs(reward),
                voucherCollectionId:reward?.voucherCollectionId ?? "",
                csvUrl: reward?.csvUrl ?? "",
                reward_icon:reward?.rewardIcon,
              },
              design_details: {},
              rewardLimits:[{
                limit_level:reward?.rewardLevel?.id,
                entity_type:reward?.rewardType?.id,
                ...convertLimitArrayToObject(reward?.rewardLimit)
              }]
            };
          }else{
            return {
              reward_type_id: rewards.find((item)=>item?.name==reward?.reward_type?.name)?.id,
              title: reward?.title,
              win_probability: reward?.probability,  
              // id: reward?.id ?? "",
              reward_details: {
                cashback_type: reward?.cashback_type?.id ?? "",
                ...findCashbackTypeKeyValuePairs(reward),
                voucherCollectionId:reward?.voucherCollectionId ?? "",
                csvUrl: reward?.csvUrl ?? "",
                reward_icon:reward?.rewardIcon,
              },
              design_details: {},
              rewardLimits:[{
                limit_level:reward?.rewardLevel?.id,
                entity_type:reward?.rewardType?.id,
                ...convertLimitArrayToObject(reward?.rewardLimit)
              }]
            };
          }
        }),
        design: {
          before_play: {
            ...data.createGame?.beforePlay?.[rewardsName],
          },
          after_play: {
            ...data.createGame?.afterPlay?.[rewardsName]
          },
        },
        details: {},
      },
      earnRuleSetId: data.setOfferRules?.linkToRule,
      collectionsIds: data.setOfferRules?.linkToCohort,
      values: [],
    };

    if (data.offerDetails.rewardCurrency?.includes("VOUCHER")) {
      obj.csvUrl = data.offerDetails.csvUrl;
    }

    // if (
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance?.daily ||
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance?.weekly ||
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance?.monthly ||
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance
    //     ?.totalAmount ||
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption?.daily ||
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption?.weekly ||
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption?.monthly ||
    //   data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption?.totalAmount
    // ) {
    //   let userLimit = {
    //     entity_type: "USER",
    //     limit_type: {
    //       daily:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance.daily,
    //       weekly:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance.weekly,
    //       monthly:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance.monthly,
    //       // yearly:null,
    //       overall:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardIssuance
    //           .totalAmount,
    //       daily_issuance:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption.daily,
    //       weekly_issuance:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption
    //           .weekly,
    //       monthly_issuance:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption
    //           .monthly,
    //       overall_issuance:
    //         data.offerLimit?.userLevelLimits?.fairUsageOfRewardRedemption
    //           .totalAmount,
    //     },
    //   };

    //   obj.values.push(userLimit);
    // }

    // if (
    //   data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance?.daily ||
    //   data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance?.weekly ||
    //   data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance?.monthly ||
    //   data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance?.total
    // ) {
    //   const offerLimit = {
    //     entity_type: "OFFER",
    //     limit_type: {
    //       daily:
    //         data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance.daily,
    //       weekly:
    //         data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance.weekly,
    //       monthly:
    //         data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance
    //           .monthly,
    //       // yearly:null,
    //       overall:
    //         data.offerLimit?.offerLevelLimits?.fairUsageOfRewardIssuance.total,
    //     },
    //   };

    //   obj.values.push(offerLimit);
    // }

    setMainData(obj);
  }, [data]);

  useEffect(() => {
    if (flag) {
      setOpen(true);
    }
  }, [flag]);
  console.log(mainData, "mainData");
  const handleSubmitData = () => {
    if (pathname.startsWith("/approve-offer")) {
      // handleApproveOffer(data?.basicOffers?.offerId);
      setOpen(true);
      dispatch({ type: editConfirmation });
    } else if (id) {
      const changedData = findEditedFields(initialData, mainData);

      // uncomment the below line if we want to show some alert if no data is changed

      if (Object.keys(changedData).length === 0) {
        toast.warning("Nothing to edit");
        console.log(changedData, "no data");
      } else {
        setOpen(true);
        dispatch({ type: editConfirmation });
      }
    } else {
      dispatch(handleSubmitAllData(mainData, setLoading));
      console.log(mainData, "submit");
    }
  };
  // console.log(data,"allData")

  const handleEditOffer = () => {
    const changedData = findEditedFields(initialDataObj, mainData);
    dispatch(handleEditData(changedData, id));
    console.log(changedData, "edit");
  };
  // console.log(mainData,"mainData")
  const handleCloseModel = () => {
    setOpen(false);
    dispatch({ type: closeModel });
    navigate("/view-offers");
  };

  const copyToClipboard = () => {
    const link = OfferSuccessUrl; // Replace with your actual offer link
    navigator.clipboard
      .writeText(link)
      .then(() => {
        // alert("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  useEffect(() => {
    if (editSuccess) {
      setOpen(false);
      toast.success(
        pathname.startsWith("/approve-offers")
          ?approvalType === "deny"?"Offer Denied Successfully" :"Offer Approved Successfully"
          : "offer edited successfully"
      );
      setTimeout(() => {
        navigate("/view-offers");
      }, 1500);
      dispatch({ type: resetEdit });
    }
    if (editError) {
      setOpen(false);
      toast.error(editErrorMessage);
      dispatch({ type: resetEdit });
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (submitOfferError) {
      toast.error(errorMessage);
      dispatch({ type: cancelErrorSubmit });
    }
    // console.log(submitOfferError)
  }, [submitOfferError]);

  // if(loading){
  //   return <Loader/>
  // }

  return (
    <Box sx={{ px: "15px" }}>
      <ToastContainer />
      {EditAndPreviewComponents.map((item) => {
        return (
          <>
            <Box pt="20px">
              {/* model */}
              <Modal
                open={open}
                onClose={handleCloseModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                  onClick: (event) => event.stopPropagation()
                }}
              >
                {pathname.startsWith("/approve-offer") ? (
                  approvalType === "deny" ? (
                    <Box sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      // p: 4,
                      px:3,
                      py: 2,
                      borderRadius: "20px",
                    }}>
                      <Typography
                        fontWeight={700}
                        fontSize="18px"
                        lineHeight="30.12px"
                        // marginTop="10px"
                        textAlign="start"
                        mb={1}
                      >
                        Reason For Deny Offer
                      </Typography>
                      <TextArea rows={5} value={denyReason} onChange={(e)=>setDenyReason(e.target.value)} />
                      <Box
                        display="flex"
                        justifyContent="end"
                        gap="20px"
                        marginTop="20px"
                      >
                        <Button
                          sx={{
                            width: "120px",
                            backgroundColor: "white",
                            color: "black",
                            border: "2px solid black",
                            "&:hover": {
                              backgroundColor: "white",
                              opacity: 1,
                            },
                          }}
                          onClick={() => {
                            setOpen(false);
                            dispatch({ type: closeModel });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            width: "120px",
                            backgroundColor: "black",
                            color: "white",
                            border: "2px solid black",
                            "&:hover": {
                              backgroundColor: "black",
                              opacity: 1,
                            },
                          }}
                          onClick={() =>
                            handleApproveOffer(data?.basicOffers?.offerId)
                          }
                        >
                          Deny
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={style}>
                      <Typography
                        fontWeight={700}
                        fontSize="24px"
                        lineHeight="30.12px"
                        marginTop="10px"
                        textAlign="center"
                      >
                        Are you sure you want to Approve this offer
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="center"
                        gap="20px"
                        marginTop="20px"
                      >
                        <Button
                          sx={{
                            width: "120px",
                            backgroundColor: "white",
                            color: "black",
                            border: "2px solid black",
                            "&:hover": {
                              backgroundColor: "white",
                              opacity: 1,
                            },
                          }}
                          onClick={() => {
                            setOpen(false);
                            dispatch({ type: closeModel });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            width: "120px",
                            backgroundColor: "black",
                            color: "white",
                            border: "2px solid black",
                            "&:hover": {
                              backgroundColor: "black",
                              opacity: 1,
                            },
                          }}
                          onClick={() =>
                            handleApproveOffer(data?.basicOffers?.offerId)
                          }
                        >
                          Yes
                        </Button>
                      </Box>

                      {/* <Button onClick={handleCloseModel}>Close</Button> */}
                    </Box>
                  )
                ) : id ? (
                  <Box sx={style}>
                    <Typography
                      fontWeight={700}
                      fontSize="24px"
                      lineHeight="30.12px"
                      marginTop="10px"
                      textAlign="center"
                    >
                      Are you sure you want to Edit this offer
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      gap="20px"
                      marginTop="20px"
                    >
                      <Button
                        sx={{
                          width: "120px",
                          backgroundColor: "white",
                          color: "black",
                          border: "2px solid black",
                          "&:hover": {
                            backgroundColor: "white",
                            opacity: 1,
                          },
                        }}
                        onClick={() => {
                          setOpen(false);
                          dispatch({ type: closeModel });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          width: "120px",
                          backgroundColor: "black",
                          color: "white",
                          border: "2px solid black",
                          "&:hover": {
                            backgroundColor: "black",
                            opacity: 1,
                          },
                        }}
                        onClick={handleEditOffer}
                      >
                        Yes
                      </Button>
                    </Box>

                    {/* <Button onClick={handleCloseModel}>Close</Button> */}
                  </Box>
                ) : (
                  <Box sx={style}>
                    <img
                      onClick={handleCloseModel}
                      style={{
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                        cursor: "pointer",
                      }}
                      src={closeBtnLogo}
                      alt=""
                    />
                    <img
                      style={{ display: "block", margin: "auto" }}
                      src={CorrectMark}
                      alt=""
                    />
                    <Typography
                      fontWeight={700}
                      fontSize="24px"
                      lineHeight="30.12px"
                      marginTop="30px"
                      textAlign="center"
                    >
                      {id
                        ? "Offer Edited Successfully"
                        : "Offer Created Successfully"}
                    </Typography>

                    {/* Text field to display the link */}
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={"44px"}
                      border="1px solid gray"
                      borderRadius="8px"
                      padding="5px 12px"
                    >
                      <Typography
                        variant="body1"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {OfferSuccessUrl}
                      </Typography>
                      <Button
                        onClick={copyToClipboard}
                        sx={{
                          display: "block",
                          marginLeft: "auto",
                          backgroundColor: "black",
                          height: "39px",
                          color: "white",
                          width: "110px",
                          padding: "4px",
                          minWidth: "110px",
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: "black",
                            opacity: 1,
                          },
                        }}
                      >
                        <Box
                          display="flex"
                          gap="5px"
                          height="15px"
                          // width="150px"

                          alignItems="center"
                          paddingLeft="5px"
                          textTransform="capitalize"
                        >
                          <img src={linkImg} alt="" /> <p>Copy</p> <p> Link</p>
                        </Box>
                      </Button>
                    </Box>

                    {/* <Button onClick={handleCloseModel}>Close</Button> */}
                  </Box>
                )}
              </Modal>

              <Box
                sx={{
                  fontFamily: "Mulish",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "25px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "#000000",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "5px",
                }}
              >
                <Typography>{item.title}</Typography>
                {/* <Typography
                  sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <EditIcon sx={{ fontSize: "18px" }} />
                  Edit Details
                </Typography> */}
              </Box>
              <Divider sx={{ mb: "20px" }} />
            </Box>
            {item.component}
          </>
        );
      })}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,

          paddingBottom: "37px",
        }}
      >
        {pathname.startsWith("/approve-offers") ? (
          <Button
            color="inherit"
            onClick={() => {
              setApprovalType("deny");
              setOpen(true);
            }}
            sx={{ mr: 1 }}
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "8px 30px",
              marginRight: "20px",
              display: pathname.startsWith("/preview-offer")
                ? "none"
                : "visible",
              fontFamily: "Mulish",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20.08px",
              borderRadius: "7px",
            }}
            variant="outlined"
            size="small"
          >
            Deny
          </Button>
        ) : (
          <Button
            color="inherit"
            onClick={cancelPreviewShow}
            sx={{ mr: 1 }}
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "8px 30px",
              marginRight: "20px",
              display: pathname.startsWith("/preview-offer")
                ? "none"
                : "visible",
              fontFamily: "Mulish",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20.08px",
              borderRadius: "7px",
            }}
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
        )}
        {updatePermission && (
          <Button
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "8px 30px",
              fontFamily: "Mulish",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20.08px",
              borderRadius: "7px",
            }}
            variant="contained"
            size="small"
            onClick={handleSubmitData}
            sx={{ mr: 1 }}
          >
            {/* Done */}
            {/* {id && initialData.approve === "1"
              ? "Approve"
              : "Submit For Approval"} */}
            {pathname.startsWith("/approve-offer")
              ? "Approve"
              : "Submit For Approval"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OfferPreview;
