import { Box } from "@mui/material";
import React from "react";
import { DeviceFrameset } from "react-device-frameset";
import welcome from "../../assets/welcome.gif";

const WelcomeScreen = () => {
  return (
    <DeviceFrameset
      device="iPhone X"
      color="black"
      zoom="55%"
      style={{
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          background: "#fff",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          //   paddingTop: "144px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            id="particle"
            quality={100}
            src={welcome}
            width={100}
            height={200}
            alt="loading..."
          />
        </Box>

        <p
          style={{
            background:
              "linear-gradient(90deg, #86678f 0%, #b485d2 31.16%, #d0a9ff 49.39%, #a88cff 64.18%, #7d6c97 100%)",
            color: "#005CBD",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          Fetching Your Reward
        </p>
      </Box>
    </DeviceFrameset>
  );
};

export default WelcomeScreen;
