import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./Layout";
import Analytics from "../pages/Analytics";
import Login from "../pages/Login";
import { PrivateRoute } from "./PrivateRoute";
import EditRules from "./offers/EditRules";
import EditOffer from "./offers/EditOffer";
import Limits from "../pages/Limits";
import PreviewOffer from "../pages/PreviewOffer";
import ForgotPassword from "../pages/ForgotPassword";
import { useSelector } from "react-redux";
import Overview from "../assets/Overview.svg";
import { Box } from "@mui/material";
import NotFound from "../pages/NotFound";
import SetQualifyingCriteria from "./offers/Create-Offer/SetQualifyingCriteria";
import OfferDetailsPage from "./offers/Create-Offer/OfferDetailsPage";
import CreateGamePage from "./offers/Create-Offer/CreateGamePage";
import CreateRulePage from "./Rule/CreateRulePage";
import CreateCohortPage from "./Cohort/CreateCohortPage";
import PreviewPage from "./offers/Create-Offer/PreviewPage";
import ViewOfferPage from "./offers/View-Offer/ViewOfferPage";
import ViewRulePage from "./Rule/view-rules/ViewRulesPage";
import ViewCohortPage from "./Cohort/view-cohort/ViewCohortPage";
import TransactionsPage from "./Transaction/TransactionsPage";

const schema = {
  rule: "",
  description: "",
  id: 1,
  type: "",
  whereSection: [{}],
};

const AllRoutes = () => {
  const [tabData, setTabData] = useState([schema]);
  const initialRoute = useSelector((store) => store.AuthReducer.initialRoute);
  const allowedData = useSelector((store) => store.AuthReducer.allowedRoutes);
  const userAllowedData = mapToNavItems(allowedData);
  const isAuth = useSelector(store=>store.AuthReducer.token);

  function mapToNavItems(routes) {
    const navItems = [];

    function mapRoute(route, icons = true) {
      const navItem = {
        text: route.title,
        disabled: route.disabled,
        icon: icons ? (
          <Box
            component="img"
            sx={{
              height: 25,
              width: 25,
              maxHeight: { xs: 200, md: 167 },
              maxWidth: { xs: 200, md: 250 },
            }}
            alt="The house from the offer."
            src={Overview}
          />
        ) : null,
        url: route?.path?.substring(1), // remove the leading '/'
      };
      navItems.push(navItem);
      if (route.subMenu) {
        route.subMenu?.forEach((subRoute) => mapRoute(subRoute, false));
      }
    }

    routes?.forEach((route) => mapRoute(route));

    return navItems;
  }

  const routesObj = {
    analytics: <Analytics />,
    "create-offers": <OfferDetailsPage />,
    "view-offers": <ViewOfferPage />,
    "create-rules": (
      <CreateRulePage/>
    ),
    // "create-rules": (
    //   <CreateRule
    //     activeTab={0}
    //     tabData={tabData}
    //     setTabData={setTabData}
    //     cancelButtonHide={true}
    //   />
    // ),
    "view-rules": <ViewRulePage />,
    "view-cohorts": <ViewCohortPage />,
    "create-cohorts":   <CreateCohortPage />,
    transactions: <TransactionsPage />,
    limits: <Limits />,
  };

  const generatedRoutes = userAllowedData?.map((e, index) => {
    if (routesObj.hasOwnProperty(e.url)) {
      return (
        <Route
          key={index}
          path={`/${e.url}`}
          element={<PrivateRoute>{routesObj[e.url]}</PrivateRoute>}
        />
      );
    }
    return null;
  });
console.log(generatedRoutes, "generatedRoutes");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<Layout />}>
          {/* <Route path="/" element={<Navigate to="/view-rules" replace />} /> */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Navigate to={initialRoute?initialRoute:`/${userAllowedData[0]?.url}`} replace />
                {/* <Navigate to={initialRoute} replace /> */}
              </PrivateRoute>
            }
          />
          {generatedRoutes}
          <Route
            path="/preview-offer/:id"
            element={
              <PrivateRoute>
                <PreviewOffer />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-offers/:id"
            element={
              <PrivateRoute>
                <EditOffer />
              </PrivateRoute>
            }
          />
          <Route
            path="/preview-rule"
            element={
              <PrivateRoute>
                <EditRules
                  activeTab={0}
                  tabData={tabData}
                  setTabData={setTabData}
                  cancelButtonHide={true}
                />
              </PrivateRoute>
            }
          />
          <Route path="/*" element={isAuth ? <NotFound /> : <Navigate to="/login" replace />} />
          <Route
            path="/offers/create-offers/offer-details"
            element={
              <PrivateRoute>
                <OfferDetailsPage />
              </PrivateRoute>
            }
          />
              <Route
            path="/offers/edit-offers/offer-details/:id"
            element={
              <PrivateRoute>
                <OfferDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/create-offers/set-qualifying-criteria"
            element={
              <PrivateRoute>
                <SetQualifyingCriteria />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/edit-offers/set-qualifying-criteria/:id"
            element={
              <PrivateRoute>
                <SetQualifyingCriteria />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/create-offers/set-qualifying-criteria/create-new-rule"
            element={
              <PrivateRoute>
                <CreateRulePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/edit-offers/set-qualifying-criteria/create-new-rule"
            element={
              <PrivateRoute>
                <CreateRulePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/create-offers/set-qualifying-criteria/create-new-cohort"
            element={
              <PrivateRoute>
                <CreateCohortPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/edit-offers/set-qualifying-criteria/create-new-cohort"
            element={
              <PrivateRoute>
                <CreateCohortPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/create-offers/create-game"
            element={
              <PrivateRoute>
                <CreateGamePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/edit-offers/create-game/:id"
            element={
              <PrivateRoute>
                <CreateGamePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/create-offers/preview"
            element={
              <PrivateRoute>
                <PreviewPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/approve-offers/preview"
            element={
              <PrivateRoute>
                <PreviewPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers/edit-offers/preview/:id"
            element={
              <PrivateRoute>
                <PreviewPage />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
