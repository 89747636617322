import React from "react";
import { Box, Typography } from "@mui/material";
import FileUpload from "../../UIComponents/FileUpload";
import UrlUpload from "../../UIComponents/UrlUpload";
import { useLocation } from "react-router-dom";

const UserCohortsFileUpload = ({ onChange, data, errorMessage,textVisibility=true }) => {
  const {pathname} = useLocation();
  return (
    <Box
      sx={{
        p: "15px",
        backgroundColor: "#F9F9FC",
        borderRadius: "6px",
        maxWidth: "500px",
        boxSizing: "border-box",
        // m: 1,
      }}
    >
      {/* <Typography
        sx={{
          color: "var(--Text-dark, #0F1828)",
          fontFamily: "Mulish",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          textAlign: "left",
          mb: "30px",
          display:textVisibility?"visible":"none",
        }}
      >
        1. Upload CSV
      </Typography> */}
      <FileUpload
        onChange={(value) => onChange("csvFile", value)}
        value={data?.csvFile}
        errorMessage={data?.url || data?.csvFile ? "" : errorMessage}
        
      />
      {/* <Typography
        sx={{
          color: "#272728",
          fontFamily: "Outfit",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "23px",
          textAlign: "left",
          mt: "20px",
          mb: "3px",
        }}
      >
        Or upload from URL
      </Typography>
      <UrlUpload
        placeholder="Add file URL"
        handleUpload={() => null}
        value={data?.url}
        onChange={(value) => onChange("url", value)}
        errorMessage={
          errorMessage?.url === "please enter valid url!"
            ? errorMessage.url
            : data?.url || data?.csvFile
            ? ""
            : errorMessage?.url
        }
      /> */}
    </Box>
  );
};

export default UserCohortsFileUpload;
