import React from "react";
import { Box, Button, Typography } from "@mui/material";
import formatNumberShort from "../utils/formatNumberShort";

const TableTotalCount = ({
  totalCount,
  text,
  deleteTab,
  count,
  handleChangeTab,
  index,
  activeTab,
  width="140px"
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.25rem",
      }}
    >
      <Button
        sx={{
          display: "flex",
          width: {width},
          padding: "8px 10px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "10px 10px 0 0",
          borderRight: "1px solid #929EAE",
          borderLeft: "1px solid #929EAE",
          borderTop: "1px solid #929EAE",
        }}
        style={{
          // background: "white",
          backgroundColor: index == activeTab ? "#003D86" : "#E8F0F9",
          
        }}
        onClick={handleChangeTab}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            textAlign: "right",
            fontWeight: "500",
            lineHeight: "17px",
            letterSpacing: "0.02px",
            textTransform: "capitalize",
            color: index == activeTab ? "#ffffff" : "#003D86",
          }}
        >
          {text}
        </Typography>

        {/* delete button */}
        {count > 1 ? (
          <Box
            onClick={deleteTab}
            sx={{
              backgroundColor: "#F1554C",
              width: "17px",
              height: "17px",
              borderRadius: "50%",
              color: "white",
              fontSize: "10px",
              paddingLeft: "1px",
              display: index == 0 ? "none" : "visible",
            }}
          >
            ✖
          </Box>
        ) : (
          ""
        )}

        {totalCount > 0 && (
          <Box
            sx={{
              display: "inline-flex",
              padding: "2px 5px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              backgroundColor: index != activeTab ? "#003D86" : "#E8F0F9",
            }}
          >
            <Typography
              sx={{
                color: index !== activeTab ? "#ffffff" : "#003D86",
                textAlign: "right",
                fontFamily: "Lato",
                fontSize: "10px",
                lineHeight: "normal",
                fontWeight: "500px",
                // letterSpacing: "0.28px",
                textTransform: "capitalize",
              }}
            >
              {formatNumberShort(totalCount)}
            </Typography>
          </Box>
        )}
      </Button>
    </Box>
  );
};

export default TableTotalCount;
