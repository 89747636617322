import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

export default function MuiSelect({
  value,
  onChange,
  label,
  options,
  errorMessage = "",
  isDisabled = false,
  backgroundColor = "",
  textColor = "",
  selectedBackgroundColor = "",
  selectedTextColor = "",
  hoverBackgroundColor = "",
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl
      sx={{ mt: '10px', width: "100%" }}
      size="small"
      error={!!errorMessage}
    >
      <InputLabel sx={{ 
        color: isDisabled ? "gray" : textColor,
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        "&.Mui-disabled": {
          color: "#000000ba !important",
        }
      }}>
        {label}
      </InputLabel>
      <Select
        value={value ?? ""}
        label={label ?? ""}
        onChange={handleChange}
        disabled={isDisabled}
        sx={{ 
          textAlign: "left",
          backgroundColor: backgroundColor,
          color: textColor,
          height: "32px",
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          padding: "8px",
          borderRadius: "6px", // matching borderRadius from MuiInput
          "& .MuiSelect-select": {
            color: textColor,
            padding: "8px",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
          },
          "& .MuiSelect-icon": {
            color: textColor,
          },
          "& .MuiSelect-select.Mui-disabled": {
            WebkitTextFillColor: textColor,
            cursor: "not-allowed",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: textColor,
            borderRadius: "6px", // matching borderRadius for outline
          },
          "& .MuiMenu-paper": {
            backgroundColor: backgroundColor,
            color: textColor,
          },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "orange", // similar to editBoolean
          // },
          // "&:hover .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "orange", // similar to editBoolean
          // },
        }}
        displayEmpty
        renderValue={
          value !== ""
            ? undefined
            : () => (
                <em
                  style={{
                    color: "gray",
                    fontFamily: "Mulish",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "130%",
                  }}
                >
                  --Select--
                </em>
              )
        }
      >
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            value={option?.value ?? ""}
            sx={{ 
              color: textColor,
              backgroundColor: selectedBackgroundColor,
              "&.Mui-selected": {
                backgroundColor: selectedBackgroundColor,
                color: selectedTextColor,
              },
              "&:hover": {
                backgroundColor: hoverBackgroundColor,
              },
              borderRadius: "6px", // matching MenuItem borderRadius as well
            }}
          >
            {option?.label ?? ""}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{ color: textColor }}>{errorMessage}</FormHelperText>
    </FormControl>
  );
}
