export const FS_OPTIONS=[
    {
        label:'javascript',
        value:'javascript'
    },
    {
        label:'java',
        value:'java'
    },
    {
        label:'c++',
        value:'c++'
    },
    {
        label:'python',
        value:'python'
    }
]