import {
  allDataSubmitted,
  cancelErrorSubmit,
  clearCreateOfferData,
  clearRewardPreviousValue,
  closeModel,
  editConfirmation,
  editOfferFail,
  editOfferSuccess,
  editReward,
  errorSubmitOffer,
  getCategory,
  getCohortsData,
  getCurrency,
  getGames,
  getGameType,
  getInitiative,
  getMerchant,
  getPreviouslyCreateRewards,
  getProduct,
  getRewardTypes,
  getRuleSet,
  getSubCategory,
  initialOfferEditValue,
  projectionLoaderStatus,
  resetEdit,
  saveAfterPlayWithGameChanges,
  saveChangedOfferData,
  savePreviousDataForEditOffer,
  saveRewardPreviousValue,
  selectGameType,
  setAfterPlayChange,
  setAfterPlayReward,
  setAfterPlayWithGameChanges,
  setBeforePlay2by2Change,
  setBeforePlay2by2Reward,
  setBeforePlayChange,
  setDrawerImageOption,
  setOfferDetailsDataChanges,
  setQualifyingCriteriaDataChanges,
  setQuizChange,
  setRewardArray,
  toggleReward,
} from "./actionType";

const beforePlayFixedData = {
  title_color: "#000000",
  background_overlay_color: "#000000",
  title: "Before Play Title",
  show_earned_via: {
    id: true,
    name: "Yes",
  },
};

const beforePlaySpinTheWheelData = {
  title_color: "#000000",
  title: "Title",
  spins: "Spin",
  wheel_image:
    "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png",
  pointer_image:
    "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png",
};

const afterPlayWithGameFixedData = {
  title: "After Play Title",
  congratulation_image:
    "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png",
};

const initialState = {
  offerData: {
    offerDetails: {},
    setQualifyingCriteria: {
      step: 1,
    },
    createGame: {
      game: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData: [],
        triviaData_step1: {
          triviaType: "",
          questions: [
            {
              title: "",
              addMedia: false,
              images: [],
              options: [{ optionTitle: "", isSelected: true }],
            },
          ],
        },
      },
      beforePlay: {
        scratchCardData: beforePlayFixedData ?? {},
        spinTheWheelData: beforePlaySpinTheWheelData ?? {},
        slotMachineData: beforePlayFixedData ?? {},
        triviaData: beforePlayFixedData ?? {},
      },
      beforePlay2by2: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData: [],
      },
      afterPlay: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData: [],
      },
      afterPlayWithGame: {
        scratchCardData: {},
        spinTheWheelData: afterPlayWithGameFixedData ?? {},
        slotMachineData: {},
        triviaData: {},
      },
      quiz: {
        scratchCardData: {},
        spinTheWheelData: {
          answer: [
            {
              isCorrect: false,
              option: "",
            },
          ],
        },
        slotMachineData: {},
        triviaData: {},
      },
      whenPlay: {
        triviaData: {},
      },
      addNewRewards: {
        scratchCardData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        spinTheWheelData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        slotMachineData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        triviaData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
      },
      drawerData: {
        backend_label: "",
        image_option: [],
        gameDataName: "",
        game_type: "",
        action_type: "",
        rewardIndex: "",
      },
    },
  },
  categoryOption: [],
  subCategoryOption: [],
  productOption: [],
  merchantOption: [],
  ruleOption: [],
  cohortsOption: [],
  initiativeOption: [],
  gamesOption: [],
  rewardCurrencyOption: [],
  initialOfferEditValue: [],
  flag: false,
  editSuccess: false,
  editError: false,
  editErrorMessage: "",
  submitOfferError: false,
  offerId: "3",
  errorMessage: "",
  allGames: [],
  allRewardTypes: [],
  rewardPreviousValue: {},
  allRewards: {},
  projectionLoader: false,
  previousOfferDataForEditOffer: {},
  changedOfferData: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case saveChangedOfferData:
      return {
        ...state,
        changedOfferData: payload,
      };
    case setOfferDetailsDataChanges:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          offerDetails: {
            ...state.offerData.offerDetails,
            ...payload,
          },
        },
      };
    case setQualifyingCriteriaDataChanges:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          setQualifyingCriteria: {
            ...state.offerData.setQualifyingCriteria,
            ...payload,
          },
        },
      };
    case selectGameType:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            ...payload,
          },
        },
      };
    case toggleReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.updatedRewardArray,
            },
          },
        },
      };
    case setRewardArray:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.rewardArray,
            },
          },
        },
      };
    case editReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.updatedRewardArray,
            },
          },
        },
      };
    case setBeforePlayChange:
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            beforePlay: {
              ...state.offerData.createGame.beforePlay,
              [gameDataName]: {
                ...state.offerData.createGame.beforePlay[gameDataName],
                [field]: value,
                ...additionalFields,
              },
            },
          },
        },
      };
    case setQuizChange: {
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            quiz: {
              ...state.offerData.createGame.quiz,
              [gameDataName]: {
                ...state.offerData.createGame.quiz[gameDataName],
                [field]: value,
                ...additionalFields,
              },
            },
          },
        },
      };
    }
    case setAfterPlayWithGameChanges: {
      console.log("Reducer received action:", payload);
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlayWithGame: {
              ...state.offerData.createGame.afterPlayWithGame,
              [gameDataName]: {
                ...state.offerData.createGame.afterPlayWithGame[gameDataName],
                [field]: value,
                ...additionalFields,
              },
            },
          },
        },
      };
    }
    case saveAfterPlayWithGameChanges: {
      console.log("Reducer received action:", payload);
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlayWithGame: {
              ...state.offerData.createGame.afterPlayWithGame,
              [gameDataName]: {
                ...additionalFields,
              },
            },
          },
        },
      };
    }
    case setAfterPlayChange:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlay: {
              ...state.offerData.createGame.afterPlay,
              [payload.gameDataName]: state.offerData.createGame.afterPlay[
                payload.gameDataName
              ].map((reward, index) => {
                if (index === payload.rewardIndex) {
                  return {
                    ...reward,
                    [payload.field]: payload.value,
                    ...payload.additionalFields,
                  };
                }
                return reward;
              }),
            },
          },
        },
      };
    case setAfterPlayReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlay: {
              ...state.offerData.createGame.afterPlay,
              [payload.gameDataName]: payload.rewardArray,
            },
          },
        },
      };
    case setDrawerImageOption:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            drawerData: {
              ...state.offerData.createGame.drawerData,
              image_option: payload?.image_option,
              backend_label: payload?.backend_label,
              gameDataName: payload?.gameDataName,
              game_type: payload?.game_type,
              action_type: payload?.action_type,
              rewardIndex: payload?.rewardIndex,
            },
          },
        },
      };
    case setBeforePlay2by2Change:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            beforePlay2by2: {
              ...state.offerData.createGame.beforePlay2by2,
              [payload.gameDataName]: state.offerData.createGame.beforePlay2by2[
                payload.gameDataName
              ].map((reward, index) => {
                if (index === payload.rewardIndex) {
                  return {
                    ...reward,
                    [payload.field]: payload.value,
                    ...payload.additionalFields,
                  };
                }
                return reward;
              }),
            },
          },
        },
      };
    case setBeforePlay2by2Reward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            beforePlay2by2: {
              ...state.offerData.createGame.beforePlay2by2,
              [payload.gameDataName]: payload.rewardArray,
            },
          },
        },
      };
    case clearCreateOfferData:
      return {
        ...state,
        offerData: initialState.offerData,
      };
    case getCategory:
      return { ...state, categoryOption: payload };
    case getSubCategory:
      return { ...state, subCategoryOption: payload };
    case getProduct:
      return { ...state, productOption: payload };
    case getMerchant:
      return { ...state, merchantOption: payload };
    case getRuleSet:
      return { ...state, ruleOption: payload };
    case getInitiative:
      return { ...state, initiativeOption: payload };
    case getGames:
      return { ...state, gamesOption: payload };
    case getCurrency:
      return { ...state, rewardCurrencyOption: payload };
    case allDataSubmitted:
      return { ...state, flag: true, offerId: payload.offerId };
    case editOfferSuccess:
      return { ...state, editSuccess: true };
    case editOfferFail:
      return { ...state, editError: true, editErrorMessage: payload };
    case editConfirmation:
      return { ...state, flag: true };
    case resetEdit:
      return { ...state, editSuccess: false, editError: false, flag: false };
    case closeModel:
      return { ...state, flag: false };
    case getCohortsData:
      return { ...state, cohortsOption: payload };
    case initialOfferEditValue:
      return { ...state, initialOfferEditValue: payload };
    case errorSubmitOffer:
      return { ...state, submitOfferError: true, errorMessage: payload };
    case cancelErrorSubmit:
      return { ...state, submitOfferError: false };
    case getGameType:
      return { ...state, allGames: payload };
    case getRewardTypes:
      return { ...state, allRewardTypes: payload };
    case saveRewardPreviousValue:
      return { ...state, rewardPreviousValue: payload };
    case clearRewardPreviousValue:
      return { ...state, rewardPreviousValue: {} };
    case getPreviouslyCreateRewards:
      return { ...state, allRewards: payload };
    case projectionLoaderStatus:
      return { ...state, projectionLoader: payload };
    case savePreviousDataForEditOffer:
      return {
        ...state,
        previousOfferDataForEditOffer: { ...state.offerData },
      };
    default:
      return state;
  }
};
