import React from "react";
import { TextField, InputAdornment } from "@mui/material";

const MuiInput = ({
  value,
  onChange,
  type = "text",
  placeholder,
  label = "",
  errorMessage = "",
  disable = false,
  mt = "10px",
  editBoolean,
  maxLength,
  allowDecimal = true
}) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (type === "number") {
      // Define regex based on allowDecimal prop
      const regex = allowDecimal ? /[^0-9.]/g : /[^0-9]/g;
      // Remove any non-numeric characters (and decimal point if not allowed)
      const numericValue = inputValue.replace(regex, '');
      
      let sanitizedValue = numericValue;
      if (allowDecimal) {
        // Handle decimal point only if decimals are allowed
        const parts = numericValue.split('.');
        sanitizedValue = parts[0] + (parts.length > 1 ? '.' + parts[1] : '');
      }
      
      if (sanitizedValue === '' || (!isNaN(parseFloat(sanitizedValue)) && parseFloat(sanitizedValue) >= 0)) {
        onChange(sanitizedValue);
      }
    } else {
      onChange(inputValue.slice(0, maxLength));
    }
  };

  const handleKeyDown = (event) => {
    if (type === "number") {
      // Prevent 'e' from being entered
      if (event.key === 'e' || event.key === 'E') {
        event.preventDefault();
      }
      // Prevent decimal point if allowDecimal is false
      if (!allowDecimal && event.key === '.') {
        event.preventDefault();
      }
    }
  };

  return (
    <TextField
      sx={{
        width: "100%",
        mt: mt,
        height: "32px",
        "& .MuiOutlinedInput-root": {
          height: "32px",
          "& input": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            padding: "8px",
          },
          "& fieldset": {
            borderColor: editBoolean ? "orange" : "",
            borderRadius: "6px",
          },
          "&:hover fieldset": {
            borderColor: editBoolean ? "orange" : "",
          },
          "&.Mui-focused fieldset": {
            borderColor: editBoolean ? "orange" : "",
          },
          "&.Mui-disabled": {
            opacity: 1,
            "& fieldset": {
              borderColor: editBoolean ? "orange" : "",
            },
          },
        },
        "& .MuiInputLabel-root": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          color: editBoolean ? "orange" : "",
          "&.Mui-focused": {
            color: editBoolean ? "orange" : "",
          },
          "&.Mui-disabled": {
            color: editBoolean ? "orange" : "#000000ba !important",
          },
        },
        "& .MuiInputBase-input::placeholder": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
        },
        "& .Mui-disabled": {
          "-webkit-text-fill-color": "#000000ba !important",
          backgroundColor: "transparent",
        },
        "& .MuiInputAdornment-root": {
          "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#B5BBC5",
          },
        },
      }}
      type={type === "number" ? "text" : type}
      value={value ?? ""}
      disabled={disable}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      label={label}
      placeholder={placeholder}
      size="small"
      helperText={errorMessage}
      error={!!errorMessage}
      onWheel={() => document.activeElement.blur()}
      InputProps={{
        endAdornment: maxLength && (
          <InputAdornment position="end">
            <span style={{ fontSize: '10px', color: '#B5BBC5' }}>
              {`${value?.length ? value.length : 0}/${maxLength}`}
            </span>
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: maxLength,
      }}
    />
  );
};

export default MuiInput;