import couponPageStyle from "./scratchCardId.module.scss";
import { useEffect, useState } from "react";
import bgImg from "../../assets/couponbg.png";
import { Box, Typography } from "@mui/material";
import ScratchCardCoupon from "./ScratchCardImage";
import { DeviceFrameset } from "react-device-frameset";
// import { apiClient } from "src/config/apiClient";
// import { ScratchCardStatusEnum } from "@components/elements/ScratchCardCarousel/scratchCardsCarousel.interface";
// import ScratchCardCoupon from "@components/molecules/ScratchCardCoupon";

export const Coupon = ({ data}) => {
    // const [scratchCardImage, setScratchCardImage] = useState(scratchCard);
    console.log(data,"scratchCardColor");
    const [AllData,setAllData] = useState({})
  //   const { token, scratchCardId, scratchCard } = props;
  //   const {
  //     points,
  //     couponStatus = null,
  //     rewardImage = "",
  //     currency_code = "",
  //     logo = "",
  //     title = "",
  //     status,
  //   } = scratchCard || {};
  //   const [scratchCardStatus, setScratchCardStatus] = useState(status);
  //   console.log("scratchCard:---------", scratchCard);

  const [welcomeScreen, setWelcomeScreen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [screatchColor ,setScratchColor] = useState("")

  //   const [rewards, setRewards] = useState(
  //     scratchCardStatus === ScratchCardStatusEnum.ALLOTTED
  //       ? {
  //           points,
  //           scratchCardStatus,
  //           rewardImage: scratchCard?.merchant_logo,
  //           title: scratchCard?.currency_name,
  //         }
  //       : null
  //   );

  const [loading, setLoading] = useState(false);

  const coinsColor = {
    NEWCOINS:
      "linear-gradient(90deg, #86678f 0%, #b485d2 31.16%, #d0a9ff 49.39%, #a88cff 64.18%, #7d6c97 100%)",
    DIGIGOLD:
      "linear-gradient(90deg, #867457 0%, #EECF66 31.16%, #FFE487 49.39%, #F7D86A 64.18%, #927C57 100%)",
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "NEUCOIN":
        return "linear-gradient(90deg, #86678f 0%, #b485d2 31.16%, #d0a9ff 49.39%, #a88cff 64.18%, #7d6c97 100%)";
      case "DIGIGOLD":
        return "linear-gradient(90deg, #867457 0%, #EECF66 31.16%, #FFE487 49.39%, #F7D86A 64.18%, #927C57 100%)";
      default:
        return "linear-gradient(90deg, #867457 0%, #EECF66 31.16%, #FFE487 49.39%, #F7D86A 64.18%, #927C57 100%)";
    }
  };

  //   useEffect(() => {
  //     const timer = setTimeout(() => {
  //       setWelcomeScreen(false);
  //     }, 2000);
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }, []);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getCoupon = async () => {
    // setLoading(true);
  };

  const couponClearAction = async () => {
    // setLoading(true);
    // const processScratchCardToken = await refreshToken(token);
    // try {
    //   const response = await apiClient.post(
    //     "/api/process-scratch-card",
    //     { scratchCardId },
    //     {
    //       headers: { Authorization: `Bearer ${processScratchCardToken}` },
    //     }
    //   );
    //   console.log("/api/process-scratch-card ----\n", response);
    //   // response
    //   // rewardValue: 13, scratchCardStatus: 'ALLOTTED'
    //   setScratchCardStatus(response?.data?.scratchCardStatus);
    //   setRewards({
    //     points: response?.data.rewardValue,
    //     scratchCardStatus: response?.data?.scratchCardStatus,
    //     title: title,
    //     background: "green",
    //     footerText: "",
    //     logo: "",
    //     rewardImage: "",
    //   });
    //   setTimeout(() => {
    //     router.push({
    //       pathname: '/rewards',
    //       query: { token}
    //     });
    //   }, 600);
    // } catch (e) {
    //   console.log("error :  ", e);
    // }
  };


  // console.log(scratchCard, "me");
  // useEffect(()=>{
  //   setScratchColor(scratchCardColor)
  // },[scratchCardColor]);

  useEffect(()=>{
setAllData(data)
  },[data])
 
  return (
    <div >
      <DeviceFrameset device="iPhone X" color="black" zoom="55%">
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className={couponPageStyle.container}
      >
        <main className={couponPageStyle.main}>
          {welcomeScreen ? (
            <></>
          ) : (
            // <div className={couponPageStyle.welcomeScreen}>
            //   <div className={couponPageStyle.welcomeScreenImage}>
            //     <img
            //       id="particle"
            //       quality={100}
            //       src={welcome.src}
            //       width={100}
            //       height={200}
            //       alt="loading..."
            //     />
            //   </div>
            //   <p
            //     style={{
            //       background: getColorByStatus(currency_code),
            //       color: "#005CBD",
            //       backgroundClip: "text",
            //       WebkitBackgroundClip: "text",
            //       WebkitTextFillColor: "transparent",
            //     }}
            //   >
            //     Fetching Your Reward
            //   </p>
            // </div>
            <div className={couponPageStyle.contentContainer}>
              <div className={couponPageStyle.topIconsContainer}>
                <div
                  //   onClick={handleDrawerOpen}
                  style={{ alignSelf: "flex-start" }}
                >
                  {/* <img src={infoIcon.src} width={20} height={20} alt="info" /> */}
                </div>
                <div
                  //   onClick={handleBackToRewardsClick}
                  style={{ alignSelf: "flex-start" }}
                >
                  {/* <img
                    src={crossIcon.src}
                    width={15}
                    height={15}
                    alt="close"
                  /> */}
                </div>
              </div>
              <div className={couponPageStyle.couponLogoContainer}>
                {/* {console.log(URL.createObjectURL(data?.createGame?.merchantLogo),"data+")} */}
                <div
                  className={couponPageStyle.brandLogo}
                  //   onClick={handleClick}
                >
                  {AllData?.merchantLogoUrl && (
                    // merchant Logo
                    <img
                      src={AllData?.merchantLogoUrl}
                      style={{ background: "#fff" }}
                      width={90}
                      height={90}
                      alt="logo"
                    />
                  )}
                </div>
              </div>
              <div className={couponPageStyle.scratchText}>
                <Typography marginTop="25px" fontFamily="Proxima Nova A" fontWeight={600} fontSize="24px" lineHeight="20.72px">Scratch your reward</Typography>
              </div>
              {/* <ScratchCardCoupon
                coverImage={scratchCard}
              /> */}
           {  AllData?.scratchCardImageUrl ? <img
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  height: "50%",
                  borderRadius:"12px"
                }}
                src={AllData?.scratchCardImageUrl}
                alt=""
              /> :
              <Box sx={{  
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "50%",
              borderRadius:"12px",
            //   border:"2px solid white",
              backgroundColor:AllData?.scratchCardColor
              }} >
                
              </Box>
              }
            </div>
          )}

          {/* <BottomDrawer openDrawer={openDrawer} handleClose={handleDrawerClose}>
            <OffersDetails
              handleClose={handleDrawerClose}
              isCouponScratched={ScratchCardStatusEnum?.ALLOTTED ?? false}
            />
          </BottomDrawer> */}
        </main>
      </div>
      </DeviceFrameset>
    </div>
  );
};
