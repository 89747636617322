import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Overview from "../assets/Overview.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../Redux/AuthReducer/actionType";
import { ToastContainer, toast } from "react-toastify";

const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isAuth = useSelector((store) => store.AuthReducer.token);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  const {pathname} = useLocation()
  const config = "";
  const login = async (values, onSubmitProps) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/login`, values)
      .then((res) => {
        // console.log(res?.data ,"loginData")
        let allowedRouters = res.data?.user_role_permission?.dashboard_data?.allowed_routes_and_sections;
        let allowedPermissions = res.data?.user_role_permission?.dashboard_data?.allowed_permissions;
        const initialRoute = res.data?.user_role_permission?.dashboard_data?.route;
        const accessToken = res?.data?.jwt ?? "";
        const userName =  res?.data?.name ?? "";
        localStorage.setItem("Auth", JSON.stringify({accessToken,userName}));
        localStorage.setItem("allowedRouters", JSON.stringify({allowedRouters}));
        localStorage.setItem("allowedPermissions", JSON.stringify({allowedPermissions}));
        localStorage.setItem("userId", JSON.stringify({userId:res?.data?.id}));
        localStorage.setItem("makerCheckerPermission", JSON.stringify({permission:res?.data?.permissions}));
        let AuthDetails ={
          token:res?.data?.jwt ?? "",
          userName:res?.data?.name ?? "",
          allowedRoutes:allowedRouters,
          allowedPermissions:allowedPermissions,
          initialRoute:initialRoute,
        }
          dispatch({type:userDetails,payload: AuthDetails})
        navigate("/",  { replace: true });

        onSubmitProps.resetForm();
      }).catch((error)=>{
        console.log(error?.response?.data?.message)
        toast.error(error?.response?.data?.message)
      });
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await login(values, onSubmitProps);
  };
  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth, navigate]);


  //css

  const titleStyle = {
    fontFamily: "ABeeZee",
    fontSize: "42px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const subtitle = {
    fontSize: "20px",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "25px",
    letterSpacing: "0em",
    color: "#000000B2",
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
     
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "#F7F9FC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
             <ToastContainer/>
            <Box
              sx={{
                width: "570px",
                height: "480px",
                backgroundColor: "#fff",
                boxShadow: "0px 0px 16px 0px #0000000A",
                boxSizing: "border-box",
                padding: "30px 40px",
                borderRadius: "16px",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="white"
                gap="5px"
                mt="1.5%"
              >
                <Typography variant="h3" sx={titleStyle}>
                  Gratify
                </Typography>
              </Box>
              <Typography
                variant="h4"
                sx={subtitle}
                textAlign="center"
                m="15px"
              >
                Welcome to gratify admin panel
              </Typography>
              <div className="paragraph" style={{ marginTop: "9%" }}></div>
              <Stack spacing={2}>
                <div className="email-field">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </div>
                <div className="password-field">
                  <div
                    className="forgot-password"
                    style={{
                      width: "100%",
                      textAlign: "right",
                      paddingBottom: "5px",
                      visibility: "hidden",
                    }}
                  >
                    <Typography
                      variant="p"
                      fontFamily="Proxima nova"
                      color="#043A87"
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                      // onClick={()=>navigate("/forgot-password")}
                    >
                      Forgot Password?
                    </Typography>
                  </div>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={
                        Boolean(touched.password) && Boolean(errors.password)
                      }
                      helperText={touched.password && errors.password}
                    />
                  </FormControl>
                </div>
              </Stack>

              <div className="login-btn" style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    padding: "13px, 142px, 13px, 142px",
                    backgroundColor: "black",
                    height: "48px",
                    borderRadius: "10px",
                    mt: "38px",
                    "&:hover": {
                      backgroundColor: "#191919",
                    },
                  }}
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Login;
