import React, { useEffect, useState } from "react";
import ViewLimits from "./ViewLimits";
import { Box, Button, Typography } from "@mui/material";
import CreateLimit from "./CreateLimit";
import TableTotalCount from "../components/TableTotalCount";
import { MdModeEditOutline } from "react-icons/md";
import usePermission from "../utils/permissions";
import axios from "axios";
import { useSelector } from "react-redux";
import plus from "../assets/Plus.svg";
import { ToastContainer } from "react-toastify";
import Loader from "../components/Loader";
const initialData = {
  category: {},
  subCategory: {},
  product: {},
  merchant: {},
  type: "",
  categoryFlag: false,
  subCategoryFlag: false,
  productFlag: false,
  merchantFlag: false,
  userLevelFlag: false,
  productLevelFlag: false,
  subCategoryOption: [],
  productOptions: [],
  // hourly: "",
  userDaily: "",
  userWeekly: "",
  userMonthly: "",
  userTotal: "",
  productDaily: "",
  productWeekly: "",
  productMonthly: "",
  productTotal: "",
  TabType: "",
  edit: {
    entity_id: "",
    entity_type: "",
    oldLimit: [],
  },
};

const dummyData = [
  {
    id: 1,
    organization: "",
    merchant: "",
    category: "Payments",
    subCategory: "CCR",
    product: "BNPL_CC",
    daily: 100,
    weekly: 700,
    monthly: 3000,
    total: 50000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 2,
    category: "Payments",
    subCategory: "LENDING",
    product: "BNPL",
    daily: 50,
    weekly: 350,
    monthly: 1500,
    total: 25000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 3,
    category: "Financial Services",
    subCategory: "Digital Gold",
    product: "Tata Tea",
    daily: 200,
    weekly: 1400,
    monthly: 6000,
    total: 100000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 4,
    category: "CCR",
    subCategory: "RCC",
    product: "RCC",
    daily: 150,
    weekly: 1050,
    monthly: 4500,
    total: 75000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 5,
    category: "CCR",
    subCategory: "Upi",
    product: "Tata Tea",
    daily: 80,
    weekly: 560,
    monthly: 2400,
    total: 40000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 6,
    category: "LENDING",
    subCategory: "Upi",
    product: "RCC",
    daily: 70,
    weekly: 490,
    monthly: 2100,
    total: 35000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 7,
    category: "Digital Gold",
    subCategory: "CCR",
    product: "BNPL",
    daily: 120,
    weekly: 840,
    monthly: 3600,
    total: 60000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 8,
    category: "Upi",
    subCategory: "LENDING",
    product: "BNPL_CC",
    daily: 90,
    weekly: 630,
    monthly: 2700,
    total: 45000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 9,
    category: "Upi",
    subCategory: "Digital Gold",
    product: "BNPL",
    daily: 300,
    weekly: 2100,
    monthly: 9000,
    total: 150000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
  {
    id: 10,
    category: "Upi",
    subCategory: "CCR",
    product: "Tata Tea",
    daily: 250,
    weekly: 1750,
    monthly: 7500,
    total: 125000,
    action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
  },
];

export default function Limits() {
  const [limits, setLimits] = useState({
    data: [],
    paginationModel: {
      pageSize: 10,
      page: 0,
    },
    totalCount: 0,
  });
  const [loading, setLoading] = useState(false);
  // to handle Tabs
  const [totalTab, setTotalTab] = useState(1);
  // to track active tab
  const [activeTab, setActiveTab] = useState(0);
  // to map all the tabs
  const [tabArray, setTabArray] = useState([]);
  // to keep id unique
  const [lastId, setLastId] = useState(1);
  // to store the data of all tab
  const [tabData, setTabData] = useState([initialData]);

  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const permission = usePermission();
  console.log(permission, "permission");

  const handleCreateLimit = () => {
    // setTotalTab((prev) => prev + 1);
    setTabData((prev) => {
      return [...prev, { ...initialData, TabType: "Create Limit" }];
    });
  };

  const handleEditLimit = (row) => {
    // setTotalTab((prev) => prev + 1);
    // set all the data here to preFill all fields
    console.log(row, "Param_row");
    if (permission?.UPDATE) {
      setTabData((prev) => {
        return [
          ...prev,
          {
            ...initialData,
            TabType: "Edit Limit",
            edit: {
              entity_id: row?.entity_id,
              entity_type: row?.entity_type,
            },
          },
        ];
      });
    }
  };

  const handleChangeTab = (i) => {
    setActiveTab(i);
  };

  const handleDeleteTab = (e, index) => {
    e.stopPropagation();
    let filteredTab = tabArray.filter((e, i) => index !== i);
    setTabArray(filteredTab);
    setTotalTab(filteredTab.length);
    let filtered = tabData.filter((e, i) => i !== index);
    setTabData(filtered);
  };

  const handleCancel = (e, index) => {
    let filteredTab = tabArray.filter((e, i) => index !== i);
    setTabArray(filteredTab);
    setTotalTab(filteredTab.length);
    let filtered = tabData.filter((e, i) => i !== index);
    setTabData(filtered);
  };

  const getLimitsTableData = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      page: limits?.paginationModel.page + 1,
      pageSize: limits?.paginationModel.pageSize,
      // ...(search && { search }), // Include search parameter if it exists
    };
    axios
      .post(`${BaseUrl}/api/limits/get-limits`, body, config)
      .then((res) => {
        const tableData = res.data?.limitsData?.map((e, index) => {
          const product = e?.limits?.find(
            (item) => item.limit_level === "product"
          );
          const user = e?.limits?.find((item) => item.limit_level === "user");
          const limit =
            product?.daily_limit ||
            product?.weekly_limit ||
            product?.monthly_limit ||
            product?.overall_limit
              ? product
              : user;

          // const limit = product || user ;

          return {
            id: index + 1,
            entity_id: e?.entity_id,
            entity_type: e?.entity_type,
            organization: e.history?.organization?.name || null,
            merchant: e.history?.merchant?.name || null,
            category: e.history?.category?.name || null,
            subCategory: e.history?.subcategory?.name || null,
            product: e.history?.product?.name || null,
            daily: limit?.daily_limit || null,
            weekly: limit?.weekly_limit || null,
            monthly: limit?.monthly_limit || null,
            total: limit?.overall_limit || null,
            action: <MdModeEditOutline style={{ fontSize: "24px" }} />,
          };
        });

        setLimits({
          ...limits,
          data: tableData,
          totalCount: res.data?.limitsCount,
        });
        console.log(res.data, "limit");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setActiveTab(tabData.length - 1);
  }, [tabData.length]);

  useEffect(() => {
    if (activeTab === 0) {
      getLimitsTableData();
    }
  }, [
    limits?.paginationModel?.page,
    limits?.paginationModel?.pageSize,
    activeTab,
  ]);

  // if (loading) {
  //   return <Loader />;
  // }
  console.log(tabData);

  return (
    <Box mx="30px">
      <ToastContainer />
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop="20px"
        marginBottom="20px"
      >
        {/* <Button
          style={{
            backgroundColor: permission?.CREATE ? "black" : "gray",
            height: "43px",
            // width: "120px",
            padding: "14px 20px 14px 20px",
            borderRadius: "5px",
            color: "#FFF",
            fontFamily: "ABeeZee",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
            visibility: activeTab === 0 ? "visible" : "hidden",
          }}
          disabled={!permission?.CREATE}
          onClick={handleCreateLimit}
        >
          CREATE LIMIT
        </Button> */}
        <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              borderRadius: "6px",
              marginLeft: "12px",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#003D86",
              },
              visibility: activeTab === 0 ? "visible" : "hidden",
            }}
            disabled={!permission?.CREATE}
            onClick={handleCreateLimit}
          >
            <img style={{ height: "16px", width: "16px" }} src={plus} alt="" />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "12px",
                lineHeight: "14.4px",
                fontWeight: 500,
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
            >
              Create Limit
            </Typography>
          </Button>
      </Box>
      <Box display="flex" gap="5px">
        {tabData.length > 0 &&
          tabData.map((e, i) => (
            <TableTotalCount
              key={i}
              handleChangeTab={() => handleChangeTab(i)}
              text={i === 0 ? "All limits" : e?.TabType}
              index={i}
              count={tabData.length}
              deleteTab={(e) => handleDeleteTab(e, i)}
              activeTab={activeTab}
              totalCount={e?.TabType === "" ? limits?.totalCount : null}
            />
          ))}
      </Box>
      {/* <TableTotalCount totalCount={10} text={"All limits"} /> */}
      <Box>
        {activeTab === 0 ? (
          <ViewLimits
            limits={limits}
            setLimits={setLimits}
            handleEditLimit={handleEditLimit}
            handleCancel={handleCancel}
            updateStatus={permission?.UPDATE}
            loading={loading}
          />
        ) : (
          <CreateLimit
            tabData={tabData}
            setTabData={setTabData}
            activeTab={activeTab}
            handleCancel={handleCancel}
          />
        )}
      </Box>
    </Box>
  );
}
