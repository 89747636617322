import {
  DummyUser,
  getLimitOptions,
  scrollFlagTrigger,
  userDetails,
  userLogout,
} from "./actionType";
const data = localStorage.getItem("Auth");
const AuthDetails = data ? JSON.parse(data) : {};
const allowed =
  JSON.parse(localStorage.getItem("allowedRouters"))?.allowedRouters || [];
const allowedPermissions =
  JSON.parse(localStorage.getItem("allowedPermissions"))?.allowedPermissions ||
  {};

const initialState = {
  token: AuthDetails.accessToken,
  userName: AuthDetails.userName,
  allowedRoutes: allowed,
  allowedPermissions: allowedPermissions,
  limitOption: {},
  scrollFlag: false,
  initialRoute: "",
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case userDetails:
      return {
        ...state,
        userName: payload.userName,
        token: payload.token,
        allowedRoutes: payload.allowedRoutes,
        allowedPermissions: payload.allowedPermissions,
        initialRoute: payload?.initialRoute,
      };
    case userLogout:
      return { ...state, userName: "", token: "" };
    case getLimitOptions:
      return { ...state, limitOption: payload };
    case scrollFlagTrigger:
      return { ...state, scrollFlag: payload };
    default:
      return state;
  }
};
