import React, { useRef, memo, useMemo } from "react";
import JoditEditor from "jodit-react";
import { Box } from "@mui/material";

const RichEditor = ({
  placeholder = "",
  onChange,
  value,
  errorMessage,
  editBoolean,
  isDisable,
}) => {
  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      readonly: isDisable,
      placeholder: placeholder || "",
      height: 300,
      disablePlugins: ["font", "speech-recognize", "add-new-line"],
      editorCssClass: editBoolean ? "orange-outline" : "",
      buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "link",
        "|",
        "align",
        "|",
        "undo",
        "redo",
      ],
      controls: {
        ol: {
          data: {
            number: "1, 2, 3...",
          },
          list: {
            number: "Number",
          },
          command: "insertOrderedList",
          tags: ["ol"],
          tooltip: "Ordered list",
        },
        ul: {
          command: "insertUnorderedList",
          tags: ["ul"],
          tooltip: "Unordered list",
        },
      },
    };
  }, [placeholder, editBoolean, isDisable]);

  return useMemo(
    () => (
      <Box
        sx={{
          mt: "10px",
          width: "100%",
          textAlign: "start",
          mb: "0px",
          "& .jodit-container": {
            border: errorMessage
              ? "1px solid #d40e10"
              : editBoolean
              ? "1px solid orange"
              : "",
            minHeight: "50px !important",
            maxHeight: "200px !important",
            borderRadius: "6px !important",
            overflow: "hidden",
          },
          "& .jodit-wysiwyg": {
            minHeight: "50px !important",
            maxHeight: "100px !important",
          },
          "& .jodit-workplace": {
            minHeight: "50px !important",
            maxHeight: "100px !important",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#1D1F2C",
            pointerEvents: isDisable ? "none" : "auto",
          },
          // Styles for unordered lists
          "& ul": {
            listStyle: "disc",
            marginLeft: "20px",
            "& li": {
              marginBottom: "5px",
            },
          },
          // Styles for nested unordered lists
          "& ul ul": {
            listStyle: "circle",
            marginLeft: "20px",
          },
          "& ul ul ul": {
            listStyle: "square",
            marginLeft: "20px",
          },
          // Styles for ordered lists
          "& ol": {
            listStyle: "decimal",
            marginLeft: "20px",
            "& li": {
              marginBottom: "5px",
            },
          },
          // Styles for nested ordered lists
          "& ol ol": {
            listStyle: "lower-alpha",
            marginLeft: "20px",
          },
          "& ol ol ol": {
            listStyle: "lower-roman",
            marginLeft: "20px",
          },
        }}
      >
        <JoditEditor
          ref={editor}
          value={value}
          config={config}
          onChange={isDisable ? undefined : onChange}
        />
        {errorMessage && (
          <Box
            sx={{
              color: "error.main",
              fontSize: "14px",
              mt: 0.5,
              textAlign: "start",
              paddingLeft: "10px",
            }}
          >
            {errorMessage}
          </Box>
        )}
      </Box>
    ),
    [config, value, onChange, errorMessage, editBoolean, isDisable]
  );
};

export default memo(RichEditor);

// import React, { useRef, memo, useMemo } from "react";
// import JoditEditor from "jodit-react";
// import { Box } from "@mui/material";

// const RichEditor = ({
//   placeholder = "",
//   onChange,
//   value,
//   errorMessage,
//   editBoolean,
//   isDisable,
// }) => {
//   const editor = useRef(null);

//   const config = useMemo(() => {
//     return {
//       readonly: isDisable,
//       placeholder: placeholder || "",
//       height: 300,
//       buttons: [
//         'source',
//         '|',
//         'bold',
//         'italic',
//         'underline',
//         '|',
//         'ul',
//         'ol', // Added ordered list button
//         '|',
//         'outdent',
//         'indent',
//         '|',
//         'font',
//         'fontsize',
//         'brush',
//         'paragraph',
//         '|',
//         'align',
//         '|',
//         'undo',
//         'redo',
//         '|',
//         'hr',
//         'eraser',
//         'fullsize',
//       ],
//       buttonsXS: [
//         'bold',
//         'italic',
//         'underline',
//         '|',
//         'ul',
//         'ol', // Added ordered list button for small screens
//         '|',
//         'align',
//       ],
//       disablePlugins: ["speech-recognize", "add-new-line"],
//       editorCssClass: editBoolean ? "orange-outline" : "",
//       controls: {
//         ol: {
//           list: {
//             default: '1',
//             circle: 'A',
//             disc: 'a',
//             square: 'I',
//           },
//         },
//       },
//     };
//   }, [placeholder, editBoolean, isDisable]);

//   return useMemo(
//     () => (
//       <Box
//         sx={{
//           mt: "10px",
//           width: "100%",
//           textAlign: "start",
//           mb: "0px",
//           "& .jodit-container": {
//             border: errorMessage
//               ? "1px solid #d40e10"
//               : editBoolean
//               ? "1px solid orange"
//               : "",
//             minHeight: "50px !important",
//             maxHeight: "200px !important",
//             borderRadius: "6px !important",
//             overflow: "hidden",
//           },
//           "& .jodit-wysiwyg": {
//             minHeight: "50px !important",
//             maxHeight: "150px !important",
//           },
//           "& .jodit-toolbar-button__icon": {
//             width: "14px",
//             height: "14px",
//           },
//           "& .jodit-toolbar-button": {
//             padding: "4px",
//           },
//           "& .jodit-workplace": {
//             minHeight: "50px !important",
//             maxHeight: "150px !important",
//             fontFamily: "Lato",
//             fontWeight: 400,
//             fontSize: "12px",
//             lineHeight: "16px",
//             color: "#1D1F2C",
//             pointerEvents: isDisable ? "none" : "auto",
//           },
//           // Styles for numbered lists
//           "& ol": {
//             listStyleType: "decimal",
//             marginLeft: "20px",
//             "& li": {
//               marginBottom: "4px",
//             },
//           },
//           // Nested list styles
//           "& ol ol": {
//             listStyleType: "lower-alpha",
//             marginLeft: "20px",
//           },
//           "& ol ol ol": {
//             listStyleType: "lower-roman",
//             marginLeft: "20px",
//           },
//         }}
//       >
//         <JoditEditor
//           ref={editor}
//           value={value}
//           config={config}
//           onChange={isDisable ? undefined : onChange}
//         />
//         {errorMessage && (
//           <Box
//             sx={{
//               mt: 1,
//               fontSize: "12px",
//               color: "#d74848",
//               textAlign: "left",
//               ml: "15px",
//             }}
//           >
//             {errorMessage}
//           </Box>
//         )}
//       </Box>
//     ),
//     [config, value, onChange, errorMessage, editBoolean, isDisable]
//   );
// };

// export default memo(RichEditor);
