import React, { useRef, useState } from "react";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { fileToBase64Image } from "../utils/fileToBase64";
import tickImage from "../assets/whiteTick.png";

const SmallImageUpload = ({
  onChange,
  errorMessage,
  value,
  handleImage,
  image,
  maxSize = "2MB",
  ratio = "1:1",
  loader = false,
  disable = false,
  borderValue,
  onClick,
}) => {
  const [aspectRatioError, setAspectRatioError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const ImageInputRef = useRef(null);

  const handleSelectImageClick = () => {
    ImageInputRef?.current?.click();
  };

  const handleDeleteImage = () => {
    handleImage(null);
    if (ImageInputRef.current) {
      ImageInputRef.current.value = "";
    }
  };

  const checkAspectRatio = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatioValue = img.width / img.height;
        const [targetWidth, targetHeight] = ratio.split(":").map(Number);
        const targetRatio = targetWidth / targetHeight;
        const margin = 0.01;
        if (Math.abs(aspectRatioValue - targetRatio) < margin) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const getMaxSizeInBytes = (size) => {
    const sizeUnit = size.slice(-2).toUpperCase();
    const sizeValue = parseFloat(size);
    switch (sizeUnit) {
      case "KB":
        return sizeValue * 1024;
      case "MB":
        return sizeValue * 1024 * 1024;
      case "GB":
        return sizeValue * 1024 * 1024 * 1024;
      default:
        return sizeValue; // Assuming size is in bytes if no unit is specified
    }
  };

  async function readImage(file) {
    if (!file) return;

    setAspectRatioError("");
    setSizeError("");

    const maxSizeInBytes = getMaxSizeInBytes(maxSize);

    if (file.size > maxSizeInBytes) {
      setSizeError(`Image size must be less than ${maxSize}`);
      return;
    }

    const isAspectRatioValid = await checkAspectRatio(file);
    if (!isAspectRatioValid) {
      setAspectRatioError(`Image aspect ratio must be ${ratio}`);
      return;
    }

    setSizeError("");
    setAspectRatioError("");
    onChange(file);
    fileToBase64Image(file, handleImage);
  }

  return (
    <>
      <Box sx={{ width: "100%", mt: "10px" }}>
        <input
          type="file"
          accept="image/png, image/jpeg"
          ref={ImageInputRef}
          onChange={(event) => {
            if (event?.target.files) {
              readImage(event.target.files[0]);
            }
          }}
          disabled={disable}
          style={{ display: "none" }}
        />

        {image || (value && typeof value === "string") ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              onClick={onClick}
              sx={{
                backgroundColor: "rgba(74, 203, 95, 0.04);",
                border:
                  borderValue == value ? "3px solid black" : "3px dashed transparent",
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "64px",
                height: "64px",
                overflow: "hidden",
                position: "relative", // Add this
              }}
            >
              {loader ? (
                <CircularProgress color="inherit" size={40} />
              ) : (
                <>
                  <img
                    src={
                      value && typeof value === "string"
                        ? value
                        // `${value}?date=${new Date().getTime()}`
                        : `data:image/jpeg;base64,${image}`
                    }
                    alt="uploaded img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  {borderValue == value && (
                    <img
                      style={{
                        position: "absolute",
                        top: "5px",
                        left: "5px",
                        zIndex: 100,
                        width: "20px",
                        height: "20px",
                      }}
                      src={tickImage} // You need to import or define tickImage
                      alt="Selected"
                    />
                  )}
                </>
              )}
            </Box>
            <Box sx={{ pl: "15px" }}>
              <Box
                sx={{
                  mb: "12px",
                  fontSize: "14px",
                  color: "#385B8B",
                  width: "150px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "Mulish",
                }}
              >
                {typeof value !== "string" && value?.name}
              </Box>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "left",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={handleSelectImageClick}
              >
                <IconButton size="small" sx={{ color: "#4ACB5F" }}>
                  <FileUploadOutlinedIcon fontSize="small" />
                </IconButton>
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#4ACB5F",
                    fontFamily: "Mulish",
                  }}
                >
                  Change
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "left",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={handleDeleteImage}
              >
                <IconButton size="small" sx={{ color: "#F24F4F" }}>
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#F24F4F",
                    fontFamily: "Mulish",
                  }}
                >
                  Delete
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              border: `1px dashed ${
                aspectRatioError || sizeError || errorMessage
                  ? "red"
                  : "var(--text-30, rgba(0, 0, 0, 0.30))"
              }`,
              borderRadius: "6px",
              cursor: "pointer",
              width: "100px",
              height: "64px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: disable ? "#1e1a1a76" : "rgba(0, 0, 0, 0.04)",
            }}
            onClick={handleSelectImageClick}
          >
            <Box sx={{ display: "flex", alignItems: "center",justifyContent:"center" }}>
              <IconButton>
                <FileUploadOutlinedIcon sx={{ color: "#272728" }} />
              </IconButton>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#272728",
                  fontFamily: "Mulish",
                  fontSize: "12px",
                  lineHeight: "23px",
                  fontWeight: 600,
                }}
              >
                Upload
              </Box>
            </Box>
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15.06px",
                color: "#65758C",
              }}
            >
              Upload .png, file.
            </Typography>
          </Box>
        )}
      </Box>
      {(aspectRatioError || sizeError || errorMessage) && (
        <Box
          sx={{
            mt: 1,
            fontSize: "14px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px",
          }}
        >
          {aspectRatioError || sizeError || errorMessage}
        </Box>
      )}
    </>
  );
};

export default SmallImageUpload;
