export function getGameDataName(gameTypeId) {
    switch (gameTypeId) {
      case 6:
        return "spinTheWheelData";
      case 7:
        return "slotMachineData";
      case 9:
        return "scratchCardData";
      case 8:
        return "triviaData";
      default:
        return "";
    }
  }
  