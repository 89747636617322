import React, { useEffect, useState } from "react";
import OfferStepper from "./OfferStepper";
import { useParams } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { initialOfferEditValue } from "../../Redux/OfferReducer/actionType";
import { convertRewardDetails, fetchCsvFileFromUrl, handleSubmit } from "../../utils/CreateGame_PreData";

const initialDataValue = () => {
  return {
    basicOffers: {
      category: "",
      subCategory: "",
      product: "",
    },
    offerDetails: {
      merchant: "",
      title: "",
      description: "",
      startDate: null,
      endDate: null,
      offerInformationForPWABefore: "",
      offerInformationForPWAAfter: "",
      TAndC: "",
      coverPhoto: "",
      coverPhotoId: "",
      offerInitiative: "",
      gameType: "",
      rewardCurrency: "",
    },
    createGame: {
      merchantLogo: "",
      merchantLogoId: "",
      scratchCardColor: "",
      offerTitleColor: "",
      scratchCardImage: "",
      scratchCardImageId: "",
      scratchCardImageUrl: "",
      merchantLogoUrl: "",
    },
    setOfferRules: {
      linkToRule: "",
      linkToCohort: "",
    },
    offerLimit: {
      offerLevelLimits: {
        fairUsageOfRewardIssuance: {
          hourly: "",
          daily: "",
          weekly: "",
          monthly: "",
        },
        // fairUsageOfRewardRedemption: {
        //   hourly: "",
        //   daily: "",
        //   weekly: "",
        //   monthly: "",
        // },
      },
      userLevelLimits: {
        fairUsageOfRewardIssuance: {
          hourly: "",
          daily: "",
          weekly: "",
          monthly: "",
          totalAmount: "",
        },
        fairUsageOfRewardRedemption: {
          hourly: "",
          daily: "",
          weekly: "",
          monthly: "",
          totalAmount: "",
        },
      },
    },
  };
};

export default function EditOffer() {
  const [initialData, setInitialData] = useState(initialDataValue());
  const dispatch = useDispatch();

  //   console.log(initialDataValue, "initialDataValue");
  const { id } = useParams();
  const token = useSelector((store) => store.AuthReducer.token);
  const data = useSelector((store) => store.OfferReducer.initialOfferEditValue);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const cohortsData = useSelector((store) => store.OfferReducer.cohortsOption);
  const ruleData = useSelector((store) => store.OfferReducer.ruleOption);
  // const rewards = useSelector(
  //   (store) => store?.OfferReducer?.allRewards
  // )?.rewardTypes?.find((item) => item.name === rewardTypeName)?.reward_details;

  const rewards = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes;
  
  const gameData = useSelector((store) => store?.OfferReducer?.allGames);

  // const ruleOption = ruleData.map((element)=>{
  //   return  {label:element.name,value:element.id}
  // })
  // const cohortsOption = cohortsData.map((element)=>{
  //   return {label:element.name,value:element.id}
  // })
  //  console.log(cohortsOption, "cohortsData");
  const getOfferDataByID = () => {
    axios
      .get(`${BaseUrl}/api/admin/offer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const offerLimit = res.data?.values?.find(
          (e) => e.entity_type == "OFFER"
        )?.limit_type;
        const userLimit = res.data?.values?.find(
          (e) => e.entity_type == "USER"
        )?.limit_type;
        // const userRedeem = res.data?.values?.find((e)=>e.entityType=="MAXIMUM")?.limitType;

        console.log(res.data, "response");
        dispatch({ type: initialOfferEditValue, payload: res.data });
        setInitialData((prev) => ({
          ...prev,
          basicOffers: {
            ...prev.basicOffers,
            category: res.data?.categoryId ?? "",
            subCategory: res.data?.subcategoryId ?? "",
            product: res.data?.productId ?? "",
          },
          offerDetails: {
            merchant: res.data?.merchantId ?? "",
            title: res.data?.name ?? "",
            description: res.data?.description ?? "",
            startDate: res.data?.startsAt ?? "",
            endDate: res.data?.endsAt ?? "",
            programId: res.data?.programId ?? "",
            lockingPeriod: res.data?.lockingPeriod ?? "",
            expiryPeriod: res.data?.expiryPeriod ?? "",
            offerInformationForPWABefore:
              res.data?.informationBeforeRedemption ?? "",
            offerInformationForPWAAfter:
              res.data?.informationAfterRedemption ?? "",
            TAndC: res.data?.termsAndConditions ?? "",
            coverPhoto: res.data?.coverImageUrl ?? "",
            coverImageUrl: res.data?.coverImageUrl ?? "",
            coverPhotoId: res.data?.coverImageId ?? "",
            offerInitiative: res.data?.initiativeId ?? "",
            gameType: res.data?.game?.gameId ?? "",
            rewardCurrency: res.data?.currencyCode ?? "",
            rewardCurrencyId: res.data?.currencyId ?? "",
            csvUrl: res.data?.csvUrl ?? "",
            // csvUrl : "https://gratifystorage01.blob.core.windows.net/gratify-dev/offerAnalytics_Mon Jun 03 2024 17_31_51 GMT+0530 (India Standard Time) (2).csv_e7ff9b"
          },
          createGame: {
            merchantLogo: res.data?.game?.merchantLogoUrl ?? "",
            merchantLogoUrl: res.data?.game?.merchantLogoUrl ?? "",
            merchantLogoId: res.data?.game?.merchantLogoId ?? "",
            scratchCardColor: res.data?.game?.scratchCardColour ?? "",
            offerTitleColor: res.data?.game?.offerTitleColour ?? "",
            scratchCardImage: res.data?.game?.scratchCardImageUrl ?? "",
            scratchCardImageId: res.data?.game?.scratchCardImageId ?? "",
            scratchCardImageUrl: res.data?.game?.scratchCardImageUrl ?? "",
          },
          setOfferRules: {
            linkToRule: res.data?.earnRuleSetId ?? {},
            linkToCohort: res.data?.collectionsIds ?? [],
          },
          offerLimit: {
            offerLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: offerLimit?.hourly,
                daily: offerLimit?.daily,
                weekly: offerLimit?.weekly,
                monthly: offerLimit?.monthly,
                total: offerLimit?.overall,
              },
              // fairUsageOfRewardRedemption: {
              //   hourly: "",
              //   daily: "",
              //   weekly: "",
              //   monthly: "",
              // },
            },
            userLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: userLimit?.hourly,
                daily: userLimit?.daily,
                weekly: userLimit?.weekly,
                monthly: userLimit?.monthly,
                totalAmount: userLimit?.overall,
              },
              fairUsageOfRewardRedemption: {
                daily: userLimit?.daily_issuance,
                weekly: userLimit?.weekly_issuance,
                monthly: userLimit?.monthly_issuance,
                totalAmount: userLimit?.overall_issuance,
              },
            },
          },
        }));
        // setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const getOfferDataByIDv2 = async() => {

    axios
      .get(`${BaseUrl}/api/admin/offer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const offerLimit = res.data?.values?.find(
          (e) => e.entity_type == "OFFER"
        )?.limit_type;
        const userLimit = res.data?.values?.find(
          (e) => e.entity_type == "USER"
        )?.limit_type;
        // const userRedeem = res.data?.values?.find((e)=>e.entityType=="MAXIMUM")?.limitType;
        const gameDetails = gameData?.find(
          (item) => item?.id === res?.data?.game?.game_type_id
        );
      
        // console.log(res.data,"response");
        dispatch({ type: initialOfferEditValue, payload: res.data });
        setInitialData((prev) => ({
          ...prev,
          basicOffers: {
            ...prev.basicOffers,
            category: res.data?.categoryId ?? "",
            subCategory: res.data?.subcategoryId ?? "",
            product: res.data?.productId ?? "",
          },
          offerDetails: {
            merchant: res.data?.merchantId ?? "",
            title: res.data?.name ?? "",
            description: res.data?.description ?? "",
            startDate: res.data?.startsAt ?? "",
            endDate: res.data?.endsAt ?? "",
            programId: res.data?.programId ?? "",
            lockingPeriod: res.data?.lockingPeriod ?? "",
            expiryPeriod: res.data?.expiryPeriod ?? "",
            offerInformationForPWABefore:
              res.data?.informationBeforeRedemption ?? "",
            offerInformationForPWAAfter:
              res.data?.informationAfterRedemption ?? "",
            TAndC: res.data?.termsAndConditions ?? "",
            coverPhoto: res.data?.coverImageUrl ?? "",
            coverImageUrl: res.data?.coverImageUrl ?? "",
            coverPhotoId: res.data?.coverImageId ?? "",
            offerInitiative: res.data?.initiativeId ?? "",
            gameType: res.data?.game?.gameId ?? "",
            rewardCurrency: res.data?.currencyCode ?? "",
            rewardCurrencyId: res.data?.currencyId ?? "",
            csvUrl: res.data?.csvUrl ?? "",
            // csvUrl : "https://gratifystorage01.blob.core.windows.net/gratify-dev/offerAnalytics_Mon Jun 03 2024 17_31_51 GMT+0530 (India Standard Time) (2).csv_e7ff9b"
          },
          createGame: {
            // merchantLogo: "",
            // merchantLogoId: "",
            // scratchCardColor: "",
            // offerTitleColor: "",
            // scratchCardImage: "",
            // scratchCardImageId: "",
            // scratchCardImageUrl: "",
            // merchantLogoUrl: "",
            gameName: gameDetails?.name,
            game_type_id: res?.data?.game?.game_type_id,
            game: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.rewards?.map((item) => {
                      const rewardName = rewards?.find(
                        (e) => e?.id === item?.reward_type_id
                      )?.name;

                      const rewardType = rewards
                        ?.find((e) => e?.id === item?.reward_type_id)
                        ?.reward_details?.find(
                          (ele) => ele?.backend_label === "reward_type"
                        )
                        ?.options.find((ele) => ele?.name === rewardName);
                      const limitOptions = rewards.find(
                        (reward) => reward?.name === rewardType?.name
                      )?.limitOptions;
                      const rewardLimits = [
                        {
                          entity_type: "reward_value",
                          daily_limit: 4,
                          weekly_limit: 5,
                          limit_level: "product",
                        },
                      ];
                      const csvFile = fetchCsvFileFromUrl("https://gratifystorage01.blob.core.windows.net/gratify-dev/330d31_SampleVouchersSheet1.csv").then((csv)=>{
                        console.log("csv file fetched");
                      })
                      console.log(limitOptions, "limitOptions");
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: false,
                        limit_required: true,
                        rewardLevel_required: true,
                        rewardType_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewardType,
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                        ...limitOptions,
                        voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "" ,
                        csvUrl: item?.reward_details?.csvUrl ?? "" ,
                        csvFile: csvFile ,
                        rewardLevel: limitOptions?.limitLevelOptions?.find((e)=>e.id === item?.rewardLimits?.[0]?.limit_level),
                        rewardType:limitOptions?.limitTypeOptions?.find((e)=>e.id === item?.rewardLimits?.[0]?.entity_type),
                        rewardLimit:limitOptions?.limitOptions?.filter((limit) => item?.rewardLimits?.[0]?.hasOwnProperty(limit?.id))
                        .map((limit) => ({
                          time_required: true,
                          amount_required: true,
                          type: {
                            id: limit.id,
                            name: limit.name,
                          },
                          amount: item?.rewardLimits[0][limit.id],
                        }))
                      };
                    })
                  : [],
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              triviaData_step1: {
                triviaType: "",
                questions: [
                  {
                    title: "",
                    addMedia: false,
                    images: [],
                    options: [{ optionTitle: "", isSelected: true }],
                  },
                ],
              },
            },
            beforePlay: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
            },
            afterPlay: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
            },
            whenPlay: {
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.when_play || {}
                  : {},
            },
            addNewRewards: {
              scratchCardData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              spinTheWheelData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              slotMachineData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              triviaData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
            },
          },
          setOfferRules: {
            linkToRule: res.data?.earnRuleSetId ?? {},
            linkToCohort: res.data?.collectionsIds ?? [],
          },
          offerLimit: {
            offerLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: offerLimit?.hourly,
                daily: offerLimit?.daily,
                weekly: offerLimit?.weekly,
                monthly: offerLimit?.monthly,
                total: offerLimit?.overall,
              },
              // fairUsageOfRewardRedemption: {
              //   hourly: "",
              //   daily: "",
              //   weekly: "",
              //   monthly: "",
              // },
            },
            userLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: userLimit?.hourly,
                daily: userLimit?.daily,
                weekly: userLimit?.weekly,
                monthly: userLimit?.monthly,
                totalAmount: userLimit?.overall,
              },
              fairUsageOfRewardRedemption: {
                daily: userLimit?.daily_issuance,
                weekly: userLimit?.weekly_issuance,
                monthly: userLimit?.monthly_issuance,
                totalAmount: userLimit?.overall_issuance,
              },
            },
          },
        }));
        // setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOfferDataByIDv3 = async () => { 
    try {
      const res = await axios.get(`${BaseUrl}/api/admin/offer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const offerLimit = res.data?.values?.find((e) => e.entity_type === "OFFER")?.limit_type;
      const userLimit = res.data?.values?.find((e) => e.entity_type === "USER")?.limit_type;
      const gameDetails = gameData?.find((item) => item?.id === res?.data?.game?.game_type_id);
  
      const updatedScratchCardData = await Promise.all(
        gameDetails?.name === "Scratch Card"
          ? res?.data?.game?.rewards?.map(async (item) => {
              const rewardName = rewards?.find((e) => e?.id === item?.reward_type_id)?.name;
              const rewardType = rewards
                ?.find((e) => e?.id === item?.reward_type_id)
                ?.reward_details?.find((ele) => ele?.backend_label === "reward_type")
                ?.options.find((ele) => ele?.name === rewardName);
              const limitOptions = rewards.find((reward) => reward?.name === rewardType?.name)?.limitOptions;
  
              const csvFile = await fetchCsvFileFromUrl("https://gratifystorage01.blob.core.windows.net/gratify-dev/330d31_SampleVouchersSheet1.csv");
  
              return {
                title_required: true,
                probability_required: true,
                limit: false,
                limit_required: true,
                rewardLevel_required: true,
                rewardType_required: true,
                title: item?.title,
                probability: item?.win_probability,
                reward_type: rewardType,
                cashback_type: {
                  ...rewards
                    ?.find((reward) => reward.id === item?.reward_type_id)
                    ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                    ?.options?.map((option) => ({
                      id: option?.value,
                      name: option?.label,
                      fields: option?.fields,
                    }))
                    ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
                },
                ...convertRewardDetails(item?.reward_details),
                ...limitOptions,
                voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "",
                csvUrl: item?.reward_details?.csvUrl ?? "",
                csvFile, // Include the fetched CSV file
                rewardLevel: limitOptions?.limitLevelOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.limit_level),
                rewardType: limitOptions?.limitTypeOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.entity_type),
                rewardLimit: limitOptions?.limitOptions
                  ?.filter((limit) => item?.rewardLimits?.[0]?.hasOwnProperty(limit?.id))
                  .map((limit) => ({
                    time_required: true,
                    amount_required: true,
                    type: {
                      id: limit.id,
                      name: limit.name,
                    },
                    amount: item?.rewardLimits[0][limit.id],
                  })),
              };
            })
          : []
      );
  
      // Now, integrate the resolved data into the state update
      dispatch({ type: initialOfferEditValue, payload: res.data });
      setInitialData((prev) => ({
        ...prev,
        basicOffers: {
          ...prev.basicOffers,
          category: res.data?.categoryId ?? "",
          subCategory: res.data?.subcategoryId ?? "",
          product: res.data?.productId ?? "",
        },
        offerDetails: {
          merchant: res.data?.merchantId ?? "",
          title: res.data?.name ?? "",
          description: res.data?.description ?? "",
          startDate: res.data?.startsAt ?? "",
          endDate: res.data?.endsAt ?? "",
          programId: res.data?.programId ?? "",
          lockingPeriod: res.data?.lockingPeriod ?? "",
          expiryPeriod: res.data?.expiryPeriod ?? "",
          offerInformationForPWABefore: res.data?.informationBeforeRedemption ?? "",
          offerInformationForPWAAfter: res.data?.informationAfterRedemption ?? "",
          TAndC: res.data?.termsAndConditions ?? "",
          coverPhoto: res.data?.coverImageUrl ?? "",
          coverImageUrl: res.data?.coverImageUrl ?? "",
          coverPhotoId: res.data?.coverImageId ?? "",
          offerInitiative: res.data?.initiativeId ?? "",
          gameType: res.data?.game?.gameId ?? "",
          rewardCurrency: res.data?.currencyCode ?? "",
          rewardCurrencyId: res.data?.currencyId ?? "",
          csvUrl: res.data?.csvUrl ?? "",
        },
        createGame: {
          gameName: gameDetails?.name,
          game_type_id: res?.data?.game?.game_type_id,
          game: { 
            scratchCardData: updatedScratchCardData,
            spinTheWheelData:
              gameDetails?.name === "Spin The Wheel"
                ? res?.data?.game?.rewards?.map((item) => {
                    return {
                      title_required: true,
                      probability_required: true,
                      limit: true,
                      limit_required: true,
                      rewardLevel_required: true,
                      title: item?.title,
                      probability: item?.win_probability,
                      reward_type: rewards?.find((e) => e?.id === item?.reward_type_id),
                      cashback_type: {
                        ...rewards
                          ?.find((reward) => reward.id === item?.reward_type_id)
                          ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                          ?.options?.map((option) => ({
                            id: option?.value,
                            name: option?.label,
                            fields: option?.fields,
                          }))
                          ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
                      },
                      ...convertRewardDetails(item?.reward_details),
                    };
                  })
                : [],
            slotMachineData:
              gameDetails?.name === "Slot Machine"
                ? res?.data?.game?.rewards?.map((item) => {
                    return {
                      title_required: true,
                      probability_required: true,
                      limit: true,
                      limit_required: true,
                      rewardLevel_required: true,
                      title: item?.title,
                      probability: item?.win_probability,
                      reward_type: rewards?.find((e) => e?.id === item?.reward_type_id),
                      cashback_type: {
                        ...rewards
                          ?.find((reward) => reward.id === item?.reward_type_id)
                          ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                          ?.options?.map((option) => ({
                            id: option?.value,
                            name: option?.label,
                            fields: option?.fields,
                          }))
                          ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
                      },
                      ...convertRewardDetails(item?.reward_details),
                    };
                  })
                : [],
            triviaData:
              gameDetails?.name === "Trivia"
                ? res?.data?.game?.rewards?.map((item) => {
                    return {
                      title_required: true,
                      probability_required: true,
                      limit: true,
                      limit_required: true,
                      rewardLevel_required: true,
                      title: item?.title,
                      probability: item?.win_probability,
                      reward_type: rewards?.find((e) => e?.id === item?.reward_type_id),
                      cashback_type: {
                        ...rewards
                          ?.find((reward) => reward.id === item?.reward_type_id)
                          ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                          ?.options?.map((option) => ({
                            id: option?.value,
                            name: option?.label,
                            fields: option?.fields,
                          }))
                          ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
                      },
                      ...convertRewardDetails(item?.reward_details),
                    };
                  })
                : [],
            triviaData_step1: {
              triviaType: "",
              questions: [
                {
                  title: "",
                  addMedia: false,
                  images: [],
                  options: [{ optionTitle: "", isSelected: true }],
                },
              ],
            },
          },
          beforePlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.before_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.before_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.before_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.before_play || {} : {},
          },
          afterPlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.after_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.after_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.after_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.after_play || {} : {},
          },
          whenPlay: {
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.when_play || {} : {},
          },
          addNewRewards: {
            scratchCardData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            spinTheWheelData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            slotMachineData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            triviaData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
          },
        },
        setOfferRules: {
          linkToRule: res.data?.earnRuleSetId ?? {},
          linkToCohort: res.data?.collectionsIds ?? [],
        },
        offerLimit: {
          offerLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: offerLimit?.daily,
              weekly: offerLimit?.weekly,
              monthly: offerLimit?.monthly,
              total: offerLimit?.overall,
            },
          },
          userLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: userLimit?.daily,
              weekly: userLimit?.weekly,
              monthly: userLimit?.monthly,
              totalAmount: userLimit?.overall,
            },
            fairUsageOfRewardRedemption: {
              daily: userLimit?.daily_issuance,
              weekly: userLimit?.weekly_issuance,
              monthly: userLimit?.monthly_issuance,
              totalAmount: userLimit?.overall_issuance,
            },
          },
        },
      }));
    } catch (error) {
      console.error(error);
    }
  };
  const getOfferDataByIDv4 = async () => { 
    try {
      const res = await axios.get(`${BaseUrl}/api/admin/offer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const offerLimit = res.data?.values?.find((e) => e.entity_type === "OFFER")?.limit_type;
      const userLimit = res.data?.values?.find((e) => e.entity_type === "USER")?.limit_type;
      const gameDetails = gameData?.find((item) => item?.id === res?.data?.game?.game_type_id);
  
      const updatedGameData = await Promise.all(
        res?.data?.game?.rewards?.map(async (item) => {
          const rewardName = rewards?.find((e) => e?.id === item?.reward_type_id)?.name;
          const rewardType = rewards
            ?.find((e) => e?.id === item?.reward_type_id)
            ?.reward_details?.find((ele) => ele?.backend_label === "reward_type")
            ?.options.find((ele) => ele?.name === rewardName);
          const limitOptions = rewards.find((reward) => reward?.name === rewardType?.name)?.limitOptions;
      
          let  csvFile =item?.reward_details?.csvUrl ? await fetchCsvFileFromUrl(item?.reward_details?.csvUrl):"";
          // if (gameDetails?.name === "Scratch Card") {
          //   csvFile = await fetchCsvFileFromUrl("https://gratifystorage01.blob.core.windows.net/gratify-dev/330d31_SampleVouchersSheet1.csv");
          // }
      
          return {
            title_required: true,
            probability_required: true,
            // limit: gameDetails?.name !== "Scratch Card",
            limit:false,
            limit_required: true,
            rewardLevel_required: true,
            rewardType_required: true,
            title: item?.title,
            probability: item?.win_probability,
            reward_type: rewardType,
            rewardIcon:item?.reward_details?.reward_icon,
            reward_icon:item?.reward_details?.reward_icon,
            id:item?.id,
            cashback_type: {
              ...rewards
                ?.find((reward) => reward.id === item?.reward_type_id)
                ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                ?.options?.map((option) => ({
                  id: option?.value,
                  name: option?.label,
                  fields: option?.fields,
                }))
                ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
            },
            ...convertRewardDetails(item?.reward_details),
            ...limitOptions,
            voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "",
            csvUrl: item?.reward_details?.csvUrl ?? "",
            csvFile,
            rewardLevel: limitOptions?.limitLevelOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.limit_level),
            rewardType: limitOptions?.limitTypeOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.entity_type),
            rewardLimit: limitOptions?.limitOptions
              ?.filter((limit) => item?.rewardLimits?.[0]?.hasOwnProperty(limit?.id))
              .map((limit) => ({
                time_required: true,
                amount_required: true,
                type: {
                  id: limit.id,
                  name: limit.name,
                },
                amount: item?.rewardLimits[0][limit.id],
              })),
          };
        })
      );
      
      // Now, use updatedGameData to set the appropriate game data based on the game type
      const scratchCardData = gameDetails?.name === "Scratch Card" ? updatedGameData : [];
      const spinTheWheelData = gameDetails?.name === "Spin The Wheel" ? updatedGameData : [];
      const slotMachineData = gameDetails?.name === "Slot Machine" ? updatedGameData : [];
      const triviaData = gameDetails?.name === "Trivia" ? updatedGameData : [];
  
      let obj ={
        // ...prev,
        basicOffers: {
          // ...prev.basicOffers,
          category: res.data?.categoryId ?? "",
          subCategory: res.data?.subcategoryId ?? "",
          product: res.data?.productId ?? "",
        },
        offerDetails: {
          merchant: res.data?.merchantId ?? "",
          title: res.data?.name ?? "",
          description: res.data?.description ?? "",
          startDate: res.data?.startsAt ?? "",
          endDate: res.data?.endsAt ?? "",
          programId: res.data?.programId ?? "",
          lockingPeriod: res.data?.lockingPeriod ?? "",
          expiryPeriod: res.data?.expiryPeriod ?? "",
          offerInformationForPWABefore: res.data?.informationBeforeRedemption ?? "",
          offerInformationForPWAAfter: res.data?.informationAfterRedemption ?? "",
          TAndC: res.data?.termsAndConditions ?? "",
          coverPhoto: res.data?.coverImageUrl ?? "",
          coverImageUrl: res.data?.coverImageUrl ?? "",
          coverPhotoId: res.data?.coverImageId ?? "",
          offerInitiative: res.data?.initiativeId ?? "",
          gameType: res.data?.game?.gameId ?? "",
          rewardCurrency: res.data?.currencyCode ?? "",
          rewardCurrencyId: res.data?.currencyId ?? "",
          csvUrl: res.data?.csvUrl ?? "",
        },
        createGame: {
          gameName: gameDetails?.name,
          game_type_id: res?.data?.game?.game_type_id,
          game: { 
            scratchCardData: scratchCardData,
            spinTheWheelData:spinTheWheelData,
            slotMachineData:slotMachineData,
            triviaData:triviaData,
            triviaData_step1: {
              triviaType: "",
              questions: [
                {
                  title: "",
                  addMedia: false,
                  images: [],
                  options: [{ optionTitle: "", isSelected: true }],
                },
              ],
            },
          },
          beforePlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.before_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.before_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.before_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.before_play || {} : {},
          },
          afterPlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.after_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.after_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.after_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.after_play || {} : {},
          },
          whenPlay: {
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.when_play || {} : {},
          },
          addNewRewards: {
            scratchCardData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            spinTheWheelData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            slotMachineData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            triviaData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
          },
        },
        setOfferRules: {
          linkToRule: res.data?.earnRuleSetId ?? {},
          linkToCohort: res.data?.collectionsIds ?? [],
        },
        offerLimit: {
          offerLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: offerLimit?.daily,
              weekly: offerLimit?.weekly,
              monthly: offerLimit?.monthly,
              total: offerLimit?.overall,
            },
          },
          userLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: userLimit?.daily,
              weekly: userLimit?.weekly,
              monthly: userLimit?.monthly,
              totalAmount: userLimit?.overall,
            },
            fairUsageOfRewardRedemption: {
              daily: userLimit?.daily_issuance,
              weekly: userLimit?.weekly_issuance,
              monthly: userLimit?.monthly_issuance,
              totalAmount: userLimit?.overall_issuance,
            },
          },
        },
      }
      dispatch({ type: initialOfferEditValue, payload: obj });
      // Now, integrate the resolved data into the state update
      setInitialData((prev) => (obj));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOfferDataByIDv4();
  }, []);

  // console.log(initialData, "data");

  return (
    <div>
      <OfferStepper initialData={initialData} />
    </div>
  );
}
