import React, { useEffect, useState } from "react";
import MuiSelectAndSearch from "../UIComponents/MuiSelectAndSearch";
import { Box, Button } from "@mui/material";
import Label from "../UIComponents/Label";
import MuiInput from "../UIComponents/MuiInput";
import MuiButton from "../UIComponents/MuiButton";
import MultiSelect from "../UIComponents/MultiSelect";
import MuiSelect from "../UIComponents/MuiSelect";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../utils/token";

const userOption = [{ label: "User Level", value: "user" }];
const productOption = [{ label: "Product Level", value: "product" }];

export default function CreateLimit({
  activeTab,
  tabData,
  setTabData,
  handleCancel,
}) {
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = useSelector((store) => store.AuthReducer?.token);
  const dispatch = useDispatch();

  const categoryData = useSelector(
    (store) => store.OfferReducer.categoryOption
  );

  const categoryOptions = categoryData.map((element) => {
    return { name: element.name, id: element.id };
  });

  const merchantData = useSelector(
    (store) => store.OfferReducer.merchantOption
  );
  const merchantOption = merchantData.map((element) => {
    return { name: element.name, id: element.id };
  });

  const getLimitSubCategoryFunction = (ID) => {
    const token = getToken();
    const categoryIdsString = JSON.stringify(ID);
    let params = { table: "subcategory", category_id: categoryIdsString };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${BaseUrl}/api/get`, { params, ...config })
      .then((res) => {
        // dispatch({ type: getLimitSubcategory, payload: res.data });

        const option = res.data?.map((element) => {
          return { name: element.name, id: element.id };
        });

        setTabData((prevTabData) => {
          return prevTabData.map((tab, index) => {
            if (index === activeTab) {
              return {
                ...tab,
                subCategoryOption: option,
              };
            }
            return tab;
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLimitsProductFunction = (ID) => {
    const categoryIdsString = JSON.stringify(ID);
    const token = getToken();
    let params = { table: "product", subcategory_id: categoryIdsString };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${BaseUrl}/api/get`, { params, ...config })
      .then((res) => {
        const option = res.data?.map((element) => {
          return { name: element.name, id: element.id };
        });

        setTabData((prevTabData) => {
          return prevTabData.map((tab, index) => {
            if (index === activeTab) {
              return {
                ...tab,
                productOptions: option,
              };
            }
            return tab;
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllData = () => {
    const allData = {
      data: [],
      entityDetails: {
        entityId: tabData[activeTab]?.merchantFlag
          ? tabData[activeTab]?.merchant?.id
          : tabData[activeTab]?.categoryFlag
          ? tabData[activeTab].category?.id
          : tabData[activeTab].subCategoryFlag
          ? tabData[activeTab].subCategory.id
          : tabData[activeTab].productFlag
          ? tabData[activeTab].product?.id
          : "",

        entityType:tabData[activeTab]?.merchantFlag
        ? "merchant"
        : tabData[activeTab]?.categoryFlag
          ? "category"
          : tabData[activeTab].subCategoryFlag
          ? "subcategory"
          : tabData[activeTab].productFlag
          ? "product"
          : "",
      },
    };

    let userObj = {
      level_type: "user",
    };

    if (tabData[activeTab]?.userDaily) {
      userObj.daily_limit = tabData[activeTab].userDaily;
    }

    if (tabData[activeTab]?.userWeekly) {
      userObj.weekly_limit = tabData[activeTab]?.userWeekly;
    }

    if (tabData[activeTab]?.userMonthly) {
      userObj.monthly_limit = tabData[activeTab]?.userMonthly;
    }

    if (tabData[activeTab]?.userTotal) {
      userObj.overall_limit = tabData[activeTab]?.userTotal;
    }
    if (
      tabData[activeTab]?.userDaily ||
      tabData[activeTab]?.userWeekly ||
      tabData[activeTab]?.userMonthly ||
      tabData[activeTab]?.userTotal
    ) {
      allData.data.push(userObj);
    }

    const productObj = {
      level_type: "product",
    };

    if (tabData[activeTab]?.productDaily) {
      productObj.daily_limit = tabData[activeTab]?.productDaily;
    }

    if (tabData[activeTab]?.productWeekly) {
      productObj.weekly_limit = tabData[activeTab]?.productWeekly;
    }

    if (tabData[activeTab]?.productMonthly) {
      productObj.monthly_limit = tabData[activeTab]?.productMonthly;
    }

    if (tabData[activeTab]?.productTotal) {
      productObj.overall_limit = tabData[activeTab]?.productTotal;
    }

    if (
      tabData[activeTab]?.productDaily ||
      tabData[activeTab]?.productWeekly ||
      tabData[activeTab]?.productMonthly ||
      tabData[activeTab]?.productTotal
    ) {
      allData.data.push(productObj);
    }

    // Add missing keys from oldLimit to allData.data if TabType is "Edit Limit"
    if (
      tabData[activeTab]?.TabType === "Edit Limit" &&
      tabData[activeTab]?.edit?.oldLimit
    ) {
      tabData[activeTab].edit.oldLimit.forEach((oldLimit) => {
        const existing = allData.data.find(
          (data) => data.level_type === oldLimit.limit_level
        );

        if (!existing) {
          allData.data.push({
            level_type: oldLimit.limit_level,
            daily_limit: null,
            weekly_limit: null,
            monthly_limit: null,
            overall_limit: null,
          });
        } else {
          if (!existing.daily_limit && existing.daily_limit !== 0)
            existing.daily_limit = null;
          if (!existing.weekly_limit && existing.weekly_limit !== 0)
            existing.weekly_limit = null;
          if (!existing.monthly_limit && existing.monthly_limit !== 0)
            existing.monthly_limit = null;
          if (!existing.overall_limit && existing.overall_limit !== 0)
            existing.overall_limit = null;
        }
      });
    }

    return allData;
  };

  // const selectOption = useSelector((store) => store.AuthReducer?.limitOption);
  // const categoryOptions = selectOption?.categoryOptions?.map((e) => {
  //   return {
  //     name: e?.entity_name,
  //     id: e?.entity_id,
  //     entity_type: e?.entity_type,
  //     entity_code: e?.entity_code,
  //     limit_level_names: e?.limit_level_names,
  //   };
  // });

  // to handle Data Dynamically
  const handleData = (name, value) => {
    if (name === "category") {
      getLimitSubCategoryFunction([value?.id]);
      setTabData((prevTabData) => {
        return prevTabData.map((tab, index) => {
          if (index === activeTab) {
            return {
              ...tab,
              [name]: value,
              subCategory: {},
              product: {},
              productOptions: [],
              categoryFlag: true,
              subCategoryFlag: false,
              productFlag: false,
              // userLevelFlag: value?.limit_level_names.includes("user"),
              // productLevelFlag: value?.limit_level_names.includes("product"),
            };
          }
          return tab;
        });
      });
    } else if (name === "subCategory") {
      getLimitsProductFunction([value?.id]);
      setTabData((prevTabData) => {
        return prevTabData.map((tab, index) => {
          if (index === activeTab) {
            return {
              ...tab,
              [name]: value,
              product: {},
              categoryFlag: false,
              subCategoryFlag: true,
              productFlag: false,
              // userLevelFlag: value?.limit_level_names.includes("user"),
              // productLevelFlag: value?.limit_level_names.includes("product"),
            };
          }
          return tab;
        });
      });
    } else if (name === "product") {
      setTabData((prevTabData) => {
        return prevTabData.map((tab, index) => {
          if (index === activeTab) {
            return {
              ...tab,
              [name]: value,
              categoryFlag: false,
              subCategoryFlag: false,
              productFlag: true,
              // userLevelFlag: value?.limit_level_names.includes("user"),
              // productLevelFlag: value?.limit_level_names.includes("product"),
            };
          }
          return tab;
        });
      });
    } else if (name === "merchant") {
      setTabData((prevTabData) => {
        return prevTabData.map((tab, index) => {
          if (index === activeTab) {
            return {
              ...tab,
              [name]: value,
              categoryFlag: false,
              subCategoryFlag: false,
              productFlag: false,
              merchantFlag: true,
              category: {},
              subCategory: {},
              product: {},
              // userLevelFlag: value?.limit_level_names.includes("user"),
              // productLevelFlag: value?.limit_level_names.includes("product"),
            };
          }
          return tab;
        });
      });
    } else {
      setTabData((prevTabData) => {
        return prevTabData.map((tab, index) => {
          if (index === activeTab) {
            return {
              ...tab,
              [name]: value,
            };
          }
          return tab;
        });
      });
    }
  };

  const handleCreate = () => {
    const allData = getAllData();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${BaseUrl}/api/limits/create-limits`, allData, config)
      .then((res) => {
        console.log(res.data, "res");
        toast.success("Limit created successfully");
        setTimeout(() => {
          handleCancel(tabData[activeTab], activeTab);
        }, 2000);
      })
      .catch((err) => {
        console.log(err?.response?.data?.message, "err");
        toast.error(err?.response?.data?.message);
      });

    console.log(allData, "data");
  };

  const getLimitDataById = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let obj = {
      entityId: tabData[activeTab]?.edit?.entity_id,
      entityType: tabData[activeTab]?.edit?.entity_type,
    };

    axios
      .post(`${BaseUrl}/api/limits/get-limits-by-entity-id`, obj, config)
      .then((res) => {
        const response = res.data;

        setTabData((prevTabData) => {
          return prevTabData.map((tab, index) => {
            if (index === activeTab) {
              return {
                ...tab,
                edit: {
                  ...tab.edit,
                  oldLimit: response.limits,
                },
              };
            }
            return tab;
          });
        });

        if(response?.history && response?.history?.merchant) {
          handleData("merchant", response.history.merchant);
        }

        if (response.history && response.history.category) {
          handleData("category", response.history.category);
        }

        if (response.history && response.history.subcategory) {
          handleData("subCategory", response.history.subcategory);
        }

        if (response.history && response.history.product) {
          handleData("product", response.history.product);
        }

        response.limits.forEach((e) => {
          if (e.limit_level === "user") {
            handleData("userDaily", e?.daily_limit);
            handleData("userWeekly", e?.weekly_limit);
            handleData("userMonthly", e?.monthly_limit);
            handleData("userTotal", e?.overall_limit);
          } else if (e.limit_level === "product") {
            handleData("productDaily", e?.daily_limit);
            handleData("productWeekly", e?.weekly_limit);
            handleData("productMonthly", e?.monthly_limit);
            handleData("productTotal", e?.overall_limit);
          }
        });

        // handleData()
        console.log(res.data, "tabData");
      })
      .catch((err) => {
        console.log(err?.response?.data?.message, "err");
        toast.error(err?.response?.data?.message);
      });

    // console.log(obj,"tabData")
  };

  const handleEdit = () => {
    const allData = getAllData();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${BaseUrl}/api/limits/update-limits`, allData, config)
      .then((res) => {
        console.log(res.data, "res");
        toast.success("Limit Edited successfully");
        setTimeout(() => {
          handleCancel(tabData[activeTab], activeTab);
        }, 2000);
      })
      .catch((err) => {
        console.log(err?.response?.data?.message, "err");
        toast.error(err?.response?.data?.message);
      });

    console.log(allData, "tabData");
  };

  useEffect(() => {
    if (tabData[activeTab]?.TabType === "Edit Limit") {
      getLimitDataById();
    }
  }, []);

  // console.log(tabData[activeTab], "tabData");
  return (
    <Box
      padding="50px 20px 20px 20px"
      sx={{
        height: "calc(100vh - 240px)",
        backgroundColor: "#fff",
        overflowY: "hidden",
        borderRadius: "0px 8px 8px 8px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        justifyContent: "space-between",
      }}
    >
      <Box>

      <Box sx={{ display: "flex", gap: "20px" }}>
        <Box width="250px">
          <Label label="Select Category" />
          <MuiSelectAndSearch
            placeholder="Select Category"
            options={categoryOptions}
            value={tabData[activeTab]?.category}
            onChange={(value) => handleData("category", value)}
            isDisable={
              tabData[activeTab]?.TabType === "Edit Limit" ||
              tabData[activeTab]?.merchant?.id
            }
          />
        </Box>

        <Box width="250px">
          <Label label="Select Subcategory " />
          <MuiSelectAndSearch
            placeholder="Select Subcategory"
            options={tabData[activeTab]?.subCategoryOption}
            value={tabData[activeTab]?.subCategory}
            onChange={(value) => handleData("subCategory", value)}
            isDisable={
              tabData[activeTab]?.TabType === "Edit Limit" ||
              tabData[activeTab]?.merchant?.id
            }
          />
        </Box>

        <Box width="250px">
          <Label label="Select Product" />
          <MuiSelectAndSearch
            placeholder="Select Product"
            options={tabData[activeTab]?.productOptions}
            value={tabData[activeTab]?.product}
            onChange={(value) => handleData("product", value)}
            isDisable={
              tabData[activeTab]?.TabType === "Edit Limit" ||
              tabData[activeTab]?.merchant?.id
            }
          />
        </Box>

        <Box width="250px">
          <Label label="Select Merchant" />
          <MuiSelectAndSearch
            placeholder="Select Merchant"
            options={merchantOption}
            value={tabData[activeTab]?.merchant}
            onChange={(value) => handleData("merchant", value)}
            isDisable={tabData[activeTab]?.TabType === "Edit Limit"}
          />
        </Box>
      </Box>

      {/* {tabData[activeTab]?.userLevelFlag && ( */}
      <Box sx={{ display: "flex", gap: "15px", marginTop: "48px" }}>
        <Box width="200px">
          <Label label="Type" />
          <MuiSelect
            value={"user"}
            options={userOption}
            isDisabled={true}
            // onChange={(value) => handleData("type", value)}
          />
        </Box>

        {/* <Box width="180px">
          <Label label="Hourly" />
          <MuiInput
            value={tabData[activeTab]?.hourly}
            onChange={(value) => handleData("hourly", value)}
            placeholder="value"
          />
        </Box> */}

        <Box width="180px">
          <Label label="Daily" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.userDaily}
            onChange={(value) => handleData("userDaily", value)}
            placeholder="value"
          />
        </Box>

        <Box width="180px">
          <Label label="Weekly" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.userWeekly}
            onChange={(value) => handleData("userWeekly", value)}
            placeholder="value"
          />
        </Box>

        <Box width="180px">
          <Label label="Monthly" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.userMonthly}
            onChange={(value) => handleData("userMonthly", value)}
            placeholder="value"
          />
        </Box>

        <Box width="180px">
          <Label label="Total" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.userTotal}
            onChange={(value) => handleData("userTotal", value)}
            placeholder="value"
          />
        </Box>
      </Box>
      {/* )} */}

      {/* {tabData[activeTab]?.productLevelFlag && ( */}
      <Box sx={{ display: "flex", gap: "15px", marginTop: "48px" }}>
        <Box width="200px">
          <Label label="Type" />
          <MuiSelect
            type="number"
            value={"product"}
            options={productOption}
            isDisabled={true}
            // onChange={(value) => handleData("type", value)}
          />
        </Box>

        <Box width="180px">
          <Label label="Daily" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.productDaily}
            onChange={(value) => handleData("productDaily", value)}
            placeholder="value"
          />
        </Box>

        <Box width="180px">
          <Label label="Weekly" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.productWeekly}
            onChange={(value) => handleData("productWeekly", value)}
            placeholder="value"
          />
        </Box>

        <Box width="180px">
          <Label label="Monthly" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.productMonthly}
            onChange={(value) => handleData("productMonthly", value)}
            placeholder="value"
          />
        </Box>

        <Box width="180px">
          <Label label="Total" />
          <MuiInput
            type="number"
            value={tabData[activeTab]?.productTotal}
            onChange={(value) => handleData("productTotal", value)}
            placeholder="value"
          />
        </Box>
      </Box>

      </Box>



<Box sx={{ display: "flex", justifyContent: "flex-start", gap: "16px", mt: "39px" }}>
  <Button
    sx={{
      color: "#003D86",
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "16.8px",
      backgroundColor: "#E8F0F9",
      width: "136px",
      height: "30px",
      borderRadius: "6px",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "#E8F0F9",
      },
      // display: cancelButtonHide ? "none" : "block",
    }}
    variant="outlined"
    size="small"
    onClick={() => handleCancel(tabData[activeTab], activeTab)}
  >
    Cancel
  </Button>

  {tabData[activeTab]?.TabType === "Create Limit" ? (
    <Button
      sx={{
        color: "#FFFFFF",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16.8px",
        backgroundColor: "#003D86",
        width: "136px",
        height: "30px",
        borderRadius: "6px",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#003D86",
        },
      }}
      variant="contained"
      size="small"
      onClick={handleCreate}
    >
      Create
    </Button>
  ) : (
    <Button
      sx={{
        color: "#FFFFFF",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16.8px",
        backgroundColor: "#003D86",
        width: "136px",
        height: "30px",
        borderRadius: "6px",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#003D86",
        },
      }}
      variant="contained"
      size="small"
      onClick={handleEdit}
    >
      Update
    </Button>
  )}
</Box>

    </Box>
  );
}
