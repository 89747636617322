import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import { FormControl, InputLabel, FormHelperText, Checkbox, Chip, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FiSearch } from 'react-icons/fi';

const dummyOptions = [
  { id: 'option1', name: 'Option 1' },
  { id: 'option2', name: 'Option 2' },
  { id: 'option3', name: 'Option 3' },
  { id: 'option4', name: 'Option 4' },
  { id: 'option5', name: 'Option 5' },
];

const SearchableMultiSelect = ({
  label = '',
  options = [],
  placeholder = 'Select options',
  onChange,
  value,
  errorMessage,
  isDisabled = false,
  editBoolean,
  showSelectAll = false,
  showChips = false,
  hideSelectedOptions = false,
  hideRemoveIcon=false,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef();

  const allOption = { id: '*', name: 'Select All' };
  const optionsWithSelectAll = showSelectAll && options.length > 0 ? [allOption, ...options] : options;

  const handleChange = (selectedOptions, actionMeta) => {
    if (actionMeta.option?.id === '*') {
      const newValue = selectedOptions.find(option => option.id === '*')
        ? options
        : [];
      onChange(newValue);
    } else {
      const filteredOptions = selectedOptions.filter(option => option.id !== '*');
      onChange(filteredOptions);
    }
  };

  const handleChipDelete = (optionToRemove) => {
    const newValue = value.filter(option => option.id !== optionToRemove.id);
    onChange(newValue);
  };

  const isOptionSelected = option =>
    option.id === '*'
      ? value.length === options.length
      : value.some(v => v.id === option.id);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '32px',
      height: 'auto',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      textAlign: "start",
      borderColor: editBoolean
        ? 'orange !important'
        : errorMessage
          ? '#d40e10 !important'
          : provided.borderColor,
      '&:hover': {
        borderColor: editBoolean
          ? 'orange !important'
          : errorMessage
            ? '#d40e10 !important'
            : provided.borderColor,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 5px',
      height: '22px',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValue: (provided, { data }) => ({
      ...provided,
      display: hideSelectedOptions ? 'none' : 'flex',
      borderRadius: '3px',
      height: '18px',
      margin: '0 2px',
      alignItems: 'center',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      padding: '0',
      height: '18px',
      display: 'flex',
      alignItems: 'center',
      display: hideRemoveIcon ? 'none' : 'flex',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '8px',
      backgroundColor: state.isSelected ? '#edf4fb' : 'white',
      color: 'black',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      borderBottom: '1px solid #E3E7EF',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Mulish',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18.2px',
      color:"#0F1828",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '22px',
      marginTop: "4px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 4px',
      display: 'flex',
      alignItems: 'center',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 4px',
      display: 'flex',
      alignItems: 'center',
    }),
    crossIcon: (provided) => ({
      ...provided,
      width: '14px',
      height: '14px',
    }),
    dropdownIndicatorSeparator: (provided) => ({
      ...provided,
      margin: '4px 0',
    }),
    indicatorSeparator: (provided) => ({
      display: 'none', // Hide the indicator separator
    }),
  };

  const Option = props => (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        borderBottom: '1px solid #E3E7EF',
      }}
    >
      <Checkbox
        checked={props.isSelected}
        sx={{
          marginRight: '8px',
          padding: '0',
          '&.Mui-checked': {
            color: '#003D86',
          },
          color: "#D5DAE1",
        }}
      />
      {props.data.name}
    </div>
  );

  const ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const selectedCount = getValue().length;

    return (
      <components.ValueContainer {...props}>
        {hideSelectedOptions && hasValue ? (
          <div>{`${selectedCount} selected`}</div>
        ) : (
          children
        )}
      </components.ValueContainer>
    );
  };

  const chipStyle = {
    margin: '2px 5px',
    backgroundColor: '#F9F9FC',
    borderRadius: '20px',
    padding: '2px 5px',
    color: '#003D86',
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '16px',
  };

  // // Close the dropdown if clicked outside
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (selectRef.current && !selectRef.current.contains(event.target)) {
  //       setMenuIsOpen(false);
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  return (
    <FormControl
      sx={{ mt: '10px', width: '100%' }}
      variant="outlined"
      size="small"
      error={!!errorMessage}
    >
      <InputLabel shrink>{label}</InputLabel>
      <div ref={selectRef}>
        <Select
          isMulti
          options={optionsWithSelectAll}
          value={value}
          onChange={handleChange}
          placeholder={
            <Box sx={{ display: "flex", alignItems: "center", color: "#9499a0ed" }}>
              <FiSearch style={{ color: "#858D9D", marginRight: '5px', height: "14px", width: "14px" }} />
              {placeholder}
            </Box>
          }
          isDisabled={isDisabled}
          styles={customStyles}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          hideSelectedOptions={false}
          components={{ Option }}
          isOptionSelected={isOptionSelected}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          closeMenuOnSelect={false}
        />
      </div>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      {showChips && (
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
          {value.map((option) => (
            <Chip
              key={option.id}
              label={option.name}
              onDelete={() => handleChipDelete(option)}
              style={chipStyle}
              deleteIcon={<CloseIcon sx={{ height: "16px", width: "16px", fill: "#003D86",display:isDisabled ? "none" : "block" }} />}
            />
          ))}
        </div>
      )}
    </FormControl>
  );
};

export default SearchableMultiSelect;
