import React from "react";
import { Box, Button } from "@mui/material";
import SearchBar from "../UIComponents/SearchBar";

const SearchActionComponent = ({
  value,
  setState,
  placeholder,
  children,
  //   toggleDrawer,
  downloadFile,
  require = { search: true, filter: true, download: false },
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "15px",
        alignItems: "center",
        maxWidth:"970px"
      }}
    >
      {require.search && (
        <SearchBar
          value={value}
          setState={setState}
          placeholder={placeholder}
        />
      )}
      {/* {require.filter && <FilterByButton toggleDrawer={toggleDrawer} />} */}

      {require.download && (
        <Button>
          <Button
            sx={{
              width: "110px",
              border: "1px solid rgba(0, 0, 0, 0.30)",
              borderRadius: "12px",
              fontSize: "15px",
              height: "45px",
              fontFamily: "Proxima Nova",
              color: "#000",
              fontWeight: 600,
              lineHeight: "normal",
            }}
            variant="Primary"
            onClick={() => downloadFile()}
          >
            Download
          </Button>
        </Button>
      )}
      {children}
    </Box>
  );
};

export default SearchActionComponent;
