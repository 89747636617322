import React from "react";
import { Box, Button } from "@mui/material";
import { BootstrapTooltip } from "../utils/tooltip";

const MuiButton = ({
  variant = "contained",
  style = { padding: "8px 30px" },
  bg = "black",
  color = "white",
  icon = "",
  isDisable = false,
  onClick,
  children,
  TooltipMessage
}) => {
  return (
    <BootstrapTooltip title={TooltipMessage&&isDisable?TooltipMessage:""} placement="left-end">
  <span>
    <Button
      disabled={isDisable}
      style={{
        backgroundColor: !isDisable ? bg : "gray",
        color: color,
        textTransform: "none",
        ...style,
      }}
      variant={variant}
      // startIcon={icon && <img style={{height:"16px",width:"16px"}} src={icon} alt="icon" />}
      size="small"
      onClick={onClick}
    >
      <Box sx={{display:"flex",alignItems:"center",gap:"4px"}}>
      {icon && <img style={{height:"16px",width:"16px"}} src={icon} alt="icon" />}
      <span>

      {children}
      </span>
      </Box>
    </Button>
  </span>
</BootstrapTooltip>
  );
};

export default MuiButton;
